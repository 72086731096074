export interface I18nMessage {
  key?: string;
  header?: string;
  title?: string;
  msg?: string;
  icon?: string;
  i18nFields?: Array<string> ;
}

export const I18nMessageConstants: Array < I18nMessage > = [

  {
      key: 'code.undefined',
      msg: 'Não foi possível identificar a excessão de código {code}. Por favor, entre em contato com o administrador do sistema informando essa mensagem.',
      title: 'Ocorre]u um problema',
      header: 'ERRO',
      icon: 'popup-icon-error'
  },

  {
      key: 'customer.birthday.invalid_format',
      msg: 'A data de nascimento do passageiro {customer} deve respeitar o formato {format}. Exemplo: {example} ',
      title: 'Dados Preenchidos Incorretamente',
      header: 'Atenção',
      icon: 'popup-icon-warning'
  },

  {
      key: 'rounding.unsupported',
      msg: 'O tipo de arredondamento {rounding} não é suportado pelo sistema. Por favor, entre em contato com o administrador do sistema informando essa mensagem.',
      title: 'Ocorreu um problema',
      header: 'ERRO',
      icon: 'popup-icon-error'
  },

  {
      key: 'pricing.not_found',
      msg: 'Não foi possível calcular o preço para o tipo de bilhete {ticket}. Por favor, entre em contato com o administrador do sistema informando essa mensagem.',
      title: 'Ocorreu um problema',
      header: 'ERRO',
      icon: 'popup-icon-error'
  },

  {
      key: 'service-travel.not_found',
      header: 'ERRO',
      title: 'Ocorreu um problema',
      msg: 'Serviço não encontrado!',
      icon: 'popup-icon-error'
  },

  {
      key: 'api.offline',
      header: 'Alerta',
      title: 'Sem conexão, deslogando do sistema...',
      msg: 'Erro na conexão com o servidor',
      icon: 'popup-icon-warning'
  },

  {
      key: 'confirmation.invalid_seat_selection',
      header: 'Alerta',
      title: 'Erro na seleção',
      msg: 'Só é permitido selecionar uma poltrona ao confirmar',
      icon: 'popup-icon-warning'
  },

  {
      key: 'confirmation.invalid_seat_selection_has_active_quotas',
      header: 'Alerta',
      title: 'Erro na seleção',
      msg: 'Esta poltrona possui cota ativa',
      icon: 'popup-icon-warning'
  },

  {
      key: 'ticket.already_confirmed',
      header: 'Alerta',
      title: 'Passagem já confirmada',
      msg: 'Por gentileza, vá ao passo anterior e selecione outra passagem',
      icon: 'popup-icon-warning'
  },

  {
      key: 'opened_sale.invalid_document',
      header: 'Erro',
      title: 'Erro ao adicionar',
      msg: 'Já foi adicionada venda em aberto com este documento',
      icon: 'popup-icon-error'
  },

  {
      key: 'balance.close_success',
      header: 'Sucesso',
      title: 'Fechamento de caixa efetuado!',
      msg: 'O fechamento de caixa foi concluído com êxito'
  },

  {
      key: 'extra_vehicle.added',
      header: 'Sucesso',
      title: 'Carro extra adicionado!',
      msg: 'Operação salva com sucesso',
      icon: 'popup-icon-success'
  },

  {
      key: 'travel.created',
      header: 'SUCESSO',
      title: 'Novo serviço cadastrado',
      msg: 'Serviço {name} cadastrado com sucesso!',
      icon: 'popup-icon-success'
  },

  {
      key: 'desktop.connection_refused',
      header: 'Guichê Pass',
      title: 'ATENÇÃO',
      msg: 'Não conseguimos contactar o aplicativo <strong>Guichê Pass Desktop</strong> instalado em sua máquina. Verifique se o mesmo foi <strong>inicializado</strong> ou <strong>não se encontra instalado</strong>. Em caso de dúvidas, consulte o seu responsável.',
      icon: 'popup-icon-warning'
  },

  {
      key: 'desktop.print.ticket',
      msg: 'Não foi possível imprimir o(s) bilhete(s) com a impressora {name} (modelo: {model}). Verifique a configuração da impressora no seu sistema operacional ou se há algum problema físico (como falta de tinta, por exemplo).',
      title: 'A operação não pode ser realizada',
      header: 'Guichê Pass',
      icon: 'popup-icon-error'
  },

  {
      key: 'desktop.print.insurance',
      msg: 'Não foi possível imprimir o(s) seguro(s) com a impressora {name} (modelo: {model}). Verifique a configuração da impressora no seu sistema operacional ou se há algum problema físico (como falta de tinta, por exemplo).',
      title: 'A operação não pode ser realizada',
      header: 'Guichê Pass',
      icon: 'popup-icon-error'
  },

  {
      key: 'sales.opening.succes',
      header: 'Sucesso',
      title: 'Emissão realizada',
      msg: 'Venda em aberto emitida com sucesso!',
      icon: 'popup-icon-success'
  },

  {
      key: 'sales.confirmation.success',
      header: 'Sucesso',
      title: 'Passagem confirmada',
      msg: 'Confirmação da passagem realizada com sucesso!',
      icon: 'popup-icon-success'
  },

  {
      key: 'insurance.printing.printer_not_set',
      header: 'Guichê Pass',
      title: 'ATENÇÃO',
      msg: 'Não foi possível realizar a impressão do seguro, pois nenhuma impressora de seguros foi registrada para esse PDV',
      icon: 'popup-icon-warning'
  },

  {
      key: 'ticket.changing.success',
      header: 'Sucesso',
      title: 'Passagem remarcada',
      msg: 'Remarcação da passagem realizada com sucesso!',
      icon: 'popup-icon-success'
  }

];
