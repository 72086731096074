import { Component, Vue } from 'vue-property-decorator'
import { AmplitudeService } from '@/modules/module/services/amplitude/amplitude.service';
import { AMPLITUDE_GROUPS } from '@/modules/module/constants/amplitude-groups.constants';
import { UserAuth } from '@/modules/module/model/client/user-auth';
import { AuthenticationService } from '@/modules/module-authentication/authentication.service';
import { RouterPath } from '@/enums/router-path.enum';

interface UI {
  user?: UserAuth;
}
@Component({
    template: require('./page-lgpd.html'),
})
export class PageLgpdComponent extends Vue {

  ui: UI = {
    user: undefined,
  }

  created() {
    this.ui.user = AuthenticationService.user;
  }

  mounted() {
    if (this.hasPermissionToSefaz()) {
      this.$router.push(RouterPath.ROOT);
    }
    AmplitudeService.logEvent('screen_open', { page: 'lgpd', group: AMPLITUDE_GROUPS.LGPD });
  }

  hasPermissionToSefaz() {
    if (this.ui.user && this.ui.user.groups && this.ui.user.groups.length) {
      const found = this.ui.user.groups.find(el => el === 'SEFAZ');
      return !!found;
    }
    return false;
  }
}
