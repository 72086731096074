import { CurrencyFormatInterface } from '@/modules/interfaces/currency-format';
import IFilter from '../ifilter';


export default class FormatCurrencyFilter implements IFilter {
    public filterName = '';

    constructor() {
        this.filterName = 'formatCurrency';
    }

    public filterAction(value: number, format?: CurrencyFormatInterface) {
        format = format || {
            prefix: 'R$ ',
            decimal: ',',
            thousands: '.',
            precision: 2
        };

        return this.formatCurrency(value, format);
    }

    private formatCurrency(value = 0, format: CurrencyFormatInterface): string {
        if (value === 0) return 'R$ 0,00';
        if (!value) return '';
        if (value < 0) {
            value *= -1;
            format.prefix = `- ${format.prefix}`;
        }

        const val = value.toFixed(format.precision).replace('.', format.decimal);
        return `${format.prefix}${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, format.thousands)}`;
    }
}
