import Cookies from "js-cookie";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    template: require('./bs-feedback.html'),
})
export class BSFeedbackComponent extends Vue {
    @Prop({ type: String, default: '' })
    title!: string;
    @Prop({ type: String, default: '' })
    message!: string;
    @Prop({ type: String, default: '' })
    event!: string;

    showFeedback = false;

    triggerEvent() {
        window.hj('event', this.event);
        const seeFeedback = Cookies.getJSON(this.event) || {};
        seeFeedback.hasSeen = true;
        Cookies.set(this.event, seeFeedback);
        this.hide();
    }

    created() {
      this.show();
    }

    show() {
      const seeFeedback = Cookies.getJSON(this.event) || {};
        if (!seeFeedback.hasSeen) {
          this.showFeedback = true;
      }
    }

    hide() {
      this.showFeedback = false;
    }

    get showFeedbackAlert() {
      return this.showFeedback;
    }
}
