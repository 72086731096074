import { BaseFactory } from '../base.factory';
import { UserModel, Group } from '@/modules/module/model/client/user-model';

class Factory extends BaseFactory {

  private title = 'User';

  constructor() {
    super(process.env.VUE_APP_API_URL);
  }

  public createUser = async (user: UserModel): Promise<UserModel> => {
    const result = await this.post(`/user`, user, undefined) as UserModel;
    return result;
  }

  public updateUser = async (user: UserModel): Promise<UserModel> => {
    const result = await this.put(`/user`, user, undefined) as UserModel;
    return result;
  }

  public changePassword = async (userId: string, oldPassword: string, newPassword: string) => {
    const user = {userId: userId, oldPassword: oldPassword, newPassword: newPassword };
    const result = await this.patch(`/user/change-password`, user, undefined);
    return result;
  }

  public getUsers = async (first = 0, max = 10): Promise<UserModel[]> => {
    const result = await this.get(`/user?first=${first}&max=${max}`, undefined) as UserModel[];
    return result;
  }

  public getUserById = async (id: string): Promise<UserModel> => {
    const result = await this.get(`/user/${id}`, undefined) as UserModel;
    return result;
  }

  public getGroupByUserLogged = async (): Promise<Group> => {
    const result = await this.get(`/user/group`, undefined) as Group;
    return result;
  }

  public countUsers = async (): Promise<number> => {
    const result = await this.get(`/user/count`, undefined) as number;
    return result;
  }

  public enableUser = async (id: string): Promise<void> => {
    return await this.patch(`/user/enable/${id}`, undefined);
  }

  public disableUser = async (id: string): Promise<void> => {
    return await this.patch(`/user/disable/${id}`, undefined);
  }
}

export const UserFactory = new Factory();
