import { Component, Vue, Prop } from 'vue-property-decorator';
import { AmplitudeService } from '../../../modules/module/services/amplitude/amplitude.service';

@Component({
    template: require('./bs-button-back.html')
})
export class BSButtonBackComponent extends Vue {
    @Prop()
    path!: string;

    @Prop(Function)
    method: any;

    doBack() {
        this.logAmplitudeButtonBack();

        if (this.path) {
            this.$router.push(this.path);

        } else if (this.method) {
            this.method();

        } else {
            this.$router.back();
        }
    }

    /********************************************************************
                AMPLITUDE - EVENTOS DO GRUPO BTN_BACK
    *********************************************************************/
     logAmplitudeButtonBack() {
         AmplitudeService.setLogRouter(true);
    }
}
