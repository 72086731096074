import BSDateCatcherComponent from '@/components/buson/bs-date-catcher';
import { BSAlertComponent } from '@/components/buson/bs-alert/bs-alert';
import { BSButtonComponent } from '@/components/buson/bs-button';
import { BSButtonBackComponent } from '@/components/buson/bs-button-back/bs-button-back';
import { BSSelectorComponent } from '@/components/buson/bs-selector/bs-selector';
// import { BSButtonBackComponent } from '../../../../../sisb-common/src/components/buson/bs-button-back/bs-button-back';
import { BSCardTableComponent } from '@/components/buson/bs-card-table/bs-card-table';
import { BSPageTableComponent } from '@/components/buson/bs-page-table/bs-page-table';
import { BSDropdownComponent } from '@/components/buson/bs-dropdown/bs-dropdown';
import { BSFeedbackComponent } from '@/components/buson/bs-feedback/bs-feedback';
import { GPMultiselectComponent } from '@/components/buson/gp-multiselect/gp-multiselect';
import { BSMultiselectFilterComponent } from '@/components/buson/bs-multiselect-filter/bs-multiselect-filter';
import { BSFormBuilderComponent } from '@/components/buson/bs-form-builder/bs-form-builder';
import { BSPaginationComponent } from '@/components/buson/bs-pagination/bs-pagination';
import { BSSmallCardComponent } from '@/components/buson/bs-small-card/bs-small-card';
import AnticipationModalComponent from '@/modules/module-account/components/shared/anticipation-modal';
import StepContainerComponent from '@/modules/module-account-opening/components/shared/step.vue';
import CompanyModal from '@/modules/module-account-opening/components/shared/company-modal.vue';
import CNPJModal from '@/modules/module-account-opening/components/shared/cnpj-modal.vue';
import PEPModal from '@/modules/module-account-opening/components/shared/pep-modal.vue';
import { BSModalDisclaimerComponent } from '@/components/buson/bs-modal-disclaimer';
import { BSBlockquoteComponent } from '@/components/buson/bs-blockquote/bs-blockquote';
import { BSLoadingComponent } from '@/components/buson/bs-loading/bs-loading';


export const COMPONENTS_GLOBAL_CONSTANTS: Array<{alias: string; component: any}> = [
  { alias: 'bs-button', component: BSButtonComponent },
  { alias: 'bs-alert', component: BSAlertComponent },
  { alias: 'bs-anticipation-modal', component: AnticipationModalComponent },
  { alias: 'bs-feedback', component: BSFeedbackComponent },
  { alias: 'bs-small-card', component: BSSmallCardComponent },
  { alias: 'bs-date-catcher', component: BSDateCatcherComponent },
  { alias: 'bs-pagination', component: BSPaginationComponent },
  { alias: 'bs-card-table', component: BSCardTableComponent },
  { alias: 'bs-page-table', component: BSPageTableComponent},
  { alias: 'bs-dropdown', component: BSDropdownComponent},
  { alias: 'bs-multiselect-filter', component: BSMultiselectFilterComponent },
  { alias: 'gp-multiselect', component: GPMultiselectComponent },
  { alias: 'bs-form-builder', component: BSFormBuilderComponent },
  { alias: 'bs-button-back', component: BSButtonBackComponent },
  { alias: 'bs-selector', component: BSSelectorComponent },
  { alias: 'bs-steps', component: StepContainerComponent },
  { alias: 'bs-company-modal', component: CompanyModal },
  { alias: 'bs-cnpj-modal', component: CNPJModal },
  { alias: 'bs-pep-modal', component: PEPModal },
  { alias: 'bs-modal-disclaimer', component: BSModalDisclaimerComponent },
  { alias: 'bs-blockquote', component: BSBlockquoteComponent},
  { alias: 'bs-loading', component: BSLoadingComponent},
];
