import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface UI {
    visible: boolean;
    containerStyle: any;
}

export interface BSDropDownItem {
    text: any;
    item: any;
}

enum RenderType {
    TOP_TO_BOTTOM = 'top-to-bottom',
    BOTTOM_TO_TOP = 'bottom-to-top',
    FULL_SCREEN = 'full-screen'
}

@Component({
    template: require('./bs-dropdown.html')
})
export class BSDropdownComponent extends Vue {

    ui: UI = {
        visible: false,
        containerStyle: {}
    };

    @Prop([Boolean])
    value: any;

    @Prop(String)
    listClass!: string;

    @Prop([Array])
    itens!: Array<BSDropDownItem>;

    onSelect(dropDownItem: BSDropDownItem) {
        if ((typeof dropDownItem.item) !== 'string') {
            dropDownItem.item();
        }
        this.$emit('select', dropDownItem);
        this.hide();
    }

    onClickOutside(event: any) {
        this.hide();
    }

    getLabelByDropDownItem(dropDownItem: BSDropDownItem) {
        if ((typeof dropDownItem.text) === 'string') {
            return dropDownItem.text;
        }
        return dropDownItem.text();
    }

    toggle() {
        this.ui.visible ? this.hide() : this.show();
    }

    show() {
        this.ui.visible = true;
        this.$emit('input', this.ui.visible);
        this.doEnableContainerPositionFix();
    }

    hide() {
        this.ui.visible = false;
        this.$emit('input', this.ui.visible);
        this.doDisableContainerPositionFix();
    }

    @Watch('value')
    onValueChange(val: boolean) {
        this.ui.visible = val;
    }

    doEnableContainerPositionFix() {
        const element = this.$el.querySelector('.card.list-group');
        const widthscreen = screen.width;
        if (element!.getBoundingClientRect().right > screen.width) {
            this.ui.containerStyle = { 'margin-left': `${this.$el.getBoundingClientRect().right - element!.getBoundingClientRect().right}px` };
        }
    }

    doDisableContainerPositionFix() {
        this.ui.containerStyle = {};
    }

}
