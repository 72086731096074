import { AnticipationFactory } from "@/modules/factory/anticipation/anticipation.factory";
import { AMPLITUDE_GROUPS } from "@/modules/module/constants/amplitude-groups.constants";
import { AmplitudeService } from "@/modules/module/services/amplitude/amplitude.service";
import { NotificationUtil } from "@/modules/module/util/notification.util";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    template: require('./anticipation-modal.html'),
})
export default class AnticipationModalComponent extends Vue {
    @Prop()
    showModal = false;

    @Prop({ required: true, default: '' })
    companyName!: string;

    get show() {
        return this.showModal;
    }

    close() {
        this.$emit('close-modal');
    }

    openModal() {
        AmplitudeService.logEvent('modal_anticipation_open', {group: AMPLITUDE_GROUPS.ACCOUNT});
        this.showModal = true;
    }

    async action() {
        try {
            AmplitudeService.logEvent('modal_anticipation_ask', {group: AMPLITUDE_GROUPS.ACCOUNT});
            await AnticipationFactory.create(this.companyName);
            NotificationUtil.especialSuccess("sua solicitação foi realizada com sucesso, aguarde contato do financeiro!");
            this.close();
        } catch(e) {
            NotificationUtil.warning('Ocorreu um erro ao solicitar a antecipação!');
            this.close();
        }
    }
}
