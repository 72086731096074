import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import merge from 'lodash/merge';
import { NetworkError, RequestError } from '@/modules/module/model/client/error';
import { AuthenticationService } from '../module-authentication/authentication.service';
import { NotificationUtil } from '@/modules/module/util/notification.util';
import { VUE_APP_SITE_SUITE_URL } from '../module/constants/environment.constants';

export class BaseFactory {
  private axios: AxiosInstance;
  protected requestConfig: AxiosRequestConfig;
  private redirected = false;

  constructor(BaseApi: string) {
    const https = require('https');
    this.requestConfig = {
      baseURL: BaseApi || process.env.VUE_APP_API_URL,
      timeout: 150000,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    };
    this.axios = axios.create(this.requestConfig);
    this.axios.interceptors.response.use(this.onRequestSuccess, this.onRequestError);
  }

  protected post = async (url: string, body: any, config?: any) => {
    const result = await this.axios.post(url, body, this.getRequestConfigAssigned(config));
    return result.data;
  }

  protected put = async (url: string, body: any, config?: any) => {
    const result = await this.axios.put(url, body, this.getRequestConfigAssigned(config));
    return result.data;
  }

  protected patch = async (url: string, body: any, config?: any) => {
    const result = await this.axios.patch(url, body, this.getRequestConfigAssigned(config));
    return result.data;
  }

  protected get = async (url: string, config?: any) => {
    const result = await this.axios.get(url, this.getRequestConfigAssigned(config));
    return result.data;
  }

  protected delete = async (url: string, config?: any) => {
    const result = await this.axios.delete(url, this.getRequestConfigAssigned(config));
    return result.data;
  }

  private onRequestSuccess = (response: any) => {
    return response;
  }

  private onRequestError = (error: any) => {
    return Promise.reject(this.toError(error));
  }

  private toError(error: any) {
    // Valida caso o token tenha expirado, redireciona para o login
    if (
      this.redirected === false
      && (error
      && error.response
      && error.response.data
      && error.response.status === 401)
    ) {
        NotificationUtil.warning('Existe outra sessão ativa para este usuário');
        window.location.href = VUE_APP_SITE_SUITE_URL;
        AuthenticationService.logout();
        this.redirected = true;
        return;
    }

    if (error.message === 'Network Error') {
      return new NetworkError(error, error.config.url);
    }

    if (error.response.status === 401 && !AuthenticationService.isAuthenticated) {
      window.location.href = VUE_APP_SITE_SUITE_URL;
      setTimeout(() => {
        AuthenticationService.logout();
      }, 1000);
    }

    return new RequestError(
      error,
      error.config.url,
      error.statusCode || error.response.status,
      error.response.data
    );
  }

  private getRequestConfigAssigned(config?: AxiosRequestConfig) {
    config = config || {};
    const configInitial = merge({}, this.requestConfig);
    if (!configInitial.headers) {
      configInitial.headers = {};
    }

    configInitial.headers['Content-Type'] = 'application/json';
    configInitial.headers['Authorization'] = 'bearer ' + AuthenticationService.token;
    return merge(configInitial, config) as AxiosRequestConfig;
  }

  protected getPdf = async (url: string, config?: any) => {
    const pdfConfig = merge({}, config);
    pdfConfig.responseType = 'arraybuffer';
    if (!pdfConfig.headers) pdfConfig.headers = {};
    pdfConfig.headers['Accept'] = 'application/pdf';

    const result = await this.axios.get(url, this.getRequestConfigAssigned(pdfConfig));
    return result;
  }

  protected getXls = async (url: string, config?: any) => {
    const xlsConfig = merge({}, config);
    xlsConfig.responseType = 'arraybuffer';
    if (!xlsConfig.headers) xlsConfig.headers = {};
    xlsConfig.headers['Accept'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    const result = await this.axios.get(url, this.getRequestConfigAssigned(xlsConfig));
    return result;
  }
}
