import * as DOMPurify from 'dompurify';

export class Util {

    isObject(value: any) {
        return !!(value && typeof value === 'object' && !Array.isArray(value));
    }

    isArray(value: any) {
        return !!(value && typeof value === 'object' && Array.isArray(value));
    }

    public clearObject(model: any) {
        Object.keys(model).forEach(key => {
            const value = model[key];
            if (typeof value === 'string') {
                model[key] = this.clearValue(value);
            } else if (this.isObject(value)) {
                this.clearObject(value);
            } else if (this.isArray(value)) {
                value.forEach((internalValue: any) => this.clearObject(internalValue));
            }
        });
    }

    public clearValue(val: any) {
        if (typeof val === 'string') {
            return DOMPurify.sanitize(
                val,
                {
                    ALLOWED_TAGS: ['b', 'span', 'i', 'div',
                    'br', 'p', 'ul', 'li'],
                    ALLOWED_ATTR: ['style', 'class']
                }
            );
        }
        return val;
    }
}

export const SanitizerUtil = new Util();
