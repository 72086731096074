import { Component, Vue } from 'vue-property-decorator'
import { AppBroadcastEventBus, AppBroadcastEvent } from './app.broadcast-event-bus';
import { AuthenticationService } from './modules/module-authentication/authentication.service';
import { ApplicationConfigService } from '@/modules/module/services/application-config/application-config.service';
import { AmplitudeService } from './modules/module/services/amplitude/amplitude.service';
import { BSSidebarComponent } from './components/layout/bs-sidebar/bs-sidebar';
import { BSTaskbarComponent } from './components/layout/bs-taskbar/bs-taskbar';
import { BSLoaderComponent } from './components/buson/bs-loader/bs-loader';
import { StationFactory } from './modules/factory/station-factory/station-factory';
import moment from 'moment';
import BSContactComponent from './components/layout/bs-contact/bs-contact';
import { BSModalEmailComponent } from './components/bs-modal-email';
import { VUE_APP_SITE_SUITE_URL } from './modules/module/constants/environment.constants';
import { MultiCompanyService } from './modules/module/services/multi-company/multi-company.service';
import { MeFactory } from './modules/factory/profile/me.factory';

@Component({
  template: require('./app.html'),
  components: {
    'bs-taskbar': BSTaskbarComponent,
    'bs-sidebar': BSSidebarComponent,
    'bs-loader': BSLoaderComponent,
    'bs-contact': BSContactComponent,
    'bs-modal-email': BSModalEmailComponent,
  }
})
export class AppComponent extends Vue {
  showTaskbar = false;
  showLoader = false;
  showSidebar = false;
  sidebarExpand = false;
  showContact = false;
  isLoadingCompanies = true;

  private _factory!: StationFactory;

  created() {
    this.registerBroadcastEvents();

    const user = AuthenticationService.user;
    const isSefazPermission = user.groups.find(g => g === "SEFAZ");

    if (!isSefazPermission) {
      ApplicationConfigService.loadAppInfo();
      this._factory = new StationFactory();

      const expireString = +localStorage.getItem('stations_expire_in')!;
      const expireIn = moment.unix(expireString);
      if (expireString === null || expireIn.isBefore(moment())) {
          this._factory.getStations().then((stations) => {
              localStorage.setItem('stations_expire_in', moment().add(7, 'days').format('X'));
              localStorage.setItem('stations', JSON.stringify(stations));
          });
      }
    }
  }

  async mounted() {
    await MultiCompanyService.userCompanies();
    await MeFactory.me();

    this.isLoadingCompanies = false;
  }

  registerBroadcastEvents() {
    AppBroadcastEventBus.$on(AppBroadcastEvent.EXIBIR_LOADER, () => {
        this.showLoader = true;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.ESCONDER_LOADER, () => {
        this.showLoader = false;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.AUTENTICADO, async () => {
        try {
            AuthenticationService.automaticRefresh();
            AppBroadcastEventBus.$emit(AppBroadcastEvent.EXIBIR_TASKBAR);
            AppBroadcastEventBus.$emit(AppBroadcastEvent.EXIBIR_SIDEBAR);
            AppBroadcastEventBus.$emit(AppBroadcastEvent.EXIBIR_CONTACT);
        } catch (e) {
            console.error(e);
            AuthenticationService.logout();
            // AppRouter.stopRefreshToken();
        }
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.DESAUTENTICADO, () => {
        AppBroadcastEventBus.$emit(AppBroadcastEvent.ESCONDER_TASKBAR);
        AppBroadcastEventBus.$emit(AppBroadcastEvent.ESCONDER_SIDEBAR);
        window.location.href = VUE_APP_SITE_SUITE_URL;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.EXIBIR_TASKBAR, () => {
        this.showTaskbar = true;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.ESCONDER_TASKBAR, () => {
        this.showTaskbar = false;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.EXIBIR_SIDEBAR, () => {
        this.showSidebar = true;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.ESCONDER_SIDEBAR, () => {
        this.showSidebar = false;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.EXIBIR_CONTACT, () => {
      this.showContact = true;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.ESCONDER_CONTACT, () => {
        this.showContact = false;
    });

    AppBroadcastEventBus.$on(AppBroadcastEvent.TOGGLE_SIDEBAR, () => {
        this.showSidebar = !this.showSidebar;
        this.logAmplitudeSidebar();
    });
  }

  get isHiddenComponents() {
    return !this.isLoadingCompanies;
  }

  get isLayoutTaskbarVisible() {
    return this.showTaskbar;
  }

  get isLayoutSidebarVisible() {
    return this.showSidebar;
  }

  get isLoaderVisible() {
    return this.showLoader;
  }

  get isLayoutContactVisible() {
    const user = AuthenticationService.user;
    const isSefazPermission = user.groups.find(g => g === "SEFAZ");

    if (!isSefazPermission) {
      return this.showContact;
    }
  }

  handleTaskbarToggleExpand(expand: boolean) {
    this.sidebarExpand = expand;
  }

  handleSidebarHover(hover: boolean) {
      this.sidebarExpand = hover;
  }

  /********************************************************************
    AMPLITUDE - EVENTOS DO GRUPO TASKBAR
  *********************************************************************/
  // Mapear uso da sidebar - se há necessidade de deixar aberto por padrão
  logAmplitudeSidebar() {
    try {
      AmplitudeService.logEvent('taskbar_toggle_sidebar', {
        sidebar_toggle: this.showSidebar,
      });
    } catch (error) {
      console.error('Amplitude event error: logAmplitudeSidebar');
    }
  }

}
