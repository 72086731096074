import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    template: require('./bs-alert.html'),
})
export class BSAlertComponent extends Vue {
    @Prop({ type: String, default: '' })
    icon!: string;

    @Prop({ type: String, default: '' })
    message!: string;

    @Prop({type: Function })
    action?: Function;

    @Prop({ type: String })
    buttonText?: string;

    @Prop({ type: Boolean, default: true })
    showButton?: boolean;
}
