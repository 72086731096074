import { BaseFactory } from '../base.factory';
import { MeModel } from '@/modules/module/model/client/me-model';

class Factory extends BaseFactory {

  private title = 'Me';
  private _me: MeModel|null = null;

  constructor() {
    super(process.env.VUE_APP_API_URL);
  }

  public me = async (): Promise<MeModel> => {
    if(!this._me) {
      this._me = await this.get('/me', undefined) as MeModel;
    }

    return this._me;
  }

  public remeberEmailValidation = async() => {
    const result = await this.post('/user/remember-email-validation', null);
    return result;
  }
}

export const MeFactory = new Factory();
