import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/pt_BR.json'
import { DICTIONARY } from '@/modules/module/constants/vee-validate.constants'

for (const [rule, validation] of Object.entries(rules)) {
  let message = (messages as any)[rule] as string;

  const newMessage = (DICTIONARY as any)[rule] as string;
  if (newMessage) {
    message = newMessage;
  }

  extend(rule, {
    ...validation,
    message
  });
}
