import Keycloak from 'keycloak-js'
import {OAUTH_CLIENT_ID, OAUTH_REALM, OAUTH_URL, VUE_APP_SITE_SUITE_URL} from '@/modules/module/constants/environment.constants';
import { UserAuth } from '@/modules/module/model/client/user-auth';
import { MultiCompanyService } from '../module/services/multi-company/multi-company.service';

const TOKEN_KEY = "JWT";
const REFRESH_TOKEN_KEY = "REFRESH_JWT";
const CNPJ = "cnpj";

const initOptions: any = {
  url: OAUTH_URL, realm: OAUTH_REALM, clientId: OAUTH_CLIENT_ID, onLoad: 'login-required'
};

export type TokenParsed = Keycloak.KeycloakTokenParsed;

class Service {
  private readonly MIN_TOKEN_VALIDITY = 70;

  private readonly _keycloak: Keycloak.KeycloakInstance;
  private _tokenParsed?: Keycloak.KeycloakTokenParsed;
  private _token?: string;
  private _refreshToken?: string;
  private _timeSkew = 0;
  private _isAuthenticated = false;
  private _refreshed = false;

  constructor() {
    this._keycloak = Keycloak(initOptions);
  }

  get keycloak(): Keycloak.KeycloakInstance {
    return this._keycloak;
  }

  get parsedToken(): TokenParsed| undefined {
    return this._tokenParsed;
  }

  get token(): string {
    return this._token || '';
  }

  get refreshToken(): string {
    return this._refreshToken || '';
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  get isRefreshed(): boolean {
    return this._refreshed;
  }

  get expInSeconds(): number {
    if (this._tokenParsed && this._tokenParsed.exp !== null && this._tokenParsed.exp !== undefined) {
      return Math.round(this._tokenParsed.exp + this._timeSkew - new Date().getTime() / 1000);
    }

    return 0;
  }

  get expDate(): Date {
    if (this._tokenParsed && this._tokenParsed.exp !== null && this._tokenParsed.exp !== undefined) {
      return new Date(this._tokenParsed.exp * 1000);
    }

    return new Date();
  }

  get user(): UserAuth {
    const user = {} as UserAuth;
    if (this.parsedToken) {
      const properties = this.parsedToken as any;
      user.id = properties.sub;
      user.username = properties.preferred_username;
      user.name = properties.name;
      user.givenName = properties.given_name;
      user.familyName = properties.family_name;
      user.email = properties.email;
      user.groups = properties.groups;
      user.authorities = properties.authorities;
    }
    return user;
  }

  private redirectToSite() {
    const action = () => {
      setTimeout(() => {
        window.location.href = VUE_APP_SITE_SUITE_URL;
      }, 3000);
    }

    this._keycloak.onTokenExpired = action;
    this._keycloak.onAuthLogout = action;
  }

  login(automaticRefresh = true): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._keycloak.init({onLoad: initOptions.onLoad}).then((auth) => {
        this._isAuthenticated = auth;

        if (auth) {
          this._tokenParsed = this._keycloak.tokenParsed;
          this._token = this._keycloak.token as string;
          this._refreshToken = this._keycloak.refreshToken as string;
          localStorage.setItem(TOKEN_KEY, this._token);
          localStorage.setItem(REFRESH_TOKEN_KEY, this._refreshToken);

          this.redirectToSite();
        }

        if (automaticRefresh) {
          this.automaticRefresh();
        }

        resolve(auth);
      }).catch(() => {
        console.error("Authenticated Failed");
        this._isAuthenticated = false;
        window.location.href = VUE_APP_SITE_SUITE_URL;
        reject(false);
      });
    });
  }

  logout(redirectUri?: string) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(CNPJ);
    MultiCompanyService.clearWorkspaceCompany();
    this._keycloak.logout({redirectUri});
  }

  refresh() {
    this._keycloak.updateToken(this.MIN_TOKEN_VALIDITY).then((refreshed: boolean) => {
      this._refreshed = refreshed;
      this._tokenParsed = this._keycloak.tokenParsed;
      this._timeSkew = this._keycloak.timeSkew || 0;

      if (refreshed) {
        // console.log('Token refreshed.');
      } else {
        // console.log(`Token not refreshed, valid for ${this.expInSeconds} seconds.`);
      }
    }).catch(() => {
      console.error('Failed to refresh token');
      this._isAuthenticated = false;
      window.location.href = VUE_APP_SITE_SUITE_URL;
    });
  }

  automaticRefresh(seconds = 10) {
    setInterval(() => {
      this.refresh();
    }, seconds * 1000);
  }
}

export const AuthenticationService = new Service();
