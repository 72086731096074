

















import Vue from 'vue'
import { Component } from 'vue-property-decorator';


@Component
export default class PEPModal extends Vue {
  close() {
    this.$emit('input', false);
  }
}
