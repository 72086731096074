import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { BSCardTableColumn, BSCardTableMenu } from '../bs-card-table/bs-card-table.types';
import { GSPagination } from '../bs-pagination/bs-pagination';
import { NotificationUtil } from '@/modules/module/util/notification.util';
import { Page } from '@/modules/module/model/server/page';

interface UI {
    itens: Array<any>;
    gpPagination: GSPagination;
    filterData: any;
}

@Component({
    template: require('./bs-page-table.html')
})
export class BSPageTableComponent extends Vue {

    @Prop([Function])
    query: any;

    @Prop({ type: Array })
    columns!: Array<BSCardTableColumn>;

    @Prop([Object])
    menu!: BSCardTableMenu;

    @Prop(Number)
    pageSize?: number;

    @Prop({ type: String, default: null })
    title!: string;

    @Prop({ type: Boolean, default: false, required: false })
    showBottomPagination!: boolean;

    @Prop()
    titleOutsideCard!: string;

    ui: UI = {
        itens: new Array<any>(),
        gpPagination: { page: 1, limit: this.getPageSize, pageTotal: 1, totalItens: null },
        filterData: {}
    };

    @Watch('query')
    async onQueryChange(val: any) {
        this.ui.gpPagination = { page: 1, limit: this.getPageSize, pageTotal: 1, totalItens: null };
        this.doQuery(this.ui.gpPagination, this.ui.filterData);
    }

    onPaginationSettingsChanged(gpPagination: GSPagination) {
        this.ui.gpPagination = gpPagination;
        this.doQuery(this.ui.gpPagination, this.ui.filterData);
    }

    onFilterSubmit(data: any) {
        this.ui.filterData = data;
        this.ui.gpPagination.page = 1;
        this.doQuery(this.ui.gpPagination, this.ui.filterData);
    }

    public reload() {
        this.doQuery(this.ui.gpPagination, this.ui.filterData);
    }

    async mounted() {
        this.doQuery(this.ui.gpPagination, this.ui.filterData);
    }

    get getPageSize() {
        return this.pageSize || 25;
    }

    async doQuery(gpPagination: GSPagination, filterData: any) {

        if (!this.query || !this.columns)
            return;

        try {
            this.ui.itens = [];
            const result = await this.query(gpPagination.page - 1, gpPagination.limit, filterData) as Page<any>;
            this.ui.itens = result.content;

            this.ui.gpPagination = {
                page: result.totalPages > 0 ? result.number + 1 : 0,
                pageTotal: result.totalPages,
                limit: gpPagination.limit,
                totalItens: result.totalElements
            };
        }
        catch (error) {
            NotificationUtil.exception(error as string);
        }
    }

    get getColumns() {
        return this.columns || [
            new BSCardTableColumn((item) => item.id, () => 'ID'),
            new BSCardTableColumn((item) => item.label, () => 'Informação')
        ];
    }

    get getItens() {
        return this.ui.itens;
    }

    get getMenu() {
        return this.menu;
    }

    public updateItem(newItem: any, predicate: (item: any) => boolean) {
        if (!this.getItens || !newItem || !predicate) {
            return;
        }

        const index = this.getItens.findIndex(predicate);
        if (index !== -1) {
            this.ui.itens[index] = newItem;
        }
    }
}
