export class Page<T> {
  constructor() {
      this.content = [];
      this.last = true;
      this.totalPages = 1;
      this.size = 10;
      this.number = 0;
  }

  content: Array<T>;
  last: boolean;
  first!: boolean;
  sort!: boolean;
  size: number;
  number: number;
  totalPages: number;
  totalElements!: number;
  numberOfElements!: number;
}
