import { RouteConfig } from 'vue-router'
import { RouterPath } from '@/enums/router-path.enum'

export default (): Array < RouteConfig > => {
  return [
    {
      path: RouterPath.ROADMAP,
      component: () => import('./components/pages/page-roadmap/page-roadmap').then(m => m.default)
    },
    {
      path: RouterPath.ROADMAP_HISTORY,
      component: () => import('./components/pages/page-roadmap-history/page-roadmap-history').then(m => m.default)
    },
  ]
}
