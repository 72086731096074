import { Vue } from 'vue-property-decorator';
import { RequestError, ErrorResponse, NetworkError } from '@/modules/module/model/client/error';
import { I18nUtil } from './i18n/i18n.util';

interface Option {
    key: string;
    value: any;
}

class Util extends Vue {

    $toasted: any;

    private optionsDefault: any = {
        duration: 7000,
        theme: 'toasted-primary',
        type: 'info',
        fullWidth: true,
        fitToScreen: true,
        position: 'bottom-center',
        className: 'toasted-suite',
        containerClass: 'toasted-container-suite',
        singleton: true,
        action: {
            icon: 'close',
            onClick: (e: any, toastObject: any) => {
                toastObject.goAway(0);
            }
        }
    };

    public error = (message: string, options?: Array<Option>) => {
        message = `<div class="toasted-text"><i class="material-icons">error_outline</i> ${message}</div>`;
        this.optionsDefault.type = 'error';
        this.optionsDefault.duration = 5000;
        if (options) {
            this.setOptionDefault(options);
        }
        this.$toasted.show(message, this.optionsDefault);
    }

    public especialSuccess = (message: string, options?: Array<Option>) => {
        message = `<div class="toasted-text"><i class="material-icons">check</i> ${message}</div>`;
        this.optionsDefault.type = 'success';
        this.optionsDefault.containerClass = 'toasted-container-suite especial-success';
        this.optionsDefault.duration = 5000;
        if (options) {
            this.setOptionDefault(options);
        }
        this.$toasted.show(message, this.optionsDefault);
    }

    public success = (message: string, options?: Array<Option>) => {
        message = `<div class="toasted-text"><i class="material-icons">check</i> ${message}</div>`;
        this.optionsDefault.type = 'success';
        if (options) {
            this.setOptionDefault(options);
        }
        this.$toasted.show(message, this.optionsDefault);
    }

    public info = (message: string, options?: Array<Option>) => {
        message = `<div class="toasted-text"><i class="material-icons">info_outline</i> ${message}</div>`;
        this.optionsDefault.type = 'info';
        if (options) {
            this.setOptionDefault(options);
        }
        this.$toasted.show(message, this.optionsDefault);
    }

    public warning = (message: string, options?: Array<Option>) => {
        message = `<div class="toasted-text"><i class="material-icons">warning</i> ${message}</div>`;
        this.optionsDefault.type = 'warning';
        this.optionsDefault.duration = 10000;
        this.optionsDefault['singleton'] = true;
        if (options) {
            this.setOptionDefault(options);
        } else {
            this.setOptionDefault([{ key: 'singleton', value: true }]);
        }
        this.$toasted.show(message, this.optionsDefault);
    }

    public errorResponse = (error: ErrorResponse) => {
      if (error.children && error.children.length) {
          error.children.forEach(x => {
              this.warning(I18nUtil.resolveMessage(x));
          });
      }
      else {
          this.warning(I18nUtil.resolveMessage(error));
      }
  }

  public exception = (error: Error | string) => {
      if (typeof error === 'string') {
          this.warning(error);
          return;
      }

      switch (error.constructor) {
          case (RequestError): {
              break;
          }
          case (NetworkError): {
              break;
          }
          default: {
              break;
          }
      }
  }

    private setOptionDefault(options: Array<Option>) {
        options.forEach(opt => this.optionsDefault[opt.key] = opt.value);
        this.$toasted.options = this.optionsDefault;
    }

}

export const NotificationUtil = new Util();
