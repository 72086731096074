
import { ErrorResponse, ErrorResponseType } from '@/modules/module/model/client/error';
import { I18nMessageConstants } from '@/modules/module/constants/i18n-messages.constant';
import { I18nApiMessageConstants } from '@/modules/module/constants/i18n-api-messages.constant';
import moment from 'moment';

export enum MessageSource {
    API_MESSAGES = 'API_MESSAGES',
    MESSAGES = 'MESSAGES'
}

export enum MessagePropertyValueType {
    STRING, DATE, ARRAY
}

class Util {

    public toHtml = (error: ErrorResponse, messageSource?: MessageSource) => {
        let text = this.resolveTitle(error, messageSource);
        text += `\n ${this.resolveMessage(error, messageSource)}`;
        return text;
    }

    public toString = (error: ErrorResponse, messageSource?: MessageSource) => {
        return this.toHtml(error, messageSource).replace(/<(?:.|\n)*?>/gm, '');
    }

    public resolveTitle = (error: ErrorResponse, messageSource?: MessageSource | any) => {
        const messages = this.retrieveI18nMessagesByMessageSource(messageSource);
        const message = messages.find(x => x.key === error.code);
        const text = message && message.title && this.replaceProperties(message.title, error.properties) || '';
        return text;
    }

    public resolveMessage = (error: ErrorResponse, messageSource?: MessageSource | any) => {
        const messages = this.retrieveI18nMessagesByMessageSource(messageSource);
        let message = messages.find(x => x.key === error.code);
        let text;
        if (message) {
            text = this.replaceProperties(message.msg, error.properties);

        } else if (error.properties && error.properties.length) {
            text = error.properties[0].value.trim();
            if (!['.', '!', '?', ':'].includes(text.substr(-1))) {
                text += '!';
            }
        } else {
            message = messages.find(x => x.key === '400');
            text = this.replaceProperties(message?.msg, []);
        }
        return text;
    }

    private replaceProperties = (text: any, properties: Array<ErrorResponseType>) => {
        properties.forEach(x => {
            while (text.indexOf(`{${x.name}}`) > -1) {
                switch (this.retrieveValueType(x)) {
                    case (MessagePropertyValueType.DATE): {
                        text = text.replace(`{${x.name}}`, `<b>${moment(x.value).format('DD/MM/YYYY')}</b>`);
                        break;
                    }
                    case (MessagePropertyValueType.STRING): {
                        text = text.replace(`{${x.name}}`, `<b>${x.value}</b>`);
                        break;
                    }
                    case (MessagePropertyValueType.ARRAY): {
                        text = text.replace(`{${x.name}}`, ``);
                        x.value.forEach((x: { source: any; message: any }) => {
                            text += `<br>${x.source} : <b>${x.message}</b></br>`;
                        });
                        break;
                    }
                }
            }
        });
        return text;
    }

    private retrieveI18nMessagesByMessageSource = (messageSource: MessageSource) => {
        switch (messageSource) {
            case (MessageSource.MESSAGES): {
                return I18nMessageConstants;
            }
            case (MessageSource.API_MESSAGES):
            default: {
                return I18nApiMessageConstants;
            }
        }
    }

    private retrieveValueType = (properties: ErrorResponseType) => {
        if (properties.type && properties.type.toLowerCase().indexOf('date') > -1) {
            return MessagePropertyValueType.DATE;
        }
        else if (properties.type && properties.type.toLocaleLowerCase().indexOf('arraylist') > -1) {
            return MessagePropertyValueType.ARRAY;
        }
        else {
            return MessagePropertyValueType.STRING;
        }
    }

}

export const I18nUtil = new Util();

