import { NotificationUtil } from '@/modules/module/util/notification.util';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MultiselectResource } from '../bs-multiselect-filter/bs-multiselect-filter.types';
import { MultiselectFilterUtil } from '../bs-multiselect-filter/bs-multiselect-filter.util';
import { BSFormBuilderInput, BSFormBuilderInputGPSelector } from './bs-form-builder.types';
import { BSFormBuilderUtil } from './bs-form-builder.util';

interface Data {
    values: any;
}

interface UI {
    inputs: Array<BSFormBuilderInput>;
}

@Component({
    template: require('./bs-form-builder.html')
})
export class BSFormBuilderComponent extends Vue {

    data: Data = {
        values: {}
    };

    ui: UI = {
        inputs: [],
    };

    @Prop({ type: Array, default: () => [] })
    inputs!: Array<BSFormBuilderInput>;

    @Prop({ type: String, default: 'Salvar' })
    submitLabel!: string;

    @Prop({ type: String, default: null })
    cardTitle!: string;

    @Prop({ type: Boolean, default: false })
    submitOnEnter!: boolean;

    submitData(data: any) {
        this.$emit('submit-data', data);
    }

    async created() {
        this.ui.inputs = await Promise.all(this.inputs.map(async input => {
            if (this.util.isGPFormBuilderInputGPSelector(input)) {
                const selectorInput = input as BSFormBuilderInputGPSelector;
                const itens = await this.getListFromQuery(selectorInput.query);

                const name = 'name' in itens[0] && 'name'
                    || 'label' in itens[0] && 'label'
                    || 'description' in itens[0] && 'description';

                selectorInput.itens = itens.map((item: any, index: any) => ({ index, ...item, }));

                selectorInput.multiselectItens
                    = MultiselectFilterUtil.convertToMultiselectArray(selectorInput.itens!, 'index', name as string);
                return selectorInput;
            }
            return input;
        }));
    }

    util = BSFormBuilderUtil;

    submitIfIsAble(data: any) {
        if (this.submitOnEnter) {
            this.submitData(data);
        }
    }

    async getListFromQuery(query: any) {
        try {
            if (typeof query === 'function') {
                const result = await query();
                return result;
            }
            return [];
        } catch (error) {
            NotificationUtil.warning(error as string);
        }
    }

    updateInput(items: MultiselectResource[], key: string) {
        const input = this.inputs.find(inputs => inputs.key === key);

        if (this.util.isGPFormBuilderInputGPSelector(input!)) {
            if (!items || !items.length) {
                this.data.values[key] = null;
                return;
            }

            const inputGPSelector = (input as BSFormBuilderInputGPSelector);
            const output = inputGPSelector.outputAs as string;
            this.data.values[key]
                = MultiselectFilterUtil.getSelectedItems(items, inputGPSelector.itens!, 'index')[0][output]; // event[0] eh o item selecionado
        }
    }

    getInputClass(inputs: Array<BSFormBuilderInput>, index: number) {
        if (inputs.length % 4 === 0 && (index === inputs.length - 1 || index === inputs.length - 2)) {
            return 'col-md-2';
        }
        return 'col-md-3';
    }

}
