import { Component, Vue } from 'vue-property-decorator';
import * as Cookies from 'js-cookie';

@Component({
  template: require('./bs-modal-disclaimer.html'),
})
export class BSModalDisclaimerComponent extends Vue {
  showModal = false;

  created() {
    this.show();
  }

  show() {
    const seeModalCookie = Cookies.getJSON('disclaimerModal') || {};
      if (!seeModalCookie.hasSeenModal) {
        this.showModal = true;
        seeModalCookie.hasSeenModal = true;
        Cookies.set('disclaimerModal', seeModalCookie);
    }
  }

  hide() {
    this.showModal = false;
  }
}
