import IFilter from '../ifilter';

export default class CapitalizeFilter implements IFilter {
    public filterName: any;

    constructor() {
        this.filterName = 'capitalize';
    }

    public filterAction(text: string) {
        return this.capitalize(text);
    }

    private capitalize(text: string): string {
        if (!text) return '';

        text = text.toString().toLowerCase();

        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}
