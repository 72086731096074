import { BaseFactory } from "../base.factory";

export class Factory extends BaseFactory {
    constructor() {
        super(process.env.VUE_APP_API_URL);
    }

    public find = async() => {
      return await this.get('/company');
    }
}

export const CompanyFactory = new Factory();
