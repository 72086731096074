import { BaseFactory } from '../base.factory';
import { Company } from '@/modules/module/model/client/company-model';

class Factory extends BaseFactory {
  constructor() {
    super(process.env.VUE_APP_API_URL);
  }

  public findCompanyAnticipation = async () => {
    const result = await this.get(`/anticipation/revenue/list-company`) as Company[];
    return result;
  }

  public create = async (nameCompany: string) => {
    const result = await this.post(`/anticipation/revenue`, {
      nameCompany
    });
    return result;
  }
}

export const AnticipationFactory = new Factory();
