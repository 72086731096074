import { RouteConfig } from 'vue-router'
import { RouterPath } from '@/enums/router-path.enum'

export default (): Array<RouteConfig> => {
  return [
    {
      name: RouterPath.ACCOUNT_OPENING,
      path: RouterPath.ACCOUNT_OPENING,
      component: () => import('./components/pages/account-opening/account-opening.vue').then(m => m.default)
    },
    {
      name: RouterPath.ACCOUNT_OPENING + "/1",
      path: RouterPath.ACCOUNT_OPENING + "/1",
      component: () => import('./components/pages/account-opening/steps/step-1').then(m => m.default)
    },
    {
      name: RouterPath.ACCOUNT_OPENING + "/2",
      path: RouterPath.ACCOUNT_OPENING + "/2",
      component: () => import('./components/pages/account-opening/steps/step-2').then(m => m.default)
    },
    {
      name: RouterPath.ACCOUNT_OPENING + "/3",
      path: RouterPath.ACCOUNT_OPENING + "/3",
      component: () => import('./components/pages/account-opening/steps/step-3').then(m => m.default)
    },
    {
      name: RouterPath.ACCOUNT_OPENING + "/4",
      path: RouterPath.ACCOUNT_OPENING + "/4",
      component: () => import('./components/pages/account-opening/steps/step-4').then(m => m.default)
    },
    {
      name: RouterPath.ACCOUNT_OPENING + "/5",
      path: RouterPath.ACCOUNT_OPENING + "/5",
      component: () => import('./components/pages/account-opening/steps/step-5').then(m => m.default)
    },
    {
      name: RouterPath.ACCOUNT_OPENING + "/fim",
      path: RouterPath.ACCOUNT_OPENING + "/fim",
      component: () => import('./components/pages/account-opening/steps/end').then(m => m.default)
    },
  ]
}
