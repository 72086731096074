import IFilter from '../ifilter';
import moment from 'moment';
export default class FormatDateRangeFilter implements IFilter {
    public filterName = '';

    constructor() {
        this.filterName = 'formatDateRange';
    }

    public filterAction(startDate: Date, endDate: Date) {
        return `De ${moment(startDate).locale('pt-br').format('DD MMM')} à ${moment(endDate).locale('pt-br').format('DD MMM')}`;
    }
}
