export interface I18nApiMessage {
  key?: string;
  header?: string;
  title?: string;
  msg?: string;
  icon?: string;
  i18nFields?: Array<string>;
}

export const I18nApiMessageConstants: Array<I18nApiMessage> = [
  {
      key: '500',
      header: 'ERRO',
      msg: 'Aconteceu algo inesperado, por favor, tente novamente mais tarde',
      title: 'Erro interno do sistema',
      icon: 'popup-icon-error',
  },

  {
      key: '401',
      msg: 'Operação não autorizada para o usuário',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '400',
      msg: 'Erro durante a solicitação da requisição',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '404',
      msg: 'Não foi encontrado {name} para o identificador {id}',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '1',
      msg: 'Erro ao salvar {entity}: {error}',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'booking.seat_busy',
      msg: 'A poltrona {seat} já foi ocupada. Escolha outro disponível.',
      header: 'Atenção',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'ticket_number.out_of_bounds',
      msg: '{number} fora do intervalo permitido',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'ticket_number.used',
      msg: 'O número {value} já foi utilizado',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '14',
      msg: 'Duplicação do serviço {code}-{name} na empresa {companyId}',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '15',
      msg: 'Estudante não permitido para origem {originId} e destino {destinationId}',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '16',
      msg: 'Limite de passagens por mês excedido para o estudante {studentId}',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '17',
      msg: 'Preço padrão não existe',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'ticket_type.quota_exceeded',
      msg: 'A cota de viagem está excedida para bilhetes do tipo {ticketType}.',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '19',
      msg: 'Ponto de parada não encontrado no serviço',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '20',
      msg: 'Não encontrado ponto de parada no serviço {serviceTravelId} pela sequencia {sequence}',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '21',
      msg: 'Viagem para o passageiro(a) {customer.name} não permitida, verificar cadastro.',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '22',
      msg: 'Poltrona {seatQuota} cotada para tipo de bilhete {ticketTypeId}',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '23',
      msg: 'Passageiro(a) não cadastrado(a) ou não permitido(a) para este tipo de bilhete',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'customer.not_found',
      msg: 'Não existe um pré-cadastro para este cliente.',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'customer.already_in_travel',
      msg: '{customer} já está viajando no serviço {service}',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'confirmation.quota.time_out',
      msg:
          'A confirmação da reserva para o passageiro {customer} só pode ser realizada entre {beginDate} às {beginTime} e {endDate} às {endTime}.',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'optionalinsurance.none_available',
      msg:
          'Não foi encontrado nenhum seguro opcional disponível para venda. Verificar sincronização SeguroCloud com o administrador do sistema.',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '28',
      msg: 'COMPANYBALANCE_EXISTS(28)',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '29',
      msg: 'PARTNERBALANCE_EXISTS(29)',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'locking.seat_already_locked',
      msg: 'A poltrona {seat} está bloqueada para este trecho da linha.',
      header: 'Ocorreu um problema',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'locking.seat_being_locked',
      msg: 'A poltrona {seat} está bloqueada por outro usuário neste momento.',
      header: 'Ocorreu um problema',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '31',
      msg: 'TRAVELSURVEY_ERROR(31)',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '32',
      msg: 'GLOBUSRECORD_INVALID(32)',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'customer.elder.invalid_cpf',
      msg: '{customer} está com o CPF ({cpf}) inválido.',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'customer.elder.invalid_age',
      msg: '{customer} deve ter no mínimo {minimumAge} anos (atualmente ele(a) tem {age}).',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: '35',
      msg: 'Dados enviado: {body}. Erro: {error}.',
      title: 'Ocorreu um problema ao realizar a soma do total.',
      header: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'printing.invalid_transaction',
      msg:
          'O bilhete associado à transação [{transactionId}] não pode ser impresso. Verifique se o mesmo está somente resevado ou se sua impressão já foi realizada.',
      title: 'Ocorreu um problema',
      header: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'customer.birthday.invalid_format',
      msg: 'A data de nascimento do passageiro {customer} deve respeitar o formato {format}. Exemplo: {example} ',
      title: 'Dados Preenchidos Incorretamente',
      header: 'Atenção',
      icon: 'popup-icon-warning',
  },

  {
      key: 'field.required',
      msg: 'O campo {name} deve ser preenchido.',
      title: 'Não é possível realizar a operação',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
      i18nFields: ['name'],
  },

  {
      key: 'unlocking.lock_with_root_cause',
      msg: 'Para desbloquear a poltrona {seat}, acesse a viagem {journey}.',
      title: 'Não foi possível realizar o desbloqueio',
      header: 'Atenção',
      icon: 'popup-icon-warning',
  },

  {
      key: 'company.standard_seat_type.undefined',
      msg:
          'Não há um TIPO DE POLTRONA definido como PADRÃO para a empresa {company}. Entre em contato com o administrador do sistema.',
      title: 'Não foi possível realizar a operação',
      header: 'ATENÇÃO',
      icon: 'popup-icon-error',
  },

  {
      key: 'company.standard_ticket_type.undefined',
      msg:
          'Não há um TIPO DE BILHETE definido como PADRÃO para a empresa {company}. Entre em contato com o administrador do sistema.',
      title: 'Não foi possível realizar a operação',
      header: 'Ocorreu um Problema',
      icon: 'popup-icon-error',
  },

  {
      key: 'pricing.standard.not_found',
      msg:
          'Não há um PREÇO cadastrado como PADRÃO para a rota {journey}, tipo de bilhete {ticketType}, e tipo de poltrona {seatType} da empresa {company}. Entre em contato com o administrador do sistema.',
      title: 'Não foi possível realizar a operação',
      header: 'Ocorreu um Problema',
      icon: 'popup-icon-error',
  },

  {
      key: 'vehicle_type.invalid',
      msg: 'O ID {vehicleTypeId} do tipo de veículo informado é inválido.',
      title: 'Não foi possível realizar a operação',
      header: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'customer.route_restriction.invalid',
      msg: 'O passageiro {customer} não pode utilizar bilhetes do tipo {ticketType} para a rota {route}',
      title: 'Rota não permitida',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'customer.route_restriction.not_operating',
      msg:
          'O passageiro {customer} ({ticketType}) não pode utilizar a rota {route} {daysOfTheWeek} (dia {departure})',
      title: 'Rota não operante',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
      i18nFields: ['daysOfTheWeek'],
  },

  {
      key: 'customer.route_restriction.ticket_limit_reached',
      msg:
          'O passageiro {customer} ({ticketType}) não pode mais comprar bilhetes para a rota {route}, pois já atingiu seu limite de compras mensal de {limit} bilhete(s).',
      title: 'Compra não permitida',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'booking.ticket_type.not_allowed',
      msg:
          'Não é possível reservar a poltrona {seat} para o tipo de bilhete {customerTicketType}. Para a data e horário desejados, somente os seguintes tipos são permitidos: {allowedTicketTypes}.',
      title: 'Ação não permitida',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'ticket.unexpected_status',
      msg:
          'O bilhete com o identificador {transactionId} atualmente está {currentStatus}, porém era esperado que ele estivesse {expectedStatus}',
      title: 'A operação não pode ser realizada',
      header: 'ERRO',
      icon: 'popup-icon-error',
      i18nFields: ['currentStatus', 'expectedStatus'],
  },

  {
      key: 'ticket.not_printed',
      msg: 'O bilhete com o identificador {transactionId} não foi impresso ainda',
      title: 'A operação não pode ser realizada',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'ticket.expired',
      msg: 'O bilhete do passageiro {customer} expirou.',
      title: 'A operação não pode ser realizada',
      header: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'booking.seat.unavailable_for_sales',
      msg:
          'A poltrona {seat} não está disponível para vendas devido a uma restrição existente para bilhetes do tipo {restrictedTicketType}. Só estará a partir de {availableDate} às {availableTime}.',
      title: 'Ação não permitida',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'booking.sales_strategy.restricted_route.no_active_restrictions',
      msg:
          'É necessária uma atualização no cadastro de {customerName} (documento {customerDocument}) para a venda na data {date} com o tipo de bilhete {ticketType}',
      title: 'A operação não pode ser realizada',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'ticket_type.sales_strategy.undefined',
      msg:
          'Não há uma estratégia de venda definida para o tipo de bilhete {ticketType}. Entre em contato com o administrador do sistema.',
      title: 'A operação não pode ser realizada',
      header: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'booking.saved_customer.required',
      msg: 'É necessário um pré-cadastro para que {customer} possa utilizar bilhetes do tipo {ticketType}.',
      title: 'A operação não pode ser realizada',
      header: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'concurrency.ticket_creation.locked',
      msg: 'Outro usuário está atualmente reservando a poltrona {seat} do serviço {service} para {date}.',
      title: 'A operação não pode ser realizada',
      header: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'elders.seat_not_allowed',
      msg: 'A poltrona {seat} não pode ser vendida para idosos',
      title: 'A operação não pode ser realizada',
      header: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'confirmation.seat.invalid_type',
      msg:
          'A poltrona {seat} ({seatTypeName}) não pode ser escolhida, pois possui um tipo diferente do informado no momento da reserva ({bookedSeatTypeName}).',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'booking.opened.elders_not_allowed',
      msg: 'Não foi possível realizar a venda para {customer}, pois não é permitido vendas em aberto para idosos.',
      header: 'ERRO',
      title: 'Operação Cancelada',
      icon: 'popup-icon-error',
  },

  {
      key: 'booking.opened.required_customer_data',
      msg: 'É necessário preencher os dados do passageiro (nome e documento) para realizar vendas em aberto.',
      header: 'Ação Não Permitida',
      title: 'Operação Cancelada',
      icon: 'popup-icon-warning',
  },

  {
      key: 'service.expired',
      msg: 'O serviço {serviceName} não está disponível em {date}.',
      header: 'Operação cancelada',
      title: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'balance.company.closed',
      header: 'Alerta',
      title: 'Caixa de hoje encerrado.',
      msg: 'O fechamento de caixa da empresa do dia de hoje já foi efetuado.',
      icon: 'popup-icon-warning',
  },

  {
      key: 'balance.partner.closed',
      header: 'Alerta',
      title: 'Caixa de hoje encerrado.',
      msg: 'O fechamento de caixa da agência do dia de hoje já foi efetuado.',
      icon: 'popup-icon-warning',
  },

  {
      key: 'service.code.already_existing',
      msg: 'Já existe um serviço da empresa {companyName} cadastrado com o código {code}',
      header: 'Operação cancelada',
      title: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'input.invalid',
      msg:
          'Ocorreu um problema ao realizar um {method} para {path}. Os dados de entrada foram recusados. Entre em contato com o responsável pelo sistema. Problemas encontrados:<br/> {validations}',
      header: 'Problema de comunicação com o servidor',
      title: 'ERRO GRAVE',
      icon: 'popup-icon-error',
  },

  {
      key: 'token.expired',
      msg: 'A sua sessão expirou. Faça login novamente para continuar a utilização do sistema.',
      header: 'A operação não pode ser realizada',
      title: 'Sessão Expirada',
      icon: 'popup-icon-warning',
  },

  {
      key: 'token.invalid',
      msg: 'O token de autenticação está inválido. Entre em contato com o administrador do sistema.',
      header: 'A operação foi cancelada',
      title: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'access_denied',
      msg: 'Usuário não autorizado. Entre em contato com o administrador do sistema.',
      header: 'A operação não pode ser realizada',
      title: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'login.invalid_username',
      msg: 'Usuário {username} inexistente.',
      header: 'Dados inválidos',
      title: 'Falha de autenticação',
      icon: 'popup-icon-warning',
  },

  {
      key: 'login.wrong_password',
      msg: 'A senha digitada está incorreta.',
      header: 'Dados inválidos',
      title: 'Falha de autenticação',
      icon: 'popup-icon-warning',
  },

  {
      key: 'login.expiredPassword',
      msg: 'A sua senha está expirada.',
      header: 'Dados expirados',
      title: 'Falha de autenticação',
      icon: 'popup-icon-warning',
  },

  {
      key: 'login.samePassword',
      msg: 'A nova senha é a mesma da atual.',
      header: 'Dados inválidos',
      title: 'Falha de autenticação',
      icon: 'popup-icon-warning',
  },

  {
      key: 'login.existing_active_token',
      msg: 'Não é possível acessar o sistema, pois você já possui uma sessão ativa no momento.',
      header: 'Guiche Pass',
      title: 'Falha de autenticação',
      icon: 'popup-icon-error',
  },

  {
      key: 'cancellation.expired_time',
      msg: 'O período de cancelamento desse bilhete encerrou em {expiredDate} às {expiredTime}',
      header: 'Operação Não Permitida',
      title: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'ticket.changing.different_bus_line',
      msg:
          'A linha do bilhete (código={sourceBusLineCode}) difere da linha do serviço escolhido (código={targetBusLineCode})',
      header: 'Não foi possível realizar a remarcação',
      title: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'ticket.changing.different_stop',
      msg: 'O serviço escolhido não possui a pernada {sourceStopName} em sua rota.',
      header: 'Não foi possível realizar a remarcação',
      title: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'ticket.changing.different_price',
      msg:
          'O serviço escolhido possui um valor (R$ {sourceCurrentPrice}) superior ao pago anteriorment (R$ {targetPrice})',
      header: 'Não foi possível realizar a remarcação',
      title: 'ERRO',
      icon: 'popup-icon-error',
  },

  {
      key: 'ticket.invalid_status',
      msg:
          'O bilhete com o identificador {transactionId} atualmente está {currentStatus}, porém era esperado que ele estivesse {expectedStatus}',
      title: 'A operação não pode ser realizada',
      header: 'ERRO',
      icon: 'popup-icon-error',
      i18nFields: ['currentStatus', 'expectedStatus'],
  },

  {
      key: 'ticket.changing.existing_pending_change',
      msg: 'Existe uma remarcação não concluída para este bilhete',
      title: 'Remarcação não permitida',
      header: 'ATENÇÃO',
      icon: 'popup-icon-warning',
  },

  {
      key: 'ticket_number.series_not_found',
      msg: 'O número de série {series} não está cadastrado para utilização',
      title: 'Operação Cancelada',
      header: 'ATENÇÃO',
  },

  {
      key: 'api.offline',
      header: 'Alerta',
      title: 'Sem conexão, deslogando do sistema...',
      msg: 'Erro na conexão com o servidor',
      icon: 'popup-icon-warning',
  },

  {
      key: 'auth.pos.invalid_ip',
      msg: 'O seu endereço IP não está autorizado a acessar o sistema',
      title: 'Falha de Autenticação',
      header: 'ERRO',
      icon: 'popup-icon-erro',
  },

  {
      key: 'auth.different_mac',
      msg: 'O endereço MAC do seu computador não condiz com o utilizado durante a autenticação no sistema',
      title: 'Falha de Autenticação',
      header: 'ERRO',
      icon: 'popup-icon-erro',
  },

  {
      key: 'login.pos.non_existing_mac',
      msg: 'O seu computador não está autorizado a acessar o sistema',
      title: 'Falha de Autenticação',
      header: 'ERRO',
      icon: 'popup-icon-erro',
  },

  {
      key: 'required_header',
      msg: 'O header {name} não foi informado. Entre em contato com o administrador do sistema.',
      title: 'Falha de comunicação com o servidor',
      header: 'ERRO',
      icon: 'popup-icon-erro',
  },

  {
      key: 'concurrency.optional_insurance.locked',
      header: 'Guichê Pass',
      title: 'Problema de operação com o seguro',
      msg:
          'Ocorreu um problema de concorrência durante uma operação envolvendo o seguro opcional. Dados: número do certificado [{certificateNumber}], transação [{ticketTransId}]. Entre em contato com o resposável pelo sistema.',
      icon: 'popup-icon-error',
  },

  {
      key: 'payment.refund_already_done',
      header: 'Guichê Pass',
      title: 'Operação Cancelada',
      msg: 'Não foi possível estornar o valor do bilhete, pois já existe um estorno associado.',
      icon: 'popup-icon-error',
  },

  {
      key: 'accepted_payment_type.disabled',
      header: 'Guichê Pass',
      title: 'Operação Cancelada',
      msg:
          'A forma de pagamento {name} se encontra desabilitada para vendas. Entre em contato com o administrador do sistema.',
      icon: 'popup-icon-error',
  },

  {
      key: 'ggds.no_accepted_payment_type',
      header: 'Guichê Pass',
      title: 'Operação Cancelada',
      msg:
          'A forma de pagamento utilizado pelo GGDS não foi configurada. Entre em contato com o administrador do sistema.',
      icon: 'popup-icon-error',
  },

  {
      key: 'refunding.no_accepted_payment_type',
      header: 'Guichê Pass',
      title: 'Operação Cancelada',
      msg:
          'A forma de pagamento utilizada para reembolsos não foi configurada. Entre em contato com o administrador do sistema.',
      icon: 'popup-icon-error',
  },

  {
      key: 'accepted_payment_type.standard_needed',
      header: 'Guichê Pass',
      title: 'Operação Não Permitida',
      msg: 'É necessário que sempre exista uma Forma de Pagamento marcada como PADRÃO.',
      icon: 'popup-icon-warning',
  },

  {
      key: 'accepted_payment_type.standard.disabled',
      header: 'Guichê Pass',
      title: 'Operação Não Permitida',
      msg: 'A Forma de Pagamento não pode ser marcada como PADRÃO e estar DESABILITADA',
      icon: 'popup-icon-warning',
  },

  {
      key: 'chart_account.system.property_not_changeable',
      header: 'Guichê Pass',
      title: 'Operação Não Permitida',
      msg: 'Não é possível alterar a propriedade {property} de um registro de origem sistêmica.',
      icon: 'popup-icon-warning',
      i18nFields: ['property'],
  },

  {
      key: 'chart_account.existing_code',
      header: 'Guichê Pass',
      title: 'Operação Não Permitida',
      msg: 'O código {code} já está sendo utilizado.',
      icon: 'popup-icon-warning',
  },

  {
      key: 'service-travel.not_found',
      header: 'Guichê Pass',
      title: 'Ocorreu um problema',
      msg: 'Serviço não encontrado.',
      icon: 'popup-icon-error',
  },

  {
      key: 'reserved-seat.is_expired',
      header: 'Guichê Pass',
      title: 'Reserva expirada.',
      msg: 'Reserva expirada.',
      icon: 'popup-icon-error',
  },

  {
      key: 'token.expired',
      header: 'Guichê Pass',
      title: 'Token Expirado.',
      msg: 'Sessão Expirada.',
      icon: 'popup-icon-error',
  },

  {
      key: 'refresh_token.outdated',
      header: 'Guichê Pass',
      title: 'Refresh Token Vencido',
      msg: 'Refresh Token Vencido.',
      icon: 'popup-icon-error',
  },

  {
      key: 'bpe.locator.not_found',
      header: 'Guichê Pass',
      title: 'Bilhete não encontrado para o localizador informado',
      msg: 'Bilhete não encontrado para o localizador informado',
      icon: 'popup-icon-info',
  },

  {
      key: 'bpe.locator.already_confirmed',
      header: 'Guichê Pass',
      title: 'Bilhete já foi confirmado para o localizador informado',
      msg: 'Bilhete já foi confirmado para o localizador informado',
      icon: 'popup-icon-info',
  },

  {
      key: 'bpe.locator.disabled',
      header: 'Guichê Pass',
      title: 'Bilhete inutilizado',
      msg: 'Bilhete inutilizado',
      icon: 'popup-icon-info',
  },

  {
      key: 'bpe.locator',
      header: 'Guichê Pass',
      title: 'Não foi possível realizar o embarque',
      msg: 'Não foi possível realizar o embarque',
      icon: 'popup-icon-info',
  },

  {
      key: 'bpe.ticket.customer.document',
      header: 'Guichê Pass',
      title: 'Documento inválido',
      msg: 'Favor verificar o número do documento digitado',
      icon: 'popup-icon-info',
  },

  {
      key: 'pricing.not_found',
      header: 'Guichê Pass',
      title: 'Preço não encontrado.',
      msg: 'Preço não encontrado.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserved-seat.reserved',
      header: 'Guichê Pass',
      title: 'Poltrona já reservada.',
      msg: 'Poltrona já reservada.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserved-seat.exception',
      header: 'Guichê Pass',
      title: 'Reserva não relizada.',
      msg: 'Reserva não relizada.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserved-seat.not_found',
      header: 'Guichê Pass',
      title: 'Reserva não encontrada.',
      msg: 'Reserva não encontrada.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserved-seat.point_of_sale',
      header: 'Guichê Pass',
      title: 'Ponto de venda não encontrado.',
      msg: 'Ponto de venda não encontrado.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserved-seat.ticket_status',
      header: 'Guichê Pass',
      title: 'Reserva vendida.',
      msg: 'Reserva vendida.',
      icon: 'popup-icon-info',
  },

  {
      key: 'seat.not_found',
      header: 'Guichê Pass',
      title: 'Poltrona não encontrada.',
      msg: 'Poltrona não encontrada.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.payment_incorrect',
      header: 'Guichê Pass',
      title: 'Pagamento incorreto.',
      msg: 'Pagamento incorreto.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.total_ticket_incorrect',
      header: 'Guichê Pass',
      title: 'Valor total do bilhete incorreto.',
      msg: 'Valor total do bilhete incorreto.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.same_passenger_in_many_seats',
      header: 'Guichê Pass',
      title: 'Passageiro já existente.',
      msg: 'Documento já está sendo utilizado nesta viagem.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.value_invalid',
      header: 'Guichê Pass',
      title: 'Valor inválido.',
      msg: 'Valor inválido.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.mandatory_nominal_sale',
      header: 'Guichê Pass',
      title: 'Obrigátório o preenchimento dos dados do passageiro.',
      msg: 'Obrigátório o preenchimento dos dados do passageiro.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.reserve_expire',
      header: 'Guichê Pass',
      title: 'Reserva da poltrona expirou.',
      msg: 'Reserva da poltrona expirou.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.tp_ticket_invalid',
      header: 'Guichê Pass',
      title: 'Tipo de bilhete inválido..',
      msg: 'Tipo de bilhete inválido..',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.quota_unavailable',
      header: 'Guichê Pass',
      title: 'Cota indisponível.',
      msg: 'Cota indisponível.',
      icon: 'popup-icon-info',
  },

  {
      key: 'reserve.seat_already_has_children',
      header: 'Guichê Pass',
      title: 'Esta poltrona já possui uma criança de colo cadastrada.',
      msg: 'Esta poltrona já possui uma criança de colo cadastrada.',
      icon: 'popup-icon-info',
  },

  {
      key: 'ticket.not_found',
      header: 'Guichê Pass',
      title: 'Bilhete não encontrado.',
      msg: 'Bilhete não encontrado.',
      icon: 'popup-icon-info',
  },

  {
      key: 'service-travel-leg-stop.not_found',
      header: 'Guichê Pass',
      title: 'Itinerário não encontrado.',
      msg: 'Itinerário não encontrado.',
      icon: 'popup-icon-info',
  },

  {
      key: 'leg-stop-origin.not_found',
      header: 'Guichê Pass',
      title: 'Origem não encontrada.',
      msg: 'Origem não encontrada.',
      icon: 'popup-icon-info',
  },

  {
      key: 'leg-stop-destination.not_found',
      header: 'Guichê Pass',
      title: 'Destino não encontrado.',
      msg: 'Destino não encontrado.',
      icon: 'popup-icon-info',
  },

  {
      key: 'service-travel-leg-stop.sequence',
      header: 'Guichê Pass',
      title: 'Sequência da origem ou destino incorreta.',
      msg: 'Sequência da origem ou destino incorreta.',
      icon: 'popup-icon-info',
  },

  {
      key: 'service-travel.departure_date',
      header: 'Guichê Pass',
      title: 'Informe a data de partida.',
      msg: 'Informe a data de partida.',
      icon: 'popup-icon-info',
  },

  {
      key: 'service-travel.ts_starts',
      header: 'Guichê Pass',
      title: 'Serviço fora do período de vendas.',
      msg: 'Serviço fora do período de vendas.',
      icon: 'popup-icon-info',
  },

  {
      key: 'service-travel.ts_ends',
      header: 'Guichê Pass',
      title: 'Serviço fora do período de vendas.',
      msg: 'Serviço fora do período de vendas.',
      icon: 'popup-icon-info',
  },

  {
      key: 'service-travel.limite_days_invalide',
      header: 'Guichê Pass',
      title: 'Limite de vendas excedido.',
      msg: 'Limite de vendas excedido.',
      icon: 'popup-icon-info',
  },

  {
      key: 'service-travel.week_operation_invalide',
      header: 'Guichê Pass',
      title: 'Serviço não roda no dia da semana selecionado.',
      msg: 'Serviço não roda no dia da semana selecionado.',
      icon: 'popup-icon-info',
  },

  {
      key: 'service-travel-leg-stop.itinerary',
      header: 'Guichê Pass',
      title: 'Não foi possível buscar o itinerário.',
      msg: 'Não foi possível buscar o itinerário.',
      icon: 'popup-icon-info',
  },

  {
      key: 'quick-ticket.service_travel.not_found',
      header: 'Guichê Pass',
      title: 'Viagem não encontrada',
      msg: 'Viagem não encontrada',
      icon: 'popup-icon-info',
  },

  {
      key: 'quick-ticket.ticket_type.not_found',
      header: 'Guichê Pass',
      title: 'Bilhete não encontrado',
      msg: 'Bilhete não encontrado',
  },

  {
      key: 'reserve.same_passenger_in_many_seats_in_current_list',
      header: 'Guichê Pass',
      title: 'Documento do passageiro está repetido na lista de reserva selecionada',
      msg: 'Documento do passageiro está repetido na lista de reserva selecionada',
  },

  {
      key: 'reserved-seat.reserve-failed-in-quickticket',
      header: 'Guichê Pass',
      title: 'Poltrona não reservada no quickticket',
      msg: 'Poltrona não reservada no quickticket',
  },

  {
      key: 'reserved-seat.cancel-reserve-failed-in-quickticket',
      header: 'Guichê Pass',
      title: 'Cancelamento da reserva falhou no quickticket',
      msg: 'Cancelamento da reserva falhou no quickticket',
  },

  {
      key: 'ticket.cancel-ticket-failed-in-quickticket',
      header: 'Guichê Pass',
      title: 'Cancelamento do bilhete falhou no quickticket',
      msg: 'Cancelamento do bilhete falhou no quickticket',
  },

  {
      key: 'quick-ticket.check-grant.failed',
      header: 'Guichê Pass',
      title: 'Reserva de poltrona não realizada, tente novamente ou reserve outra poltrona',
      msg: 'Reserva de poltrona não realizada, tente novamente ou reserve outra poltrona',
  },

  {
      key: 'userBalance.not_opened',
      header: 'Guichê Pass',
      title: 'Nenhum caixa de operador fechado',
      msg: 'Você precisa fechar ao menos um caixa de operador',
  },

  {
      key: 'reserved-seat.standing-sale-exceeded',
      header: 'Guichê Pass',
      title: 'A cota de venda em pé foi excedida',
      msg: 'A cota de venda em pé foi excedida',
  },

  {
      key: 'ticket.failed',
      header: 'Guichê Pass',
      title: 'Essa venda já foi realizada',
      msg: 'Essa venda já foi realizada',
  },

  {
      key: 'price_base.rule_order_incorrect',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'O preço mínimo deve ser menor que o preço praticado, e este deve ser menor que o preço base',
  },

  {
      key: 'price_base.past_date',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'A data de vigência deve ser maior que hoje. Também não é possível alterá-la',
  },

  {
      key: 'price_base.same_date',
      header: 'ATENÇÃO',
      title: 'Nova tarifa não pode ser adicionada',
      msg: 'Já existe uma tarifa com a mesma data de vigência.',
  },

  {
      key: 'trip_cost.past_date',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'A data de vigência deve ser maior que hoje. Também não é possível alterá-la',
  },

  {
      key: 'user.existent_username',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Login já existente',
  },

  {
      key: 'user.not_found',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Existe outra sessão ativa para este usuário',
  },

  {
      key: 'user.inactive',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Usuário desativado',
  },

  {
      key: 'reserve.document_type_incorrect',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Tipo de documento incorreto',
  },

  {
      key: 'ticket_number.range_in_use"',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Faixa de bilhete em uso',
  },

  {
      key: 'quick-ticket.grant_quota_error',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Limite de cota excedido',
  },

  {
      key: 'sales-unit-balance-check.balance_opened',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'O caixa solicitado encontra-se aberto',
  },

  {
      key: 'userBalance.opened',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Os caixas dos operadores devem estar fechados',
  },

  {
      key: 'ticket-fiscal.number_fiscal_in_use',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Número fiscal em uso',
  },

  {
      key: 'privilege.action_manual_ticket_typing',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Você não possui permissão',
  },

  {
      key: 'ticket-fiscal.number_fiscal_in_use',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Número do bilhete em uso',
  },

  {
      key: 'createQuot.duplicate.registration',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Essa cota já foi cadastrada',
  },

  {
      key: 'createQuot.parameter.ticketId_duplicate',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Foram inseridas modalidades de cotas iguais',
  },

  {
      key: 'createQuot.parameter.tpSeatId_null',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Por favor, informe o tipo da poltrona',
  },

  {
      key: 'createQuot.parameter.regulatoryId_null',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Por favor, informe o orgão regulador',
  },

  {
      key: 'createQuot.parameter.ticketTypeId_null',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Por favor, informe a modalidade de cota',
  },

  {
      key: 'createQuot.parameter.quotPerTravel_null',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Por favor, informe a quantidade de cota',
  },

  {
      key: 'stock.existing',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'O estoque informado já está cadastrado',
  },

  {
      key: 'ticket_booking.range_in_use',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'A faixa de bilhetes já está sendo utilizada',
  },

  {
      key: 'createQuot.parameter.quotPerTravel_invalid',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'A quantidade deve ser maior que zero',
  },

  {
      key: 'createQuot.configuration_already_exists',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Já existe cota cadastrada para essa poltrona',
  },

  {
      key: 'serviceTravel.travel_already_closed',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Essa viagem já foi fechada',
  },

  {
      key: 'user-balance-service.balance-diff-zero',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'O saldo em dinheiro deve estar zerado para fechar o caixa',
  },

  {
      key: 'user-balance-service.user-balance-closed',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Não foi possível realizar a movimentação pois o caixa está fechado',
  },

  {
      key: 'authentication-service.login-user-disabled',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Usuário desativado',
  },

  {
      key: 'add-promotion-parameter.dt_trip_started_invalid',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'A data de início da viagem deve ser uma data futura',
  },

  {
      key: 'add-promotion-parameter.qt_seats_null',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Por favor, informe a quantidade de poltronas',
  },

  {
      key: 'promotion_resource.disabled',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Não é possível atualizar um desconto desabilitado',
  },

  {
      key: 'extend-promotion-promotion_dt_trip_ended_invalided',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Não é possível alterar a data do desconto para uma menor que a atual',
  },

  {
      key: 'service-travel-paralyze.sales-unit.not_informed',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Por favor, selecione pelo menos uma agência',
  },

  {
      key: 'service-travel-paralyze-registration.already.exists',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Essa paralisação já foi cadastrada',
  },
  {
      key: 'reserved-promotion-seat.reserves_validate_promotion_seat',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Limite de promoções excedidos para este serviço',
  },
  {
      key: 'board_tax.km_conflict',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Preço já cadastrado para a faixa de km',
  },
  {
      key: 'ticket-category.different_from_informed',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Categoria do bilhete diferente do esperado',
  },
  {
      key: 'bank.slip.register.except',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Erro ao gerar o boleto no fechamento do caixa',
  },
  {
      key: 'constraint_violation_: sisb_bus_line_company_id_code_key',
      header: 'Guichê Pass',
      title: 'Atenção',
      msg: 'Já existe uma linha cadastrada com o prefixo informado',
  },
];
