import { RouteConfig } from 'vue-router'
import { RouterPath } from '@/enums/router-path.enum'


export default (): Array <RouteConfig> => {
    return [
        {
            path: RouterPath.DIGITAL_ACCOUNT_LOGIN,
            component: () => import('./components/pages/page-authentication').then(page => page.default),
        },
    ];
};
