export enum RouterPath {
  ROOT = '/',
  HOME_NOTIFICATION = '/notificacao/:id',
  NOTIFICATION_HISTORY = '/notificacoes/historico',
  HOME_GENERIC_MENU = '/menus/:name',
  USERS = '/usuarios',
  FUNCTIONALITIES = '/funcionalidades',
  ACCOUNT = "/minha-conta",
  ACCOUNT_OPENING = "/guiche-pago/abrir-conta",
  INVOICE_IMS = '/minha-conta/ims',
  INVOICE_IMS_DETAIL = '/minha-conta/ims/detalhes',
  INVOICE_MARKETPLACE = '/minha-conta/marketplace',
  INVOICE_MARKETPLACE_DETAIL = '/minha-conta/marketplace/detalhes',
  ACCOUNT_EXTRACT = '/minha-conta/extrato',
  ACCOUNT_TRANSFER = '/minha-conta/transferencia',
  CHANGE_PASSWORD = '/alterar-senha',
  SWITCH_WORKSPACE = '/switch-workspace',
  ROADMAP = '/roadmap',
  ROADMAP_HISTORY = '/roadmap/historico',
  PERMISSIONS = '/permissoes',
  DASHBOARD = '/relatorio',
  DASHBOARD_DETAILS = '/relatorio-detalhes',
  PERMISSIONS_CREATE = '/permissoes/criar',
  LGPD = '/lgpd',
  DIGITAL_ACCOUNT_LOGIN = '/conta-digital/autenticacao',
  TRIP_CLOSING = '/fechamento-viagem',
  PAF_BPE_IDENTIFICATION = '/identificacao-paf',
  PAF_BPE_REGISTERS = '/registros-paf',
  CHECK_TICKETS = '/consultar-passagens'
}
