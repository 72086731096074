import { BaseResource } from './base-resource';
import { CompanyResource } from './company-resource';
import { WorkingDaysResource } from './working-days-resource';

export class SalesUnitResource extends BaseResource<number> {
    constructor() {
        super();
        this.companies = [];
        this.owned = true;
        this.bankSlipDays = new WorkingDaysResource();
        this.closedSalesUnitBalanceAutomatic = false;
        this.checkSalesUnitBalanceToClosed = false;
    }
    value!: string;
    code!: string;
    description!: string;
    name!: string;
    cnpj!: string;
    adress!: string;
    adressNumber!: string;
    adressComplement!: string;
    district!: string;
    cep!: string;
    city!: string;
    state!: string;
    phone!: string;
    email!: string;
    owned: boolean;
    legStopId!: number;
    nameLegStop!: string;
    removedAt!: string;
    companies: Array<CompanyResource>;
    bankSlipDays: WorkingDaysResource;
    closedSalesUnitBalanceAutomatic: boolean;
    checkSalesUnitBalanceToClosed: boolean;
    bankSlipIsActive!: boolean;
}
