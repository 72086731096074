import { RouterPath } from '@/enums/router-path.enum';
import { MultiCompanyService } from '@/modules/module/services/multi-company/multi-company.service';
import { Vue, Component } from 'vue-property-decorator';


@Component({
    template: '<div>asd</div>'
})
export class PageSwitchWorkspaceComponent extends Vue {

    created() {
        const companyId = this.$route.query.key as string;
        if (companyId) {
            MultiCompanyService.changeCompany(companyId);
        }
        this.$router.push(RouterPath.ROOT);
        this.$router.go(0);
    }
}
