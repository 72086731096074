import Vue from 'vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './sass/main.scss';
import '@/modules/module/util/vee-validate';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vClickOutside from 'v-click-outside';
import Toasted from 'vue-toasted';
import VueTheMask from 'vue-the-mask';
import { AppComponent } from './app';
import { AuthenticationService } from './modules/module-authentication/authentication.service';
import Filters from './app.filters';
import '@/modules/directives/vue-amplitude.directive';
import { AmplitudeService } from './modules/module/services/amplitude/amplitude.service';
import { MultiCompanyService } from './modules/module/services/multi-company/multi-company.service';
import VuejsDialog from 'vuejs-dialog';

import 'moment/locale/pt-br.js';
import '@/modules/module/validators/cpf.validator';
import '@/modules/module/validators/cnpj.validator';
import { COMPONENTS_GLOBAL_CONSTANTS } from './modules/module/constants/components-global.constants';
import VueEllipseProgress from 'vue-ellipse-progress';
import money from 'v-money';
import VueCarousel from 'vue-carousel';
import { UserFactory } from './modules/factory/users/users.factory';

Vue.use(VueEllipseProgress);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VueTheMask as any);
Vue.use(Filters);
Vue.use(vClickOutside);
Vue.use(Toasted);
Vue.use(money, { precision: 4 });
Vue.use(VueCarousel)

Vue.use(VuejsDialog, {
  html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
  loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
  reverse: false, // switch the button positions (left to right, and vise versa)
  okText: 'Confirmar',
  cancelText: 'Cancelar',
  animation: 'zoom', // Available: "zoom", "bounce", "fade"
  type: 'basic', // coming soon: 'soft', 'hard'
  verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
  verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
  clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
});

Vue.config.productionTip = false;

COMPONENTS_GLOBAL_CONSTANTS.forEach(componentConfig => {
  Vue.component(componentConfig.alias, componentConfig.component as any);
});

AuthenticationService
  .login(true)
  .then(async (auth: boolean) => {
    if (auth) {
      const companies = await MultiCompanyService.userCompanies();
      const me = await UserFactory.getUserById(AuthenticationService.user.id);

      AmplitudeService.initAmplitude(me, MultiCompanyService.defaultCompany(companies));

      new Vue({
        router,
        store,
        render: h => h(AppComponent, { props: { keycloak: AuthenticationService.keycloak } })
      }).$mount('#app');
    } else {
      window.location.reload();
    }
  });
