import { AuthEmailFactory } from '@/modules/factory/auth-email/auth-email.factory';
import { MeFactory } from '@/modules/factory/profile/me.factory';
import { AuthenticationService } from '@/modules/module-authentication/authentication.service';
import { MeModel } from '@/modules/module/model/client/me-model';
import { TokenCodeResponse } from '@/modules/module/model/client/token-code-response';
import { UserAuth } from '@/modules/module/model/client/user-auth';
import { AmplitudeService } from '@/modules/module/services/amplitude/amplitude.service';
import { DateUtil } from '@/modules/module/util/date/date.util';
import { NotificationUtil } from '@/modules/module/util/notification.util';
import { Component, Vue } from 'vue-property-decorator';

interface UI {
    profile: MeModel | null;
    step: number;
    email: boolean;
    model: {
      email: string;
      password: string;
      newEmail: string;
      confirmEmail: string;
    };
    canceled: boolean;

    firstField: string;
    secondField: string;
    thirdField: string;
    fourthField: string;
    fifthField: string;
    sixthField: string;

    firstFieldSelected: boolean;
    secondFieldSelected: boolean;
    thirdFieldSelected: boolean;
    fourthFieldSelected: boolean;
    fifthFieldSelected: boolean;
    sixthFieldSelected: boolean;
    counter: number;
    reSend: boolean;

    tokenResponse: TokenCodeResponse | null;
    token: string;
    user?: UserAuth;
}

@Component({
    template: require('./bs-modal-email.html'),
})

export class BSModalEmailComponent extends Vue {

  ui: UI = {
    profile: null,
    step: 1,
    email: true,
    model: {
      email: '',
      password: '',
      newEmail: '',
      confirmEmail: '',
    },
    canceled: false,

    firstField: '',
    secondField: '',
    thirdField: '',
    fourthField: '',
    fifthField: '',
    sixthField: '',
    firstFieldSelected: false,
    secondFieldSelected: false,
    thirdFieldSelected: false,
    fourthFieldSelected: false,
    fifthFieldSelected: false,
    sixthFieldSelected: false,
    counter: 0,
    reSend: false,
    tokenResponse: null,
    token: '',
    user: undefined,
  };

  showModal = false;
  loadingSendEmail = false;
  loadingConfirmToken = false;

  created() {
    this.ui.user = AuthenticationService.user;
  }

  async mounted() {
    try {

      if (!this.hasSefazGroup) {
        this.ui.profile = await MeFactory.me();
        this.validateRememberLater();
      }

    } catch (error) {
      NotificationUtil.warning(error as string);
    }

  }

  isSefazPermission() {
    if (this.ui.user) {
      const found = this.ui.user.groups?.find(g => g === "SEFAZ");
      return !!found;
    }
    return false;
  }

  get hasSefazGroup() {
    return this.isSefazPermission();
  }

  get maskEmail() {
    if (this.ui.profile?.email) {
        const email = this.ui.email ? this.ui.profile.email : this.ui.model.newEmail;

        const maskid = email.replace(/^(.)(.*)(.@.*)$/,
                (_, a, b, c) => a + b.replace(/./g, '*') + c);
        return maskid;
    }
  }

  get profileResourceEmail() {
      if (this.ui.profile) {
          return this.ui.profile.email;
      }
      return '-';
  }

  showEmailValidationModal() {
      if (this.ui.profile && this.ui.profile.showEmailValidationModal) {
          return this.showModal = true;
      }
      return this.showModal = false;
  }

  hide() {
      this.showModal = false;
  }

  get step() {
      return this.ui.step;
  }

  increaseStep() {
      this.ui.step++;
  }

  decreaseStep() {
      this.ui.step--;
  }

  updateEmail() {
      if (this.ui.email === false) {
          this.increaseStep();
      }
  }

  changeInput() {
      if (this.ui.firstField !== '') { this.ui.firstFieldSelected = true; }
      if (this.ui.secondField !== '') {this.ui.secondFieldSelected = true; }
      if (this.ui.thirdField !== '') {this.ui.thirdFieldSelected = true; }
      if (this.ui.fourthField !== '') {this.ui.fourthFieldSelected = true; }
      if (this.ui.fifthField !== '') {this.ui.fifthFieldSelected = true; }
      if (this.ui.sixthField !== '') {this.ui.sixthFieldSelected = true; }
      if (this.ui.firstFieldSelected) {
          (this.$refs['two'] as HTMLElement).focus();
      }
      if (this.ui.secondFieldSelected) {
          (this.$refs['three'] as HTMLElement).focus();
      }
      if (this.ui.thirdFieldSelected) {
          (this.$refs['four'] as HTMLElement).focus();
      }
      if (this.ui.fourthFieldSelected) {
          (this.$refs['five'] as HTMLElement).focus();
      }
      if (this.ui.fifthFieldSelected) {
          (this.$refs['six'] as HTMLElement).focus();
      }
  }

  async verifyCode() {
      try {
        this.loadingConfirmToken = true;
        if (this.ui.firstField !== '' && this.ui.secondField !== '' && this.ui.thirdField !== '' && this.ui.fourthField !== '' &&
        this.ui.fifthField !== '' && this.ui.sixthField !== '' && (this.ui.counter > 0)) {
          const code = this.ui.firstField + this.ui.secondField + this.ui.thirdField + this.ui.fourthField + this.ui.fifthField + this.ui.sixthField;

          await AuthEmailFactory.verifyEmailToken(this.ui.model.newEmail, code.toString());
          this.increaseStep();
          this.ui.counter = 0;
          this.clearInputs();

          AmplitudeService.logEvent('click_confirm_token', { product: 'suite'});
        } else {
            NotificationUtil.warning('Preencha os campos com o código recebido');
        }
      } catch (error) {
        let message = error;
        if (error && error.errorResponse && error.errorResponse.code === 'user.verify-token-code.incorrect-token') {
          message = error.errorResponse.properties[1].value;
        }
        NotificationUtil.warning(message);
        AmplitudeService.logEvent('click_fail_token', { description: message, product: 'suite' });
        this.ui.step = 1;
        this.ui.counter = 0;
        this.resetFields();
        this.clearInputs();
      } finally {
        this.loadingConfirmToken = false;
      }
    }

    clearInputs() {
      this.ui.firstField = '';
      this.ui.secondField = '';
      this.ui.thirdField = '';
      this.ui.fourthField = '';
      this.ui.fifthField = '';
      this.ui.sixthField = '';
      this.ui.firstFieldSelected = false;
      this.ui.secondFieldSelected = false;
      this.ui.thirdFieldSelected = false;
      this.ui.fourthFieldSelected = false;
      this.ui.fifthFieldSelected = false;
      this.ui.sixthFieldSelected = false;
      if (this.$refs['one'] as HTMLElement) {
          (this.$refs['one'] as HTMLElement).focus();
      }
  }

  async reSendCode() {
      try {
          this.clearInputs();
          if (this.ui.email === false ) {
            await AuthEmailFactory.sendEmailVerification(this.ui.model.newEmail);
          } else {
            // envia por email o codigo de verificação
            await AuthEmailFactory.sendEmailVerification();
          }
          this.ui.reSend = true;
          this.ui.counter = 0;
          this.startCountdown(60);
          AmplitudeService.logEvent('click_resend_token', { product: 'suite'});
          NotificationUtil.success('Código enviado, verifique sua caixa de e-mail.');
      } catch (error) {
          NotificationUtil.warning(error as string);
      }
  }

  get accessTokenCode() {
      return this.ui.token;
  }

  startCountdown(seconds: number) {
      this.ui.counter = seconds;
      const interval = setInterval(() => {
          this.ui.counter--;
          if (this.ui.counter < 0 ) {
              clearInterval(interval);
              this.ui.counter = 0;
          }
      }, 1000);
  }

  countFormatted() {
      const date = new Date(0);
      date.setSeconds(this.ui.counter);
      const timeString = date.toISOString().substring(14, 19);
      this.verifyCounter();
      return `${timeString}`;
  }

  textMessageStatus() {
      if (this.ui.reSend) {
          return 'Reenviaremos em instantes um novo código de verificação via e-mail.';
      }
      return 'Enviaremos em instantes um código de verificação via e-mail';
  }

  verifyCounter() {
      if (this.ui.counter === 0) {
          this.clearInputs();
          NotificationUtil.warning('Código não é mais válido, reenvie o código.');
      }
  }

  isReSend() {
      if (this.ui.counter === 0) {
          return true;
      }
      return false;
  }

  async toVerifyToken() {
    if (this.ui.email === false ) {
        AmplitudeService.logEvent('click_proceed_step_1', { description: 'atualizar e-mail', product: 'suite'});
        this.increaseStep();
    } else {
      try {
        this.loadingSendEmail = true;
        AmplitudeService.logEvent('click_proceed_step_1', { description: 'e-mail correto', product: 'suite'});
        AmplitudeService.logEvent('click_send_token', { product: 'suite'});
        // envia por email o codigo de verificação
        await AuthEmailFactory.sendEmailVerification();
        this.increaseStep();
        this.startCountdown(60);

      } catch (error) {
        NotificationUtil.warning(error as string);
        AmplitudeService.logEvent('error_send_email', { product: 'suite'});
      } finally {
        this.loadingSendEmail = false;
      }
    }
  }


  async goToSendCode() {
    try {
      this.loadingSendEmail = true;
      if (this.ui.model.newEmail === '' || this.ui.model.confirmEmail === '' || this.ui.model.email === '' || this.ui.model.password === '') {
        NotificationUtil.warning('Preencha todos os campos');
      } else {

        try {
          if (this.ui.model.newEmail === this.ui.model.email) {
            NotificationUtil.warning('É preciso inserir um novo e-mail para prosseguir');
            return;
          }
          // chamar api de password
          await AuthEmailFactory.verifyPassword(this.ui.model.email, this.ui.model.password);
          AmplitudeService.logEvent('click_verify_password_user', { product: 'suite'});
        } catch (error) {
          let message = error;
          if (error && error.errorResponse && error.errorResponse.code === 'user.password_invalid') {
              message = 'Senha ou usuário inválido';
          }
          if (error && error.errorResponse && error.errorResponse.code === 'user.email_invalid') {
            message = error.errorResponse.properties[1].value;
          }
          NotificationUtil.warning(message);
          AmplitudeService.logEvent('error_verify_password', { product: 'suite'});
          return;
        }

        //api de enviar code por email
        await AuthEmailFactory.sendEmailVerification(this.ui.model.newEmail);
        this.startCountdown(60);
        this.increaseStep();
        AmplitudeService.logEvent('click_send_token', { product: 'suite'});
      }
    } catch (error) {
      let message = error;
      if (error && error.errorResponse && error.errorResponse.code === 'user.email-already-taken') {
        message = 'O e-mail escolhido já está sendo utilizado.';
        NotificationUtil.warning(message);
      } else {
        NotificationUtil.warning(error as string);
      }
      AmplitudeService.logEvent('error_send_email', { product: 'suite'});
    } finally {
      this.loadingSendEmail = false;
    }
  }

  closeModal() {
      this.showModal = false;
      this.clearInputs();
      this.resetFields();
      this.ui.step = 1;
      this.ui.counter = 0;
      this.ui.canceled = false;
      AmplitudeService.logEvent('click_verified_email_success', { product: 'suite'});
  }

  closeModalAfterCancel() {
    this.showModal = false;
    this.clearInputs();
    this.resetFields();
    this.ui.step = 1;
    this.ui.counter = 0;
    this.ui.canceled = false;
    AmplitudeService.logEvent('click_close_modal', { product: 'admin'});
}

  resetFields() {
      this.ui.model.email = '';
      this.ui.model.newEmail = '';
      this.ui.model.confirmEmail = '';
      this.ui.model.password = '';
  }

  cancelUpdate() {
      this.ui.canceled = true;
      AmplitudeService.logEvent('click_canceled_steps', { product: 'suite'});
  }

  get hasCanceled() {
    return this.ui.canceled;
  }

  reset() {
      this.decreaseStep();
      this.clearInputs();
      this.resetFields();
      this.ui.counter = 0;
      this.ui.canceled = false;
  }

  async rememberLater() {
      this.hide();
      await MeFactory.remeberEmailValidation();
      const date = new Date().toISOString();
      localStorage.setItem('SAW_MODAL_EMAIL', date);
      AmplitudeService.logEvent('click_rememberLater', { product: 'suite'});
  }

  validateRememberLater() {
      const modalDate = localStorage.getItem('SAW_MODAL_EMAIL');
      if (modalDate && DateUtil.calcDiff(modalDate, new Date(), 'hours') < 2) {
          return;
      }
      setTimeout(() => {
          this.showEmailValidationModal();
      }, 1500);
  }

}
