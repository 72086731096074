class Util {

  public chain(list: Array<any>, chain: number): Array<Array<any>> {
      const retorno = [];
      let sub: any = [];
      list.forEach((item, index) => {
          sub.push(item);
          if ((index + 1) % chain === 0) {
              retorno.push(sub);
              sub = [];
          }
      });
      if (sub.length) {
          retorno.push(sub);
          sub = [];
      }
      return retorno;
  }

}

export const ArrayUtil = new Util();
