import { Vue } from 'vue-property-decorator';

export enum AppBroadcastEvent {
    EXIBIR_LOADER = 'EXIBIR_LOADER',
    ESCONDER_LOADER = 'ESCONDER_LOADER',
    AUTENTICADO = 'AUTENTICADO',
    DESAUTENTICADO = 'DESAUTENTICADO',
    EXIBIR_TASKBAR = 'EXIBIR_TASKBAR',
    ESCONDER_TASKBAR = 'ESCONDER_TASKBAR',
    EXIBIR_SIDEBAR = 'EXIBIR_SIDEBAR',
    ESCONDER_SIDEBAR = 'ESCONDER_SIDEBAR',
    CARREGAR_USER_DEFAULTS = 'CARREGAR_USER_DEFAULTS',
    TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
    EXIBIR_CONTACT = 'EXIBIR_CONTACT',
    ESCONDER_CONTACT = 'ESCONDER_CONTACT',
}

export const AppBroadcastEventBus = new Vue();
