export class WorkingDaysResource {
    sun = false;
    mon = false;
    tue = false;
    wed = false;
    thu = false;
    fri = false;
    sat = false;
    holiday = false;
    weeklyOperationAsString!: string;
}
