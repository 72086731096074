import { BSFormBuilderInput } from '../bs-form-builder/bs-form-builder.types';

export interface CardTableColumnConfig {
    moeda?: boolean;
    input?: BSFormBuilderInput;
    title?: string;
    groupedBy?: boolean;
    groupByFn?: Function;
    size?: string;
}

export class BSCardTableColumn {
    constructor(value: (item: any) => string | number | Date, label: () => string | number | Date, config?: CardTableColumnConfig) {
        this.value = value;
        this.label = label;
        this.config = config || {};
    }
    value: (item: any) => string | number | Date;
    label: () => string | number | Date;
    config: CardTableColumnConfig;
}

interface DropdownItem {
    id: any;
    value: string;
    onClick: () => Promise<void> | void;
    visible: boolean;
}

interface BSCardTableMenuEntryInterface {
    method: (item: any, index?: number) => void;
    label: (item: any) => string | number | Date;
    iconClass: (item: any) => Array<string>;
    btnClass: (item: any) => Array<string>;
    disabled?: (item: any) => boolean;
    visible?: (item?: any) => boolean;
    dropdown?: (items: any) => Array<DropdownItem>;
    amplitudeEvent?: string;
}

export class BSCardTableMenuEntry implements BSCardTableMenuEntryInterface {
    constructor(config: BSCardTableMenuEntryInterface) {
        this.method = config.method;
        this.label = config.label;
        this.iconClass = config.iconClass;
        this.btnClass = config.btnClass;
        this.disabled = config.disabled;
        this.visible = config.visible;
        this.amplitudeEvent = config.amplitudeEvent;
        this.dropdown = config.dropdown;
    }
    method: (item: any, index?: number) => void;
    label: (item: any) => string | number | Date;
    iconClass: (item: any) => string[];
    btnClass: (item: any) => string[];
    disabled?: (item: any) => boolean;
    visible?: (item?: any) => boolean;
    dropdown?: (items: any) => Array<DropdownItem>;
    amplitudeEvent?: string;
}

export class BSCardTableMenu {
    main!: Array<BSCardTableMenuEntry>;
    row!: Array<BSCardTableMenuEntry>;
}
