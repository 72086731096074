import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    template: require('./bs-small-card.html'),
})
export class BSSmallCardComponent extends Vue {
    @Prop({ type: String, default: '' })
    title!: string;

    @Prop({ type: String, default: '' })
    content!: string;

    @Prop({ type: String, default: '' })
    msg!: string;

    @Prop({type: Boolean, default: false})
    hasTooltip!: boolean;
}
