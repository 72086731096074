export const DICTIONARY = {
  "alpha": "O campo {_field_} deve conter somente letras",
  "alpha_dash": "O campo {_field_} deve conter letras, números e traços",
  "alpha_num": "O campo {_field_} deve conter somente letras e números",
  "alpha_spaces": "O campo {_field_} só pode conter caracteres alfabéticos e espaços",
  "between": "O campo {_field_} deve estar entre {min} e {max}",
  "confirmed": "A confirmação do campo {_field_} deve ser igual",
  "digits": "O campo {_field_} deve ser numérico e ter exatamente {length} dígitos",
  "dimensions": "O campo {_field_} deve ter {width} pixels de largura por {height} pixels de altura",
  "email": "O campo {_field_} deve ser um email válido",
  "excluded": "O campo {_field_} deve ser um valor válido",
  "ext": "O campo {_field_} deve ser um arquivo válido",
  "image": "O campo {_field_} deve ser uma imagem",
  "integer": "O campo {_field_} deve ser um número inteiro",
  "is": "O valor inserido no campo {_field_} não é válido",
  "oneOf": "O campo {_field_} deve ter um valor válido",
  "length": "O tamanho do campo {_field_} deve ser {length}",
  "max": "O campo {_field_} não deve ter mais que {length} caracteres",
  "max_value": "O campo {_field_} precisa ser {max} ou menor",
  "mimes": "O campo {_field_} deve ser um tipo de arquivo válido",
  "min": "O campo {_field_} deve conter pelo menos {length} caracteres",
  "min_value": "O campo {_field_} precisa ser {min} ou maior",
  "numeric": "O campo {_field_} deve conter apenas números",
  "regex": "O campo {_field_} possui um formato inválido",
  "required": "O campo {_field_} é obrigatório",
  "required_if": "O campo {_field_} é obrigatório",
  "size": "O campo {_field_} deve ser menor que {size}KB"
};

