import { BaseFactory } from '../base.factory';
import { AppInfoModel } from '@/modules/module/model/client/app-info-model';

class Factory extends BaseFactory {

  private title = 'Get App Info';

  constructor() {
    super(process.env.VUE_APP_API_URL);
  }

  public getAppInfo = async (): Promise<AppInfoModel> => {
    const result = await this.get('/app', undefined) as AppInfoModel;
    return result;
  }
}

export const AppInfoFactory = new Factory();
