import { Vue } from 'vue-property-decorator';
import IFilter from './modules/filters';
import CapitalizeFilter from './modules/filters/capitalize';
import FormatCurrencyFilter from './modules/filters/format-currency';
import FormatDateRangeFilter from './modules/filters/format-date-range/format-date-range.filter';
import FormatPercentage from './modules/filters/format-percentage-filter';
import FormatPercentageFilter from './modules/filters/format-percentage';
import StriphtmlFilter from './modules/filters/striphtml';

export default class Filters {
    public static install() {
        const filters: any[] = [
            new CapitalizeFilter(),
            new FormatPercentageFilter(),
            new StriphtmlFilter(),
            new FormatDateRangeFilter(),
            new FormatCurrencyFilter(),
            new FormatPercentage(),
        ];

        filters.forEach((filterService: IFilter) =>
            Vue.filter(filterService.filterName, (...params: any) => filterService.filterAction(...params))
        );
    }
}
