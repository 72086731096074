












































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator';
import { LocationFactory } from '../../../factory/location/location.factory'
import { LocationModel } from '../../../module/model/client/location-model';
import { CompanyLocation } from '../../../module/model/client/company-location-model';
import { NotificationUtil } from '../../../module/util/notification.util';

interface UI {
  showCompanies: boolean;
  cities: LocationModel[];
  selectedCity: string;
  selectedCompanies: CompanyLocation[];
  lists: {
    cities: LocationModel[];
    companies: CompanyLocation[];
  };
  city?: LocationModel;
}

@Component
export default class CompanyModal extends Vue {
  ui: UI = {
    showCompanies: false,
    cities: [{city: 'São Paulo', id: 1}],
    selectedCity: '',
    selectedCompanies: [],
    lists: {
      cities: [],
      companies: [],
    },
    city: undefined,
  }

  async created() {
    this.ui.cities = await LocationFactory.findCities();
  }

  @Watch('ui.selectedCity')
  async onSelectedCityChanged() {
    if(this.ui.selectedCity.length > 2) {
      this.ui.lists.cities = this.ui.cities.filter(city => city.city.toLowerCase().includes(this.ui.selectedCity.toLowerCase()));
      this.ui.selectedCompanies = [];
    } else {
      this.ui.lists.cities = [];
    }
  }

  setCity(city: LocationModel) {
    this.ui.city = city;
  }

  @Watch('ui.city')
  async onCityChanged() {
    try {
      if(!this.ui.city) {
        return;
      }
      this.ui.showCompanies = true;
      this.ui.lists.companies = await LocationFactory.getCompanyByLocation(this.ui.city.id.toString());
    } catch (error) {
      if (!error.errorResponse) return;

      if (error.errorResponse.code === '500' || error.errorResponse.status === 'INTERNAL_SERVER_ERROR') {
        console.log('error ',error);
        NotificationUtil.error('Erro Interno na aplicação');
        return;
      }
    }
  }

  goBack() {
    this.ui.showCompanies = false;
  }

  goNext() {
    this.$emit('input', this.ui.selectedCompanies)
  }

  close() {
    this.$emit('input', this.ui.selectedCompanies)
  }
}
