import { CompanyHeaderModel } from '@/modules/module/model/client/company-model';
import { AmplitudeClient } from 'amplitude-js';
import { Vue } from 'vue-property-decorator';
import { UserModel } from '../../model/client/user-model';

class Service extends Vue {

    constructor() {
        super();

        const amplitude = require('amplitude-js'); // eslint-disable-line @typescript-eslint/no-var-requires
        this.amplitudeProject = amplitude.getInstance();
    }

    private logRouter = false;
    amplitudeProject: AmplitudeClient;

    private userProperties!: {
        user: string;
        email: string;
        cnpj?: string;
        company?: string;
    };

    async initAmplitude(user: UserModel, company?: CompanyHeaderModel) {
        this.amplitudeProject.init(window.CONFIG.AMPLITUDE_API_KEY, user.id, {
            saveEvents: true,
            batchEvents: true,
            includeReferrer: true,
        });

        this.setUserProperty(user, company);
        this.logEvent('login');
    }

    logAmplitudeResetUser() {
        this.amplitudeProject.setUserId(null);
        this.amplitudeProject.regenerateDeviceId();
        this.logEvent('logout');
    }

    logEvent(name: any, properties?: any) {
        if (!name) return;

        try {
            this.amplitudeProject.logEvent(name, properties);
        } catch (error) {
            console.error(`AmplitudeService error ${name}`);
        }
    }

    getError(error: any) {
        return (error.errorResponse && error.errorResponse.code) || error;
    }

    setLogRouter(logRouter: boolean) {
        this.logRouter = logRouter;
    }

    setUserProperty(user: UserModel, company?: CompanyHeaderModel) {
        this.userProperties = {
            user: user.username!,
            email: user.email!,
            cnpj: company?.cnpj,
            company: company?.name,
        };

        this.amplitudeProject.setUserId(user.id!);
        this.amplitudeProject.setUserProperties(this.userProperties);
    }

    get registerRoute() {
        return this.logRouter;
    }
}

export const AmplitudeService = new Service();
