import { RouteConfig } from 'vue-router'
import { RouterPath } from '@/enums/router-path.enum'

export default (): Array < RouteConfig > => {
  return [
    {
      name: RouterPath.ACCOUNT,
      path: RouterPath.ACCOUNT,
      component: () => import('./components/pages/page-account').then(m => m.default)
    },
    {
      name: RouterPath.INVOICE_IMS,
      path: RouterPath.INVOICE_IMS,
      component: () => import('./components/pages/page-account-ims').then(m => m.default)
    },
    {
      name: RouterPath.INVOICE_IMS_DETAIL,
      path: RouterPath.INVOICE_IMS_DETAIL,
      component: () => import('./components/pages/page-account-ims-detail').then(m => m.default)
    },
    {
      name: RouterPath.INVOICE_MARKETPLACE,
      path: RouterPath.INVOICE_MARKETPLACE,
      component: () => import('./components/pages/page-account-marketplace').then(m => m.default)
    },
    {
      name: RouterPath.INVOICE_MARKETPLACE_DETAIL,
      path: RouterPath.INVOICE_MARKETPLACE_DETAIL,
      component: () => import('./components/pages/page-account-marketplace-detail').then(m => m.default)
    },
    {
      name: RouterPath.ACCOUNT_EXTRACT,
      path: RouterPath.ACCOUNT_EXTRACT,
      component: () => import('./components/pages/page-account-extract').then(m => m.default)
    },
    {
      name: RouterPath.ACCOUNT_TRANSFER,
      path: RouterPath.ACCOUNT_TRANSFER,
      component: () => import('./components/pages/page-account-transfer').then(m => m.default)
    },
    {
      path: RouterPath.USERS,
      component: () => import('./components/pages/page-account-users/page-account-users').then(m => m.default)
    },
    {
      name: RouterPath.CHECK_TICKETS,
      path: RouterPath.CHECK_TICKETS,
      component: () => import('./components/pages/page-check-tickets/page-check-tickets').then(m => m.default)
    }
  ]
}
