import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BSLoadingComponent } from '../bs-loading/bs-loading';
import { BSDropDownItem } from '../bs-dropdown/bs-dropdown';
import { BSFormBuilderUtil } from '../bs-form-builder/bs-form-builder.util';
import { BSFormBuilderInputText } from '../bs-form-builder/bs-form-builder.types';
import { BSCardTableColumn, BSCardTableMenu, BSCardTableMenuEntry } from './bs-card-table.types';


interface UI {
    itens?: Array<any>;
    showDropdown: boolean;
    checkAll: boolean;
    activeExpanded: number;
    dropdownMenuRow: boolean;
    dropdownMenuRowActive: number;
}

@Component({
    template: require('./bs-card-table.html'),
    components: {
      'bs-loading': BSLoadingComponent
    }
})
export class BSCardTableComponent extends Vue {

    @Prop()
    menu!: BSCardTableMenu;

    @Prop()
    columns!: Array<BSCardTableColumn>;

    @Prop({ type: Array, default: () => [] })
    itens!: Array<any>;

    @Prop()
    noCard!: boolean;

    @Prop({ type: Boolean, default: false })
    selectRow!: boolean;

    @Prop({ type: String, default: null })
    title!: string;

    @Prop({ type: Number, default: -1 })
    activeExp!: number;

    @Prop({  type: Boolean, default: false })
    dropdownMenuRow!: boolean;

    @Watch('itens')
    onItensChanged() {
        this.ui.itens = this.itens;
    }

    @Watch('activeExp')
    onExpand() {
        this.ui.activeExpanded = this.activeExp;
    }

    ui: UI = {
        itens: undefined,
        showDropdown: false,
        checkAll: false,
        activeExpanded: -1,
        dropdownMenuRow: false,
        dropdownMenuRowActive: -1,
    };

    util = BSFormBuilderUtil;

    mounted() {
        this.ui.itens = this.itens;
        this.ui.activeExpanded = this.activeExp;
        this.ui.dropdownMenuRow = this.dropdownMenuRow;
    }

    /* COLUMN MANAGEMENT */
    get getColumns() {
        const groupedByCols = this.columns.filter(col => col.config && col.config.groupedBy);

        // Impossibilita o agrupamento por mais de uma coluna
        if (groupedByCols.length > 1) {
            console.error('Impossível agrupar por mais de uma coluna');
            return [];
        }
        return this.columns.filter(col => col.config && !col.config.groupedBy);
    }

    get getGroups() {
        const groupedCol = this.columns.find(col => col.config && col.config.groupedBy);

        if (groupedCol) {
            const groupedByValues = this.ui.itens?.map(item => this.getColumnValue(groupedCol, item).toString());
            return Array.from(new Set(groupedByValues));
        }
        return [''];
    }

    get getGroupByFieldName() {
        return this.getColumnLabel(this.columns.find(col => col.config && col.config.groupedBy));
    }

    get getColumnCount() {
        return this.getColumns.length;
    }

    get hasCardTitle() {
        return !!this.title;
    }

    isValidGroup(group: string) {
        return group && group !== '';
    }

    getColumnLabel(column: BSCardTableColumn | undefined | null) {
        return column?.label();
    }

    getColumnValue(column: BSCardTableColumn, item: any) {
        if (column.config) {
            if (column.config.moeda) {
                return column.value(item);
            }
        }
        return column.value(item);
    }
    /* COLUMN MANAGEMENT - END*/

    getItens(group?: string) {
        if (!group) {
            return this.ui.itens?.map(i => {
                if (i.expandable !== null) {
                    return i;
                }
                return { ...i, expandable: false }
            });
        }

        const groupedCol = this.columns.find(col => col.config && col.config.groupedBy);
        if (groupedCol && groupedCol.config && this.ui.itens) {
          return this.ui.itens.filter(item => groupedCol.config.groupByFn!(item) === group);
        }

        return [];
    }

    get itensNotFound() {
        return !!this.ui.itens && !this.ui.itens.length;
    }

    get isLoading() {
        return !this.ui.itens;
    }

    /* MENU MANAGEMENT */
    getMenuRow(item: any) {
        return (this.menu && this.menu.row || []).filter(menuRow => { return menuRow.visible && item && menuRow.visible(item) || !menuRow.visible; });
    }

    get getMenuRowCount() {
        return this.getMenuRow(true).length;
    }

    get getMenuMain() {
        return (this.menu && this.menu.main || []).filter(menuMain => { return menuMain.visible && menuMain.visible() || !menuMain.visible; });
    }

    getMenuItensAsDropDown(menu: Array<BSCardTableMenuEntry>) {
        if (menu) {
            return menu.map(x => {
                const dropdownItem: BSDropDownItem = {
                    text: `<i class='${x.iconClass(this.getItens()).join(' ')} mr-2'></i>${x.label(this.getItens())}`,
                    item: x.method
                };
                return dropdownItem;
            });
        }
    }

    getMenuItemLabel(menuItem: BSCardTableMenuEntry, item: any) {
        return menuItem.label(item);
    }

    getMenuIconClass(menuItem: BSCardTableMenuEntry, item: any) {
        return menuItem.iconClass(item);
    }

    getMenuButtonClass(menuItem: BSCardTableMenuEntry, item: any) {
        return menuItem.btnClass(item).length ? menuItem.btnClass(item) : 'bs-btn bs-btn-text-only';
    }

    get cardClass() {
        if (this.noCard) {
            return ['bs-card-table'];

        }
        return ['bs-card', 'bs-card-table'];
    }

    isMenuItemDisabled(menu: BSCardTableMenuEntry, item: any) {
        return menu.disabled ? menu.disabled(item) : false;
    }

    isColumnInput(column: BSCardTableColumn) {
        return column.config && column.config.input;
    }

    doCallMenuMethod(menuItem: BSCardTableMenuEntry, item: any) {
        const itemIndex = this.itens.findIndex(arrayItem => arrayItem === item);
        menuItem.method(item, itemIndex);

        // this.logAmplitudeButtonList(menuItem);
    }

    onBlur(item: any, column: BSCardTableColumn) {
        if (!column.config.input) return;
        if (column.config.input instanceof BSFormBuilderInputText) {
            const input = column.config.input as BSFormBuilderInputText;
            if (!input.onBlur) return;
            input.onBlur(item);
        }
    }
    /* MENU MANAGEMENT - END*/

    toggleAllSelect() {
        this.ui.checkAll ? this.selectAll() : this.unselectAll();
    }

    selectAll() {
        this.itens.forEach(item => this.$set(item, 'checked', true));
    }

    unselectAll() {
        this.itens.forEach(item => this.$set(item, 'checked', false));
    }

    get selectedItens() {
        return this.itens
            .filter(item => item.checked)
            .map(item => {
                delete item.checked;
                return item;
            }
            );
    }

    get isDropdownMenuRow() {
        return this.ui.dropdownMenuRow;
    }

    get dropdownActive() {
        return this.ui.dropdownMenuRowActive;
    }

    toggleDropdownMenu(id: number) {
        this.ui.dropdownMenuRowActive = this.ui.dropdownMenuRowActive === id ? -1 : id;
    }
    /********************************************************************
                    AMPLITUDE - EVENTOS
    *********************************************************************/

    // logAmplitudeButtonList(menuItem: GPCardTableMenuEntry) {
    //     try {
    //         if (!menuItem.amplitudeEvent) return;

    //         AmplitudeService.logEvent(menuItem.amplitudeEvent);

    //     } catch (error) {
    //         console.error(`Amplitude event error: ${menuItem.amplitudeEvent}`);
    //     }
    // }
}
