import { RouteConfig } from 'vue-router'
import { RouterPath } from '@/enums/router-path.enum'

export default (): Array < RouteConfig > => {
  return [
    {
      path: RouterPath.ROOT,
      component: () => import('./components/pages/page-home').then(m => m.default)
    },
    {
      path: RouterPath.HOME_GENERIC_MENU,
      component: () => import('./components/pages/page-home-generic-menu').then(m => m.default)
    },
    {
      path: RouterPath.HOME_NOTIFICATION,
      component: () => import('./components/pages/page-home-notification').then(m => m.default)
    },
    {
      path: RouterPath.NOTIFICATION_HISTORY,
      component: () => import('./components/pages/page-notification-history').then(m => m.default)
    },
  ]
}
