import { BaseFactory } from '../base.factory';
import { Station } from '@/modules/module/model/server/station';

export class StationFactory extends BaseFactory {

    constructor() {
      super(process.env.VUE_APP_API_URL);
    }

    public getStations = async () => {
      const path = `/location`;
      const result = await this.get(path) as Array<Station>;
      return result.sort((s1, s2) => { return s1.label.localeCompare(s2.label); });
  }
}
