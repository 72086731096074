export enum AMPLITUDE_GROUPS {
    ACCOUNTING = 'accounting',
    HOME = 'home',
    COMPANY = 'company',
    COMPANY_USERS = 'users_company',
    FUNCTIONALITIES = "funcionalities",
    ACCOUNT = 'account',
    ACCOUNT_OPENING = 'account_opening',
    MARKETPLACE = 'marketplace',
    ACCOUNT_EXTRACT = 'account_extract',
    LGPD = 'lgpd',
}
