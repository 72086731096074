import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { PageUserAccountComponent } from '@/pages/page-user-account/page-user-account'
import { RouterPath } from '@/enums/router-path.enum'
import { functionalitiesModule } from '@/modules/module-functionalities'
import { accountModule } from '@/modules/module-account'
import { PageSwitchWorkspaceComponent } from '@/pages/page-switch-workspace'
import { accountOpeningModule } from '@/modules/module-account-opening'
import { homeModule } from '@/modules/module-home'
import { marketplaceModule } from '@/modules/module-marketplace'
import { roadMapModule } from '@/modules/module-roadmap'
import { PageLgpdComponent } from '@/pages/page-lgpd/page-lgpd'
import { digitalAccountModule } from '@/modules/module-digital-account'
import { sefazModule } from '@/modules/module-sefaz'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: RouterPath.CHANGE_PASSWORD,
    name: 'Alterar senha',
    component: PageUserAccountComponent
  },
  {
    path: RouterPath.SWITCH_WORKSPACE,
    name: 'Mudar de workspace',
    component: PageSwitchWorkspaceComponent
  },
  {
    path: RouterPath.LGPD,
    name: 'LGPD',
    component: PageLgpdComponent
  },
  ...functionalitiesModule.routes(),
  ...accountModule.routes(),
  ...accountOpeningModule.routes(),
  ...homeModule.routes(),
  ...marketplaceModule.routes(),
  ...roadMapModule.routes(),
  ...digitalAccountModule.routes(),
  ...sefazModule.routes(),
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'smi-active',
  linkExactActiveClass: 'smi-active',
})

export default router
