import { RouteConfig } from 'vue-router'
import { RouterPath } from '@/enums/router-path.enum'


export default (): Array <RouteConfig> => {
    return [
        {
            path: RouterPath.TRIP_CLOSING,
            component: () => import('./components/pages/page-trip-closing').then(page => page.default),
        },
        {
          path: RouterPath.PAF_BPE_IDENTIFICATION,
          component: () => import('./components/pages/page-paf-identification').then(page => page.default),
        },
        {
          path: RouterPath.PAF_BPE_REGISTERS,
          component: () => import('./components/pages/page-paf-registers').then(page => page.default),
        },
    ];
};
