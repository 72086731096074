export interface PercentageFormatInterface {
    decimal: string;
}

export class Util {
    format = {
        decimal: ','
    };

    public formatPercentage = (value: number | string = 0) => {
        return `${value}%`.replace('.', this.format.decimal);
    }
}

export const PercentageUtil = new Util();