import { AuthenticationService } from "@/modules/module-authentication/authentication.service";
import { AppInfoFactory } from "@/modules/factory/app-info/app-info.factory";
import { NotificationUtil } from "@/modules/module/util/notification.util";
import { ProfileResource } from "../../model/server/profile-resource";
import { AppBroadcastEvent, AppBroadcastEventBus } from "@/app.broadcast-event-bus";
import { VUE_APP_SITE_SUITE_URL } from "../../constants/environment.constants";

interface Data {
    profileResource: ProfileResource;
    urlMarketplace: string;
    urlGpassAdmin: string;
    urlSuite: string;
    hasFitBankAccount: boolean;
}

class Service {
    private data: Data = {
        profileResource: new ProfileResource(),
        urlMarketplace: '' ,
        urlGpassAdmin: '',
        urlSuite: '',
        hasFitBankAccount: false
    };

    private loaded = false;

    setProfileResource(profileResource: ProfileResource) {
      this.data.profileResource = profileResource;
    }

    clearProfileResource() {
        this.data.profileResource = new ProfileResource();
    }

    setUrlMarketplace(urlMarketplace: string) {
        this.data.urlMarketplace = urlMarketplace;
    }

    clearUrlMarketplace() {
        this.data.urlMarketplace = '';
    }

    get profileResource() {
      return this.data.profileResource;
    }

    get urlMarketplace() {
        return this.data.urlMarketplace;
    }

    setUrlGpassAdmin(urlGpassAdmin: string) {
      this.data.urlGpassAdmin = urlGpassAdmin;
    }

    clearUrlGpassAdmin() {
        this.data.urlGpassAdmin = '';
    }

    get urlGpassAdmin() {
        return this.data.urlGpassAdmin;
    }

    setUrlSuite(urlSuite: string) {
        this.data.urlSuite = urlSuite;
    }

    clearUrlSuite() {
        this.data.urlSuite = '';
    }

    get urlSuite() {
        return this.data.urlSuite;
    }

    hasFitBankAccount() {
      return this.data.hasFitBankAccount;
    }

    sethasFitBankAccount(hasFitBankAccount: boolean) {
      this.data.hasFitBankAccount = hasFitBankAccount;
    }

    clearhasFitBankAccount() {
      this.data.hasFitBankAccount = false;
    }

    public async loadAppInfo() {
      // Carrega o perfil do usuário caso não esteja previamente carregado
      if (AuthenticationService.isAuthenticated && !this.loaded) {
          try {

            const user = AuthenticationService.user;
            const isSefazPermission = user.groups.find(g => g === "SEFAZ");

            if (!isSefazPermission) {
              this.initAppBroadcastEvent();
              const appInfo = await AppInfoFactory.getAppInfo();
              // const profileResource = await ProfileFactory.me();
              // this.setProfileResource(profileResource);
              this.setUrlMarketplace(appInfo.marketplaceUrl);
              this.setUrlGpassAdmin(appInfo.gpassAdminUrl);
              this.sethasFitBankAccount(appInfo.hasFitBankAccount);
              this.loaded = true;
            }

          } catch (e) {
            if (e) {
              console.error(e);
              window.location.href = VUE_APP_SITE_SUITE_URL;
              if (e.message) {
                NotificationUtil.error(e.message);
              } else {
                NotificationUtil.error(e);
              }
            }
            this.loaded = false;
            window.location.href = VUE_APP_SITE_SUITE_URL;
            setTimeout(() => {
              AuthenticationService.logout();
            }, 2000);
          }
      }
    }

    initAppBroadcastEvent() {
      AppBroadcastEventBus.$emit(AppBroadcastEvent.EXIBIR_TASKBAR);
      AppBroadcastEventBus.$emit(AppBroadcastEvent.EXIBIR_SIDEBAR);
      AppBroadcastEventBus.$emit(AppBroadcastEvent.EXIBIR_CONTACT);
      AppBroadcastEventBus.$emit(AppBroadcastEvent.AUTENTICADO);
    }
}

export const ApplicationConfigService = new Service();
