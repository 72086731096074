import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    template: require('./bs-loader.html')
})
export class BSLoaderComponent extends Vue {

    @Prop({ type: Boolean, default: false })
    show!: boolean;
}
