





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class StepContainerComponent extends Vue {
  @Prop({ default: 0, required: true })
  step!: number;

  totalSteps = 5;

  get currentStep() {
    return this.step;
  }
}
