import { MultiselectResource } from '../bs-multiselect-filter/bs-multiselect-filter.types';

interface BSFormBuilderInputInterface {
    description: string;
    key: string;
}

interface BSFormBuilderInputGPSelectorInterface extends BSFormBuilderInputInterface {
    outputAs?: string;
    label?: any;
    query?: any;
    itens?: Array<any>;
    limit?: number;
    dontFilter?: boolean;
    ordered?: boolean;
}

interface BSFormBuilderInputGPDateCatcherInterface extends BSFormBuilderInputInterface {
    label?: string;
    nullable?: boolean;
    renderType?: string;
    startDate?: number;
    format?: string;
}

interface BSFormBuilderInputTextInterface extends BSFormBuilderInputInterface {
    mask?: string;
    placeholder?: string;
    inputClass?: string;
    onBlur?: Function;
}

export class BSFormBuilderInput implements BSFormBuilderInputInterface {
    constructor(config: any) {
        for (const key in config) {
            const temp = this as any;
            temp[key] = config[key];
        }
    }
    description!: string;
    key!: string;
}

export class BSFormBuilderInputGPSelector extends BSFormBuilderInput implements BSFormBuilderInputGPSelectorInterface {
    constructor(config: any) {
        super(config);
        for (const key in config) {
           const temp = this as any;
           temp[key] = config[key];
        }
    }
    outputAs?: string;
    label?: any;
    query?: any;
    itens?: any[];
    multiselectItens?: MultiselectResource[];
    limit?: number;
    dontFilter?: boolean;
    ordered?: boolean;
}

export class BSFormBuilderInputGPDateCatcher extends BSFormBuilderInput implements BSFormBuilderInputGPDateCatcherInterface {
    constructor(config: any) {
        super(config);
        for (const key in config) {
          const temp = this as any;
          temp[key] = config[key];
       }
    }
    label?: string;
    nullable?: boolean;
    renderType?: string;
    inputClass?: string;
    startDate?: number;
    format?: string;
    description!: string;
    key!: string;
}

export class BSFormBuilderInputText extends BSFormBuilderInput implements BSFormBuilderInputTextInterface {
    constructor(config: any) {
        super(config);
        for (const key in config) {
          const temp = this as any;
          temp[key] = config[key];
       }
    }
    mask?: string;
    description!: string;
    key!: string;
    placeholder?: string;
    inputClass?: string;
    onBlur?: Function;
}
