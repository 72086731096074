import IFilter from '../ifilter';
import { PercentageUtil } from '@/modules/module/util/percentage/percentage.util';

export default class FormatPercentageFilter implements IFilter {
    public filterName: any;

    constructor() {
        this.filterName = 'formatPercentage';
    }

    public filterAction(value: number) {
        return this.formatPercentage(value);
    }

    public formatPercentage = (value: number | string = 0) => {
        return PercentageUtil.formatPercentage(value);
    }
}
