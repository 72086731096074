import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

export interface GSPagination {
    page: number;
    pageTotal?: number;
    limit: number;
    totalItens?: number | null;
    last?: boolean;
}

@Component({
    template: require('./bs-pagination.html')
})
export class BSPaginationComponent extends Vue {

    data: GSPagination = {
        page: 1,
        pageTotal: 1,
        limit: 10,
        totalItens: null
    };

    @Prop({
        type: Object, default: () => {
            return {
                page: 1,
                pageTotal: 1,
                limit: 10,
                totalItens: null
            };
        }
    })
    defaultConfig!: GSPagination;

    @Watch('defaultConfig', { deep: true })
    onDefaultConfig(val: any) {
        this.data = val;
    }

    async doIncreasePage() {
        if (!this.isLastPage) {
            this.data.page++;
            this.emitChangePage();
        }
    }

    async doDecreasePage() {
        if (!this.isFirstPage) {
            this.data.page--;
            this.emitChangePage();
        }
    }

    async setNumberPage() {
        if (!this.data?.totalItens) {
          return 0;
        }

        let totalPage = this.data.totalItens / this.data.limit;
        if (totalPage % 1 > 0) {
            totalPage = Math.trunc(totalPage) + 1;
        }

        if (totalPage < this.data.page) { // Caso o total de páginas seja inferior que a página atual, seta a página atual para ser a última página
            this.data.page = totalPage;
        }
    }

    doIncreaseLimit() {
        if (!this.isMaximunLimit()) {
            this.data.limit += 10;
            this.setNumberPage();
            this.emitChangePage();
        }
    }

    doDecreaseLimit() {
        if (!this.isMinimunLimit()) {
            this.data.limit -= 10;
            this.emitChangePage();
            this.setNumberPage();
        }
    }

    emitChangePage() {
        this.$emit('pagination-settings-changed', this.data);
    }

    public get isFirstPage() {
        return this.data.page <= 1;
    }

    public get isLastPage() {
        return this.data.page === this.data.pageTotal;
    }

    public isMinimunLimit() {
        return this.data.limit <= 10;
    }

    public isMaximunLimit() {
        return (this.data?.totalItens && this.data.limit >= this.data.totalItens) || this.data.limit >= 100;
    }

    public get firstItemInPage() {
        return ((this.data.page - 1) * this.data.limit) + (this.data.totalItens ? 1 : 0);
    }

    public get lastItemInPage() {
        return this.isLastPage ? this.data.totalItens : this.data.page * this.data.limit;
    }

    get getPagingText() {
        if (!this.data.totalItens)
            return '0 registros';

        return `<span class="p-text-color-primary">${this.firstItemInPage}</span> - <span class="p-text-color-primary">${this.lastItemInPage}</span> de <span class="p-text-color-primary">${this.data.totalItens}</span>`;
    }

    get isEmptyPagination() {
        return !this.data.totalItens;
    }
}
