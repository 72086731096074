import { Prop, Vue, Watch, Component } from 'vue-property-decorator';

@Component({
    template: require('./gp-multiselect.html')
})
export class GPMultiselectComponent extends Vue {

    /**
     * name attribute to match optional label element
     * @default ''
     * @type {String}
     */
    @Prop({ type: String, default: '' })
    name!: string;

    /**
     * String to show when pointing to an option
     * @default 'Press enter to select'
     * @type {String}
     */
    @Prop({ type: String, default: 'Press enter to select' })
    selectLabel!: string;

    /**
     * String to show when pointing to an option
     * @default 'Press enter to select'
     * @type {String}
     */
    @Prop({ type: String, default: 'Press enter to select group' })
    selectGroupLabel!: string;

    /**
     * String to show next to selected option
     * @default 'Selected'
     * @type {String}
     */
    @Prop({ type: String, default: 'Selected' })
    selectedLabel!: string;

    /**
     * String to show when pointing to an already selected option
     * @default 'Press enter to remove'
     * @type {String}
     */
    @Prop({ type: String, default: 'Press enter to remove' })
    deselectLabel!: string;

    /**
     * String to show when pointing to an already selected option
     * @default 'Press enter to remove'
     * @type {String}
     */
    @Prop({ type: String, default: 'Press enter to deselect group' })
    deselectGroupLabel!: string;

    /**
     * Decide whether to show pointer labels
     * @default true
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: true })
    showLabels!: boolean;

    /**
     * Limit the display of selected options. The rest will be hidden within the limitText string.
     * @default 99999
     * @type {Integer}
     */
    @Prop({ type: Number, default: 99999 })
    limit!: number;

    /**
     * Sets maxHeight style value of the dropdown
     * @default 300
     * @type {Integer}
     */
    @Prop({ type: Number, default: 300 })
    maxHeight!: number;

    /**
     * Function that process the message shown when selected
     * elements pass the defined limit.
     * @default 'and * more'
     * @param {Int} count Number of elements more than limit
     * @type {Function}
     */
    @Prop({ type: Function, default: (count: any) => `and ${count} more` })
    limitText!: Function;

    /**
     * Set true to trigger the loading spinner.
     * @default False
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: false })
    loading!: boolean;

    /**
     * Disables the multiselect if true.
     * @default false
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    /**
     * Fixed opening direction
     * @default ''
     * @type {String}
     */
    @Prop({ type: String, default: '' })
    openDirection!: string;

    /**
     * Shows slot with message about empty options
     * @default true
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: true })
    showNoOptions!: boolean;

    @Prop({ type: Boolean, default: true })
    showNoResults!: boolean;

    @Prop({ type: Number, default: 0 })
    tabindex!: number;

    /**
     * Custom select class
     * @default 'multiselect__tags'
     * @type {String}
     */
    @Prop({ type: String, default: 'multiselect__tags' })
    multiselectTagsClass!: string;

    /**
     * Custom content wrapper class
     * @default 'multiselect__content-wrapper'
     * @type {String}
     */
    @Prop({ type: String, default: 'multiselect--above' })
    multiselectContentWrapperClass!: string;

    /**
     * Custom content wrapper above class
     * @default 'multiselect--above'
     * @type {String}
     */
    @Prop({ type: String, default: 'multiselect--above' })
    aboveClass!: string;

    /**
     * Custom input class
     * @default 'multiselect__input'
     * @type {String}
     */
    @Prop({ type: String, default: 'multiselect__input' })
    inputClass!: string;

    /**
     * Custom placeholder class
     * @default 'multiselect__placeholder'
     * @type {String}
     */
    @Prop({ type: String, default: 'multiselect__placeholder' })
    placeholderClass!: string;

    /**
     * Custom singleLabel class
     * @default 'multiselect__placeholder'
     * @type {String}
     */
    @Prop({ type: String, default: 'multiselect__single' })
    singleLabelClass!: string;


    /*******************************************************************
     * Multi Select Mixin
     *******************************************************************/


    /**
     * Decide whether to filter the results based on search query.
     * Useful for async filtering, where we search through more complex data.
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: true })
    internalSearch!: boolean;

    /**
     * Array of available options: Objects, Strings or Integers.
     * If array of objects, visible label will default to option.label.
     * If `labal` prop is passed, label will equal option['label']
     * @type {Array}
     */
    @Prop({ type: Array })
    options!: Array<any>;

    /**
     * Equivalent to the `multiple` attribute on a `<select>` input.
     * @default false
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: false })
    multiple!: boolean;

    /**
     * Presets the selected options value.
     * @type {Object||Array||String||Integer}
     */
    @Prop({ type: [Object, Array, String, Number], default: () => [] })
    value: any;

    /**
     * Key to compare objects
     * @default 'id'
     * @type {String}
     */
    @Prop(String)
    trackBy!: string;

    /**
     * Label to look for in option Object
     * @default 'label'
     * @type {String}
     */
    @Prop(String)
    label!: string;

    /**
     * Enable/disable search in options
     * @default true
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: true })
    searchable!: boolean;

    /**
     * Clear the search input after `)
     * @default true
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: true })
    clearOnSelect!: boolean;

    /**
     * Hide already selected options
     * @default false
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: false })
    hideSelected!: boolean;

    /**
     * Equivalent to the `placeholder` attribute on a `<select>` input.
     * @default 'Select option'
     * @type {String}
     */
    @Prop({ type: String, default: 'Select option' })
    placeholder!: string;

    /**
     * Allow to remove all selected values
     * @default true
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: true })
    allowEmpty!: boolean;

    /**
     * Reset this.internalValue, this.search after this.internalValue changes.
     * Useful if want to create a stateless dropdown.
     * @default false
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: false })
    resetAfter!: boolean;

    /**
     * Enable/disable closing after selecting an option
     * @default true
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: true })
    closeOnSelect!: boolean;

    /**
     * Function to interpolate the custom label
     * @default false
     * @type {Function}
     */
    @Prop({
        type: Function, default: function (option: any, label: any) {
          // if (this.isEmpty(option)) return '';
          if (!option || option === '') return '';
            return label ? option[label] : option;
        }
    })
    customLabel!: Function;

    /**
     * Disable / Enable tagging
     * @default false
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: false })
    taggable!: boolean;

    /**
     * String to show when highlighting a potential tag
     * @default 'Press enter to create a tag'
     * @type {String}
    */
    @Prop({ type: String, default: 'Press enter to create a tag' })
    tagPlaceholder!: string;

    /**
     * By default new tags will appear above the search results.
     * Changing to 'bottom' will revert this behaviour
     * and will proritize the search results
     * @default 'top'
     * @type {String}
    */
    @Prop({ type: String, default: 'top' })
    tagPosition!: string;

    /**
     * Number of allowed selected options. No limit if 0.
     * @default 0
     * @type {Number}
    */
    @Prop({ type: [Number, Boolean], default: false })
    max!: number | boolean;

    /**
     * Will be passed with all events as second param.
     * Useful for identifying events origin.
     * @default null
     * @type {String|Integer}
    */
    @Prop({ type: [String, Number], default: null })
    id!: string | number;

    /**
     * Limits the options displayed in the dropdown
     * to the first X options.
     * @default 1000
     * @type {Integer}
    */
    @Prop({ type: Number, default: 1000 })
    optionsLimit!: number;

    /**
     * Name of the property containing
     * the group values
     * @default 1000
     * @type {String}
    */
    @Prop({ type: String })
    groupValues!: string;

    /**
     * Name of the property containing
     * the group label
     * @default 1000
     * @type {String}
    */
    @Prop({ type: String })
    groupLabel!: string;
    /**
     * Allow to select all group values
     * by selecting the group label
     * @default false
     * @type {Boolean}
     */
    @Prop({ type: Boolean, default: false })
    groupSelect!: boolean;

    /**
     * Array of keyboard keys to block
     * when selecting
     * @default 1000
     * @type {String}
    */
    @Prop({ type: Array, default: () => [] })
    blockKeys!: Array<any>;

    /**
     * Prevent from wiping up the search value
     * @default false
     * @type {Boolean}
    */
    @Prop({ type: Boolean, default: false })
    preserveSearch!: boolean;

    /**
     * Select 1st options if value is empty
     * @default false
     * @type {Boolean}
    */
    @Prop({ type: Boolean, default: false })
    preselectFirst!: boolean;

    /***********************************************************
     * Pointer Mixin
     ***********************************************************/

    @Prop({ type: Boolean, default: true })
    showPointer!: boolean;

    @Prop({ type: Number, default: 40 })
    optionHeight!: number;

    /********************************************************
     * Private Props
     ********************************************************/

    pointer = 0;
    pointerDirty = false;
    search = '';
    optimizedHeight = this.maxHeight;
    isOpen = false;
    prefferedOpenDirection = 'below';

    /***********************************************************
   * Multiselect Watch
   ***********************************************************/

    @Watch('internalValue')
    watchInternalValue() {

        if (this.resetAfter && this.internalValue.length) {
            this.search = '';
            this.$emit('input', this.multiple ? [] : null);
        }
    }

    @Watch('search')
    watchSearch() {
        this.$emit('search-change', this.search, this.id);
    }

    /***********************************************************
     * Pointer Watch
     ***********************************************************/

    @Watch('filteredOptions')
    watchFilteredOptions() {
        this.pointerAdjust();
    }

    @Watch('isOpen')
    watchIsOpen() {
        this.pointerDirty = false;
    }

    /*****************************************
     * Pointer Computeds
     *****************************************/

    get pointerPosition() {
        return this.pointer * this.optionHeight;
    }

    get visibleElements() {
        return this.optimizedHeight / this.optionHeight;
    }

    /*******************************************
     * Multiselect Computeds
     *******************************************/

    get internalValue() {
        return this.value || this.value === 0 ? Array.isArray(this.value) ? this.value : [this.value] : [];
    }

    get filteredOptions() {
        const search = this.search || '';
        const normalizedSearch = search.toLowerCase().trim();

        let options = this.options.concat();


        if (this.internalSearch) {
            options = this.groupValues ? this.filterAndFlat(options, normalizedSearch, this.label) : this.filterOptions(options, normalizedSearch, this.label, this.customLabel);

        } else {
            options = this.groupValues ? this.flattenOptions(this.groupValues, this.groupLabel)(options) : options;
        }

        options = this.hideSelected ? options.filter(this.not(this.isSelected)) : options;


        if (this.taggable && normalizedSearch.length && !this.isExistingOption(normalizedSearch)) {
            if (this.tagPosition === 'bottom') {
                options.push({ isTag: true, label: search });

            } else {
                options.unshift({ isTag: true, label: search });
            }
        }

        return options.slice(0, this.optionsLimit);
    }

    get valueKeys() {
        if (this.trackBy) {
            return this.internalValue.map(element => element[this.trackBy]);

        } else {
            return this.internalValue;
        }
    }

    get optionKeys() {
        const options = this.groupValues ? this.flatAndStrip(this.options) : this.options;
        return options.map((element: any) => this.customLabel(element, this.label).toString().toLowerCase());
    }

    get currentOptionLabel() {
        return this.multiple ? this.searchable ? '' : this.placeholder : this.internalValue.length ? this.getOptionLabel(this.internalValue[0]) : this.searchable ? '' : this.placeholder;
    }

    /*********************************************
     * Main Computeds
     *********************************************/

    get isSingleLabelVisible() {
        return (this.singleValue && (!this.isOpen || !this.searchable) && !this.visibleValues.length);
    }

    get isPlaceholderVisible() {
        return !this.internalValue.length && (!this.searchable || !this.isOpen);
    }

    get visibleValues() {
        return this.multiple ? this.internalValue.slice(0, this.limit) : [];
    }

    get singleValue() {
        return this.internalValue[0];
    }

    get deselectLabelText() {
        return this.showLabels ? this.deselectLabel : '';
    }

    get deselectGroupLabelText() {
        return this.showLabels ? this.deselectGroupLabel : '';
    }

    get selectLabelText() {
        return this.showLabels ? this.selectLabel : '';
    }

    get selectGroupLabelText() {
        return this.showLabels ? this.selectGroupLabel : '';
    }

    get selectedLabelText() {
        return this.showLabels ? this.selectedLabel : '';
    }

    get inputStyle() {
        if (this.searchable || (this.multiple && this.value && this.value.length)) {
            // Hide input by setting the width to 0 allowing it to receive focus
            return this.isOpen ? { width: 'auto' } : { width: '0', position: 'absolute', padding: '0' };
        }
    }

    get contentStyle() {
        return this.options.length ? { display: 'inline-block' } : { display: 'block' };
    }

    get isAbove() {
        if (this.openDirection === 'above' || this.openDirection === 'top') {
            return true;

        } else if (this.openDirection === 'below' || this.openDirection === 'bottom') {
            return false;

        } else {
            return this.prefferedOpenDirection === 'above';
        }
    }

    // get showSearchInput() {
    //     return (this.searchable && (this.hasSingleSelectedSlot && (this.visibleSingleValue || this.visibleSingleValue === 0) ? this.isOpen : true));
    // }

    /******************************************
     * Multiselect Methods
     ******************************************/

    /**
    * Returns the internalValue in a way it can be emited to the parent
    * @returns {Object||Array||String||Integer}
    */
    getValue() {
        return this.multiple ? this.internalValue : this.internalValue.length === 0 ? null : this.internalValue[0];
    }

    /**
     * Filters and then flattens the options list
     * @param  {Array}
     * @returns {Array} returns a filtered and flat options list
     */
    filterAndFlat(options: any, search: any, label: any) {
        return this.flow(
            this.filterGroups(search, label, this.groupValues, this.groupLabel, this.customLabel),
            this.flattenOptions(this.groupValues, this.groupLabel)
        )(options);
    }

    /**
     * Flattens and then strips the group labels from the options list
     * @param  {Array}
     * @returns {Array} returns a flat options list without group labels
     */
    flatAndStrip(options: any) {
        return this.flow(
            this.flattenOptions(this.groupValues, this.groupLabel),
            this.stripGroups
        )(options);
    }

    /**
     * Updates the search value
     * @param  {String}
     */
    updateSearch(query: any) {
        this.search = query;
    }

    /**
     * Finds out if the given query is already present
     * in the available options
     * @param  {String}
     * @returns {Boolean} returns true if element is available
     */
    isExistingOption(query: any) {
        return !this.options ? false : this.optionKeys.indexOf(query) > -1;
    }

    /**
     * Finds out if the given element is already present
     * in the result value
     * @param  {Object||String||Integer} option passed element to check
     * @returns {Boolean} returns true if element is selected
     */
    isSelected(option: any) {
        const opt = this.trackBy ? option[this.trackBy] : option;
        return this.valueKeys.indexOf(opt) > -1;
    }

    /**
     * Returns empty string when options is null/undefined
     * Returns tag query if option is tag.
     * Returns the customLabel() results and casts it to string.
     *
     * @param  {Object||String||Integer} Passed option
     * @returns {Object||String}
     */
    getOptionLabel(option: any) {
        if (this.isEmpty(option)) return '';

        if (option.isTag) return option.label;

        if (option.$isLabel) return option.$groupLabel;

        const label = this.customLabel(option, this.label);

        if (this.isEmpty(label)) return '';
        return label;
    }

    /**
     * Add the given option to the list of selected options
     * or sets the option as the selected option.
     * If option is already selected -> remove it from the results.
     *
     * @param  {Object||String||Integer} option to select/deselect
     * @param  {Boolean} block removing
     */
    select(option: any, key?: any) {

        if (option.$isLabel && this.groupSelect) {
            this.selectGroup(option);
            return;
        }

        if (this.blockKeys.indexOf(key) !== -1 || this.disabled || option.$isDisabled || option.$isLabel) return;

        if (this.max && this.multiple && this.internalValue.length === this.max) return;

        if (key === 'Tab' && !this.pointerDirty) return;

        if (option.isTag) {
            this.$emit('tag', option.label, this.id);
            this.search = '';

            if (this.closeOnSelect && !this.multiple) this.deactivate();

        } else {
            const isSelected = this.isSelected(option);

            if (isSelected) {
                if (key !== 'Tab') this.removeElement(option);
                return;
            }

            this.$emit('select', option, this.id);

            if (this.multiple) {
                this.$emit('input', this.internalValue.concat([option]), this.id);

            } else {
                this.$emit('input', option, this.id);
            }


            if (this.clearOnSelect) this.search = '';
        }

        if (this.closeOnSelect) this.deactivate();
    }

    /**
     * Add the given group options to the list of selected options
     * If all group optiona are already selected -> remove it from the results.
     *
     * @param  {Object||String||Integer} group to select/deselect
     */
    selectGroup(selectedGroup: any) {
        const group = this.options.find(option => {
            return option[this.groupLabel] === selectedGroup.$groupLabel;
        });

        if (!group) return;

        if (this.wholeGroupSelected(group)) {
            this.$emit('remove', group[this.groupValues], this.id);

            const newValue = this.internalValue.filter(
                option => group[this.groupValues].indexOf(option) === -1
            );

            this.$emit('input', newValue, this.id);

        } else {
            const optionsToAdd = group[this.groupValues].filter(this.not(this.isSelected));
            this.$emit('select', optionsToAdd, this.id);
            this.$emit('input', this.internalValue.concat(optionsToAdd), this.id);
        }
    }

    /**
     * Helper to identify if all values in a group are selected
     *
     * @param {Object} group to validated selected values against
     */
    wholeGroupSelected(group: any) {
        return group[this.groupValues].every(this.isSelected);
    }

    /**
     * Removes the given option from the selected options.
     * Additionally checks this.allowEmpty prop if option can be removed when
     * it is the last selected option.
     *
     * @param  {type} option description
     * @returns {type}        description
     */
    removeElement(option: any, shouldClose = true) {
        if (this.disabled) return;

        if (!this.allowEmpty && this.internalValue.length <= 1) {
            this.deactivate();
            return;
        }

        const index = typeof option === 'object' ? this.valueKeys.indexOf(option[this.trackBy]) : this.valueKeys.indexOf(option);

        this.$emit('remove', option, this.id);

        if (this.multiple) {
            const newValue = this.internalValue.slice(0, index).concat(this.internalValue.slice(index + 1));
            this.$emit('input', newValue, this.id);

        } else {
            this.$emit('input', null, this.id);
        }

        if (this.closeOnSelect && shouldClose) this.deactivate();
    }

    /**
     * Calls this.removeElement() with the last element
     * from this.internalValue (selected element Array)
     *
     * @fires this#removeElement
     */
    removeLastElement() {

        if (this.blockKeys.indexOf('Delete') !== -1) return;

        if (this.search.length === 0 && Array.isArray(this.internalValue)) {
            this.removeElement(this.internalValue[this.internalValue.length - 1], false);
        }
    }

    /**
     * Opens the multiselect’s dropdown.
     * Sets this.isOpen to TRUE
     */
    activate() {

        if (this.isOpen || this.disabled) return;

        this.adjustPosition();

        if (this.groupValues && this.pointer === 0 && this.filteredOptions.length) {
            this.pointer = 1;
        }

        this.isOpen = true;

        if (this.searchable) {
            if (!this.preserveSearch) this.search = '';
            this.$nextTick(() => {
                const search = this.$refs.search as HTMLElement;
                search.focus();
            });

        } else {
            const el = this.$el as HTMLElement;
            el.focus();
        }
        this.$emit('open', this.id);
    }

    /**
     * Closes the multiselect’s dropdown.
     * Sets this.isOpen to FALSE
     */
    deactivate() {

        if (!this.isOpen) return;

        this.isOpen = false;

        if (this.searchable) {
            const search = this.$refs.search as HTMLElement;
            search.blur();

        } else {
            const el = this.$el as HTMLElement;
            el.blur();
        }

        if (!this.preserveSearch) this.search = '';
        this.$emit('close', this.getValue(), this.id);
    }

    /**
     * Call this.activate() or this.deactivate()
     * depending on this.isOpen value.
     *
     * @fires this#activate || this#deactivate
     * @property {Boolean} isOpen indicates if dropdown is open
     */
    toggle() {
        this.isOpen ? this.deactivate() : this.activate();
    }

    /**
     * Updates the hasEnoughSpace variable used for
     * detecting where to expand the dropdown
     */
    adjustPosition() {
        if (typeof window === 'undefined') return;

        const spaceAbove = this.$el.getBoundingClientRect().top;
        const spaceBelow = window.innerHeight - this.$el.getBoundingClientRect().bottom;
        const hasEnoughSpaceBelow = spaceBelow > this.maxHeight;

        if (hasEnoughSpaceBelow || spaceBelow > spaceAbove || this.openDirection === 'below' || this.openDirection === 'bottom') {
            this.prefferedOpenDirection = 'below';
            this.optimizedHeight = Math.min(spaceBelow - 40, this.maxHeight);

        } else {
            this.prefferedOpenDirection = 'above';
            this.optimizedHeight = Math.min(spaceAbove - 40, this.maxHeight);
        }
    }

    /**
     * Emit an event when the scrollbar is at the bottom of list.
     */
    scrollBottomList() {
        const list = this.$refs.list as HTMLElement;
        const scrollAtTheEnd = list.scrollHeight - list.scrollTop === list.clientHeight;
        if (scrollAtTheEnd) {
            this.$emit('scrollEnd', this.search);
        }
    }

    /**************************************************
     * Pointer Methods
     **************************************************/

    optionHighlight(index: any, option: any) {
        return {
            'multiselect__option--highlight': index === this.pointer && this.showPointer,
            'multiselect__option--selected': this.isSelected(option)
        };
    }

    groupHighlight(index: any, selectedGroup: any) {
        if (!this.groupSelect) {
            return ['multiselect__option--group', 'multiselect__option--disabled'];
        }

        const group = this.options.find(option => {
            return option[this.groupLabel] === selectedGroup.$groupLabel;
        });

        return [
            'multiselect__option--group',
            { 'multiselect__option--highlight': index === this.pointer && this.showPointer },
            { 'multiselect__option--group-selected': this.wholeGroupSelected(group) }
        ];
    }

    addPointerElement({ key }: any = 'Enter') {

        if (this.filteredOptions.length > 0) {
            this.select(this.filteredOptions[this.pointer], key);
        }
        this.pointerReset();
    }

    pointerForward() {
        const list = this.$refs.list as HTMLElement;
        if (this.pointer < this.filteredOptions.length - 1) {
            this.pointer++;

            if (list.scrollTop <= this.pointerPosition - (this.visibleElements - 1) * this.optionHeight) {
                list.scrollTop = this.pointerPosition - (this.visibleElements - 1) * this.optionHeight;
            }

            if (
                this.filteredOptions[this.pointer] &&
                this.filteredOptions[this.pointer].$isLabel &&
                !this.groupSelect
            ) this.pointerForward();
        }
        this.pointerDirty = true;
    }

    pointerBackward() {
        const list = this.$refs.list as HTMLElement;
        if (this.pointer > 0) {
            this.pointer--;

            if (list.scrollTop >= this.pointerPosition) {
                list.scrollTop = this.pointerPosition;
            }
            if (
                this.filteredOptions[this.pointer] &&
                this.filteredOptions[this.pointer].$isLabel &&
                !this.groupSelect
            ) this.pointerBackward();
        } else {
            if (
                this.filteredOptions[this.pointer] &&
                this.filteredOptions[0].$isLabel &&
                !this.groupSelect
            ) this.pointerForward();
        }
        this.pointerDirty = true;
    }

    pointerReset() {
        if (!this.closeOnSelect)
            return;

        this.pointer = 0;

        const list = this.$refs.list as HTMLElement;

        if (list) {
            list.scrollTop = 0;
        }
    }

    pointerAdjust() {

        if (this.pointer >= this.filteredOptions.length - 1) {
            this.pointer = this.filteredOptions.length ? this.filteredOptions.length - 1 : 0;
        }

        if (this.filteredOptions.length > 0 && this.filteredOptions[this.pointer].$isLabel && !this.groupSelect) {
            this.pointerForward();
        }
    }
    pointerSet(index: any) {
        this.pointer = index;
        this.pointerDirty = true;
    }

    /******************************************************
     * Multiselect private methods
     ******************************************************/

    isEmpty(opt: any) {
        if (opt === 0) return false;
        if (Array.isArray(opt) && opt.length === 0) return true;
        return !opt;
    }

    not(fun: any) {
        return (...params: any[]) => !fun(...params);
    }

    includes(str: any, query: any) {
        if (str === undefined) str = 'undefined';
        if (str === null) str = 'null';
        if (str === false) str = 'false';
        const text = str.toString().toLowerCase();
        return text.indexOf(query.trim()) !== -1;
    }

    filterOptions(option: any, search: any, label: any, customLabel: any) {
        return this.options.filter(option => this.includes(customLabel(option, label), search));
    }

    stripGroups(options: any) {
        return options.filter((option: any) => !option.$isLabel);
    }

    flattenOptions(values: any, label: any) {
        return (options: any) =>
            options.reduce((prev: any, curr: any) => {

                if (curr[values] && curr[values].length) {
                    prev.push({
                        $groupLabel: curr[label],
                        $isLabel: true
                    });
                    return prev.concat(curr[values]);
                }
                return prev;
            }, []);
    }

    filterGroups(search: any, label: any, values: any, groupLabel: any, customLabel: any) {
        return (groups: any) =>
            groups.map((group: any) => {

                if (!group[values]) {
                    console.warn(`Options passed to vue-multiselect do not contain groups, despite the config.`);
                    return [];
                }
                const groupOptions = this.filterOptions(group[values], search, label, customLabel);

                return groupOptions.length ? {
                        [groupLabel]: group[groupLabel],
                        [values]: groupOptions
                    } : [];
            });
    }

    flow = (...fns: any[]) => (x: any) => fns.reduce((v, f) => f(v), x);

    mounted() {
        if (!this.multiple && this.max) {
            console.warn('[Vue-Multiselect warn]: Max prop should not be used when prop Multiple equals false.');
        }
        if (this.preselectFirst && !this.internalValue.length && this.options.length) {
            this.select(this.filteredOptions[0]);
        }
    }
}
