import { RouteConfig } from 'vue-router'
import { RouterPath } from '@/enums/router-path.enum'

export default (): Array < RouteConfig > => {
  return [
    {
      path: RouterPath.FUNCTIONALITIES,
      component: () => import('./components/pages/page-functionalities/page-functionalities').then(m => m.default)
    },
  ]
}