import { AuthenticationService } from '@/modules/module-authentication/authentication.service';
import { CompanyHeaderModel } from '@/modules/module/model/client/company-model';
import { UserAuth } from '@/modules/module/model/client/user-auth';
import { AmplitudeService } from '@/modules/module/services/amplitude/amplitude.service';
import { MultiCompanyService } from '@/modules/module/services/multi-company/multi-company.service';
import { NotificationUtil } from '@/modules/module/util/notification.util';
import {Component, Vue} from 'vue-property-decorator';

interface UI {
  active: boolean;
  companies: Array<CompanyHeaderModel>;
  company?: CompanyHeaderModel;
  user?: UserAuth;
}

@Component({
    template: require('./bs-contact.html'),
})
export default class BSContactComponent extends Vue {

    ui: UI = {
      active: false,
      companies: [],
      company: undefined,
      user: undefined,
    }

    created() {
      this.ui.user = AuthenticationService.user;
    }

    async mounted() {
        try {
          if (!this.hasSefazGroup) {
            this.ui.companies = await MultiCompanyService.userCompanies();
            this.ui.company = MultiCompanyService.products();
          }

        } catch (error) {
            NotificationUtil.warning(error as string);
        }
    }

    isSefazPermission() {
      if (this.ui.user) {
        const found = this.ui.user.groups?.find(g => g === "SEFAZ");
        return !!found;
      }
      return false;
    }

    get hasSefazGroup() {
      return this.isSefazPermission();
    }

    get companyWorkspace() {
      if (this.ui.company) {
          return this.ui.company.name;
      }
    }

    urlWhatsapp() {
        AmplitudeService.logEvent('click_whatsapp', { product: 'Suíte'});
        const message = `Atendimento Buson Suíte 55 (34) 99907-0612`;
        const link = `https://api.whatsapp.com/send?phone=553499070612&text=${message}`;
        window.open(link, '_blank');
    }

    get isActive() {
      return this.ui.active;
    }

    get keyAccountPhoneNumber() {
      if (this.ui.company) {
          return this.ui.company.keyAccountSupport.phoneNumber;
      }
    }

    showButtons() {
      this.ui.active = !this.ui.active;
      AmplitudeService.logEvent('click_contact');
    }

    talkTo() {
        AmplitudeService.logEvent('click_ka', { product: 'Suíte', company: this.ui.company?.name, ka: this.ui.company?.keyAccountSupport.name ,companyChannels: this.ui.company?.companyChannels});
        const message = `Seu canal de contato com Executivo de Vendas`;
        // definir o contato do KA
        const numberPhone = this.keyAccountPhoneNumber;
        const link = `https://api.whatsapp.com/send?phone=${numberPhone}&text=${message}`;
        window.open(link, '_blank');
    }

}
