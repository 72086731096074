import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
    template: require('./bs-blockquote.html')
})
export class BSBlockquoteComponent extends Vue {
    @Prop({ type: String, default: '' })
    title!: string;

    @Prop({ type: String, default: '' })
    text!: string;

    @Prop({ type: String, default: 'welcome' })
    tag!: string;

    showBlockquote = false;

    created() {
        this.show();
    }

    show() {
        const closed = localStorage.getItem(this.tag);
        this.showBlockquote = closed !== 'true';
    }

    hide() {
        localStorage.setItem(this.tag, 'true');
        this.showBlockquote = false;
    }
}
