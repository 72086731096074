import CompanyCurrentMonthEnum from '@/enums/company-current-month.enum';
import moment from 'moment';

export class Util {
    public subtractMonth(date: string, month: number) {
        return moment(date).subtract(month, 'month').format('YYYY-MM-DD');
    }

    public getDateFormatted = (dateTimeString: string, outFormat: string, inFormat?: string) => {
        const momentDate = moment(dateTimeString, inFormat || 'YYYY-MM-DD HH:mm:ss');
        return momentDate.isValid() ? momentDate.format(outFormat) : '';
    }

    public getDuration = (start: any, end: any) => {
        const first = moment(end);
        const second = moment(start);
        return moment.duration(first.diff(second));
    }

    public isAfter = (date1: moment.Moment | Date | string, date2: moment.Moment | Date | string, format = 'YYYY-MM-DD') => {
        return moment(date1).format(format) > moment(date2).format(format);
    }

    public isAfterOrEqual = (date1: moment.Moment | Date | string, date2: moment.Moment | Date | string) => {
        return moment(date1).format('YYYY-MM-DD') >= moment(date2).format('YYYY-MM-DD');
    }

    public isAfterOrEqualToday = (date: moment.Moment | Date | string) => {
        return moment(date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD');
    }

    public isValidDateString = (date: string) => {
        return /^\d{4}-[0-1]{1}[0-9]-[0-3]{1}[0-9]{1}$/.test(date);
    }

    public convertToMin = (type: string, value: number) => {
        if (!type || !value) return 0;
        let minutes = 0;
        switch (type) {
            case 'minutes':
                minutes = value;
                break;
            case 'days':
                minutes = value * 24 * 60;
                break;
            case 'hour':
                minutes = value * 60;
                break;
            default:
                break;
        }
        return minutes;
    }

    public getDateHumanized = (value: string) => {
        if (!value) return;
        // Segunda, 29 out 2018
        return `${moment(value)
            .format('dddd')
            .replace('-feira', '')}, ${moment(value).format('DD MMM YYYY')}`;
    }

    public getDateTimeHumanized = (value: string) => {
        if (!value) return;
        // Quinta, 29 out 2018 às 08:00
        return `${moment(value)
            .format('dddd')
            .replace('-feira', '')}, ${moment(value).format('DD MMM YYYY [às] HH:mm')}`;
    }

    public getDateTimeHumanizedShort = (value: string) => {
        if (!value) return;
        // 29 out 2018 às 08:00
        return `${moment(value).format('DD MMM YYYY [às] HH:mm')}`;
    }

    public timeHumanized = (value: string) => {
        if (!value) return;

        const splitTime = value.split(':');

        return `${+splitTime[0]}h ${splitTime[1]}m`;
    }

    /**
     * Calcula e retorna a diferença absoluta entre os fusos
     * Ex.: -03:00 e -04:00 - Retorno 1
     */
    public diffTimeZone = (previousTimeZone: string, currentTimeZone: string) => {
        if (!previousTimeZone || !currentTimeZone) return;

        const prev = Math.abs(Number(previousTimeZone.split(':')[0]));
        const curr = Math.abs(Number(currentTimeZone.split(':')[0]));

        return Math.abs(prev - curr);
    }

    /**
     * Compara os 2 fusos passados
     * @returns retorna -1 se currTimeZone for menor que prevTimeZone
     *          retorna 0 se os fusos forem iguais
     *          retorna 1 se currTimeZone for maior que prevTimeZone
     */
    public compareTimeZone = (prevTimeZone: string, currTimeZone: string) => {
        if (currTimeZone === prevTimeZone)
            return 0;

        if (moment(currTimeZone, 'Z').isBefore(moment(prevTimeZone, 'Z'))) // no moment -03:00 isBefore -04:00, porém -3 é maior que -4
            return 1;

        return -1;
    }

    public sumDurationToDate(date: string, durationTime: string, outputFormat = 'YYYY-MM-DD HH:mm:ss') {
        const duration = moment.duration(durationTime);

        return moment(date)
            .add(duration.asHours(), 'hours')
            .format(outputFormat);
    }

    /**
    * Calcula a diferença entre 2 datas e retorna o valor absoluto (valor sempre positivo)
    * @param startedDate
    * @param endedDate
    */
    public calcDiff(startedDate: string | Date, endedDate: string | Date, unit= 'days') {
        const momentStart = moment(startedDate);
        const momentEnd = moment(endedDate);
        const diff = momentEnd.diff(momentStart, unit as any);
        return Math.abs(diff);
    }

    /**
     * Calcula a diferença em dias entre 2 datas e retorna o valor absoluto (valor sempre positivo)
     * @param startedDate
     * @param endedDate
     * @param format
     */
    public calcDaysDiff(startedDate: string, endedDate: string, format: string) {
        const momentStart = moment(startedDate, format);
        const momentEnd = moment(endedDate, format);
        const diff = momentEnd.diff(momentStart, 'days');
        return Math.abs(diff);
    }

    period(startedAt: string, endedAt: string, format = 'DD/MM/YYYY') {
        if (!startedAt && !endedAt) return;

        if (startedAt && endedAt)
            return `${this.getDateFormatted(startedAt, format)} a ${this.getDateFormatted(
                endedAt,
                format
            )}`;

        if (startedAt) return `Início: ${this.getDateFormatted(startedAt, format)}`;

        return `Término ${this.getDateFormatted(endedAt, format)}`;
    }
    public getMonth(date: string) {
        return moment(date).format('MMMM');
    }
    public getMonthByNumber(month: number) {
        switch (month) {
          case CompanyCurrentMonthEnum.JAN:
            return 'Janeiro';
          case CompanyCurrentMonthEnum.FEV:
            return 'Fevereiro';
          case CompanyCurrentMonthEnum.MAR:
            return 'Março';
          case CompanyCurrentMonthEnum.ABR:
            return 'Abril';
          case CompanyCurrentMonthEnum.MAI:
            return 'Maio';
          case CompanyCurrentMonthEnum.JUN:
            return 'Junho';
          case CompanyCurrentMonthEnum.JUL:
            return 'Julho';
          case CompanyCurrentMonthEnum.AGO:
            return 'Agosto';
          case CompanyCurrentMonthEnum.SET:
            return 'Setembro';
          case CompanyCurrentMonthEnum.OUT:
            return 'Outubro';
          case CompanyCurrentMonthEnum.NOV:
            return 'Novembro';
          case CompanyCurrentMonthEnum.DEZ:
            return 'Dezembro';
          default:
            break;
        }
      }
}

export const DateUtil = new Util();
