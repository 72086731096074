import Vue from 'vue';
import { AmplitudeService } from '@/modules/module/services/amplitude/amplitude.service';

/*
    USAGE
    // Apenas nome do evento:
        v-amplitude="'event_name'"

    // Nome do evento e objeto de propriedade:
        v-amplitude="{event: 'event_name', props: eventProperties}"

    // Evento e propriedade por modifiers:
        v-amplitude.event="'event_name'"
        v-amplitude.props="eventProperties"

    // Ação :click por default:
        v-amplitude:click

    // Documentação de diretivas do Vue.js:
        https://br.vuejs.org/v2/guide/custom-directive.html

    // Documentação dos eventos do PDV:
        https://docs.google.com/spreadsheets/d/1-q02vH4GiPobHGK1_eCmMSxElEIH_KalrB1Upv5kbSE/edit#gid=367769533

*/

Vue.directive('amplitude', {
    bind(el, binding) {

        const arg = binding.arg || 'click';

        el.addEventListener(arg, () => {
            if (binding.modifiers.event) {
                const event = binding.modifiers.event;
                const props = binding.modifiers.props || null;

                if (!event) return;
                AmplitudeService.logEvent(event, props);
            } else if (binding.value) {
                const event = binding.value.event || binding.value;
                const props = binding.value.props || null;

                if (!event) return;
                AmplitudeService.logEvent(event, props);
            }
        });
    }
});
