import { Vue } from 'vue-property-decorator';
import { Prop, Component } from 'vue-property-decorator';

@Component({
    template: require('./bs-loading.html')
})
export class BSLoadingComponent extends Vue {

    @Prop(Boolean)
    show!: boolean;

    @Prop({ type: Boolean, default: false })
    small!: boolean;

}
