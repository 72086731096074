import { Printer } from './printer';
import { SalesUnitResource } from './sales-unit-resource';
import { BaseResource } from './base-resource';
import { GuichePagoResource } from './guiche-pago-resource';

export class ProfileResource extends BaseResource<number> {
    constructor() {
        super();
        this.salesUnit = new SalesUnitResource();
    }
    name!: string;
    username!: string;
    email!: string;
    partnerId!: number;
    roles!: Array<any>;
    privileges!: Array<any>;
    companyIds!: Array<number>;
    groupIds!: Array<number>;
    printers!: Array<Printer>;
    salesUnit: SalesUnitResource;
    salesUnitId!: SalesUnitResource;
    salesUnits!: Array<SalesUnitResource>;
    onboardActivated!: boolean;
    guichePago!: GuichePagoResource;
    printTerminalSetupCode!: string;
    contractLevel!: number;
}
