export interface Position {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
}

export interface MultiselectResource {
    id: number;
    name: string;
    checked: boolean;
    applied: boolean;
}

export enum TypeEnum {
    CHECKBOX = 'CHECKBOX',
    RADIO = 'RADIO',
}
