import { Component, Vue } from 'vue-property-decorator'
import router from '@/router';
import { RouterPath } from '@/enums/router-path.enum';
import { UserAuth } from '@/modules/module/model/client/user-auth';
import { AuthenticationService } from '@/modules/module-authentication/authentication.service';
import { UserFactory } from '@/modules/factory/users/users.factory';
import { UserModel } from '@/modules/module/model/client/user-model';
import { NotificationUtil } from '@/modules/module/util/notification.util';
import { AppBroadcastEventBus, AppBroadcastEvent } from '@/app.broadcast-event-bus';
import { AmplitudeService } from '@/modules/module/services/amplitude/amplitude.service';
import { AMPLITUDE_GROUPS } from '@/modules/module/constants/amplitude-groups.constants';
import { SanitizerUtil } from '@/modules/module/util/sanitizer/sanitizer.util';

interface UI {
    user?: UserModel;
    userAuth?: UserAuth;
    oldPassword: string;
    confirmNewPassword: string;
    newPassword: string;
}

@Component({
    template: require('./page-user-account.html'),
})
export class PageUserAccountComponent extends Vue {

    ui: UI = {
        user: {
          firstName: '',
          lastName: '',
          username: '',
          email: ''
        },
        userAuth: undefined,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    };

    private isShowingPassword = false;

    created() {
      if (AuthenticationService.isAuthenticated) {
          this.ui.userAuth = AuthenticationService.user;

          this.ui.user = {
            firstName: this.ui.userAuth.givenName,
            lastName: this.ui.userAuth.familyName,
            username: this.ui.userAuth.username,
            email: this.ui.userAuth.email
          };
      }
      if (!this.ui.userAuth) {
        return;
      }
      const userId: string = this.ui.userAuth.id;
      UserFactory.getUserById(userId)
          .then((user: UserModel) => {
              this.ui.user = user;
          })
          .catch((error: any) => {
              console.error(error);
              NotificationUtil.error('Ocorreu um erro interno, tente novamente mais tarde.');
          });
    }

    mounted() {
      if (this.hasPermissionToSefaz()) {
        this.$router.push(RouterPath.ROOT);
      }
      AmplitudeService.logEvent('screen_open', { page: 'user_account', group: AMPLITUDE_GROUPS.ACCOUNTING });
    }

    // retorna os dados da api getUserById
    get userInfo() {
        return this.ui.user;
    }

    hasPermissionToSefaz() {
      if (this.ui.userAuth && this.ui.userAuth.groups && this.ui.userAuth.groups.length) {
        const found = this.ui.userAuth.groups.find(el => el === 'SEFAZ');
        return !!found;
      }
      return false;
    }

    /*
    * Change Password
    */
    async doChangePassword(): Promise<void> {
        if(this.missingPass()){
          NotificationUtil.exception('Existem campos inválidos');
          return;
        }
        try {
            SanitizerUtil.clearValue(this.ui.oldPassword);
            SanitizerUtil.clearValue(this.ui.newPassword);
            SanitizerUtil.clearValue(this.ui.confirmNewPassword);
            AppBroadcastEventBus.$emit(AppBroadcastEvent.EXIBIR_LOADER);
            this.ui.oldPassword = this.ui.oldPassword.trim();
            this.ui.newPassword = this.ui.newPassword.trim();
            this.ui.confirmNewPassword = this.ui.confirmNewPassword.trim();

            if (/[a-zA-Z]/g.test(this.ui.newPassword) === false) {
              NotificationUtil.warning('Senha precisa ter ao menos uma Letra');
              return;
            }
            if (/[0-9]/g.test(this.ui.newPassword) === false) {
              NotificationUtil.warning('Senha precisa ter ao menos um Número');
              return;
            }
            if (this.ui.newPassword.length <=7 ) {
                NotificationUtil.warning('Senha precisa ter número 8 caracteres');
                return;
            }
            if (this.ui.newPassword !== this.ui.confirmNewPassword) {
                NotificationUtil.warning('Os campos não coincidem.');
                return;
            }
            if (!this.ui.userAuth) {
              return;
            }
            const userId: string = this.ui.userAuth.id;
            await UserFactory.changePassword(
              userId,
              this.ui.oldPassword,
              this.ui.newPassword
            );

            NotificationUtil.success('Salvo com sucesso');
            router.push(RouterPath.ROOT);
            this.clearForm();
        } catch (error) {
          let message = error;
          if (error && error.errorResponse && error.errorResponse.code === 'user.old_password_invalid') {
              message = error.errorResponse.properties[1].value;
              NotificationUtil.warning(message, [{ key: 'duration', value: 3000 }]);
          } else {
            NotificationUtil.exception(error as string), [{ key: 'duration', value: 3000 }];
          }
        } finally {
            AppBroadcastEventBus.$emit(AppBroadcastEvent.ESCONDER_LOADER);
        }
    }

    missingPass() {
      return this.ui.oldPassword === '' || this.ui.newPassword === '' || this.ui.confirmNewPassword === '';
    }

    doCancelChanges() {
        router.push(RouterPath.ROOT);
    }

    clearForm() {
        this.ui.oldPassword = '';
        this.ui.newPassword = '';
        this.ui.confirmNewPassword = '';
    }

    changeVisibility(): void {
      this.isShowingPassword = !this.isShowingPassword;
    }

    get iconPassword(): string {
        return this.isShowingPassword ? 'visibility_off' : 'visibility';
    }

    get typePassword(): string {
        return this.isShowingPassword ? 'text' : 'password';
    }

    get fullName() {
        if (this.userInfo && this.userInfo.firstName && this.userInfo.lastName) {
          return `${this.userInfo ? `${this.userInfo?.firstName}` : ''}`
          + ' ' + `${this.userInfo?.lastName ? `${this.userInfo?.lastName}` : '' }`;
        }
      return '-';
    }
}
