import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Position, MultiselectResource, TypeEnum } from './bs-multiselect-filter.types';
import { NotificationUtil } from '@/modules/module/util/notification.util';
import { StringUtil } from '@/modules/module/util/string/string.util';
import { Page } from '@/modules/module/model/server/page';


interface UI {
    isDropdownVisible: boolean;
    checkAll: boolean;
    filterText: string;
    wasFiredByMe: boolean;
    inputId: string;
    inputRadioSelected?: number;
    pageList: Page<MultiselectResource>;
    itens: Array<MultiselectResource>;
    waiting: any;
}

@Component({
    template: require('./bs-multiselect-filter.html'),
    // inject: ['$validator'],
})
export class BSMultiselectFilterComponent extends Vue {

    ui: UI = {
        isDropdownVisible: false,
        checkAll: false,
        filterText: '',
        wasFiredByMe: false,
        inputId: '',
        inputRadioSelected: undefined,
        pageList: new Page(),
        itens: [],
        waiting: null
    };

    openDirection = 'below';

    @Prop({ type: Array, default: [] })
    itens!: Array<MultiselectResource>;

    @Prop({ type: String, default: 'Filtro' })
    btnName!: string;

    @Prop()
    name!: string;

    @Prop()
    title!: string;

    @Prop({ type: Boolean, default: false })
    pageable!: boolean;

    @Prop([Function])
    query: any;

    @Prop()
    maxSelect!: number;

    @Prop({ type: Boolean, default: true })
    showChips!: boolean;

    @Prop({ type: Object, default: () => ({ }) })
    position!: Position;

    @Prop({ type: Boolean, default: false })
    showFilter!: boolean;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    @Prop({ type: String, default: TypeEnum.CHECKBOX })
    type!: TypeEnum;

    @Prop({ type: Boolean, default: true })
    canBeNull!: boolean;

    @Prop({ type: Boolean, default: false })
    fullWidth!: boolean;

    @Prop({
    default: () => {
      return { rules: {} };
      },
    })
    validate!: Record<string, any>;

    @Prop({ type: Number, default: null })
    defaultValue!: number;

    @Prop({ type: Number, default: 10 })
    maxWordLength!: number;

    @Prop({ type: String, default: null })
    minDropdownWidth!: string;

    @Prop({ type: Boolean, default: false })
    noneIsAll!: boolean;

    @Prop({ type: Boolean, default: true })
    checkOnFocus!: boolean;

    @Watch('itens', { immediate: true })
    onItensChange(itens: any) {
        this.ui.itens = itens;
        this.selectDefaultValue();

        if (this.isRadio && !this.ui.itens.find(item => item.checked === true) && this.canBeNull) {
            this.ui.inputRadioSelected = undefined;
        }
    }

    created() {
        this.setInputId();
    }

    async mounted() {
        this.findServices();
        this.selectDefaultValue();
    }

    setInputId() {
        if (this.name) {
            this.ui.inputId = this.name;
            return;
        }

        this.ui.inputId = `${StringUtil.normalizeAndRemoveSpaces(this.btnName).toLocaleLowerCase()}`;
    }

    selectDefaultValue() {
        if (!this.isRadio || this.defaultValue === null) {
            return;
        }

        const defaultItemSelected = this.itens.find(item => item.id === this.defaultValue);
        if (defaultItemSelected) {
            this.selectRadio(defaultItemSelected);
        }
    }

    showDropdown() {
        if (this.disabled)
            return;

        this.ui.wasFiredByMe = true;

        // caso esteja abrindo o dropdown, marca como "checked" os itens selecionados
        this.ui.itens.forEach(item => item.checked = item.applied);
        this.adjustPosition();
        this.ui.isDropdownVisible = true;

        this.$nextTick(() => {
            if (this.showFilter || this.pageable) {
                this.removeFilter();
                (this.$refs.filterSearch as HTMLInputElement).focus();
            }
        });

        this.listItemFocus(true);
    }

    toggleDropdownFromButtonName() {
        if (this.ui.wasFiredByMe) {
            this.forceHideDropdown();
            return;
        }
        this.showDropdown();
    }

    forceHideDropdown() {
        this.ui.wasFiredByMe = false;
        this.hideDropdown();
    }

    hideDropdown() {
        this.setTooltip();
        if (!this.ui.wasFiredByMe) {
            this.ui.isDropdownVisible = false;
            return;
        }

        this.ui.wasFiredByMe = false;
    }

    /**
     * Remove o item selecionado
     * @param item
     */
    unselect(item: any) {
        item.applied = false;
        this.$emit('remove', this.selected);
    }

    /**
     * Remove todos os itens selecionados
     */
    unselectAll() {
        this.ui.itens.forEach(item => {
            item.checked = false;
            item.applied = false;
        });
        this.$emit('remove', this.selected);
    }

    /**
     * Remove os itens selecionados
     * @param item
     */
    clear() {
        this.setTooltip();
        if (this.isRadio) {
            this.ui.inputRadioSelected = undefined;
            this.$emit('remove');
        }

        if (this.isCheckbox)
            this.unselectAll();
    }

    /**
     * Marca/desmarca o item como 'checked'
     * @param item
     */
    toggleItemCheck(item: MultiselectResource) {
        if (this.isMaxSelected)
            return;
        item.checked = !item.checked;
    }

    /**
     * Seleciona ou remove todos os itens
     */
    toggleCheckAll() {
        if (!this.isAllChecked) {
            this.ui.itens.forEach(item => item.checked = true);
            return;
        }
        this.ui.itens.forEach(item => item.checked = false);
    }

    /**
     * Emite evento 'change' com os itens que foram selecionados
     */
    apply() {
        this.ui.itens.forEach(item => item.applied = item.checked);
        this.$emit('change', this.selected);
        this.hideDropdown();
    }

    /**
     * Retorna a classe que define a posição que o dropdown deverá ser aberto
     */
    adjustPosition() {
        if (typeof window === 'undefined') return;

        const spaceAbove = this.$el.getBoundingClientRect().top;
        const spaceBelow = window.outerHeight - this.$el.getBoundingClientRect().bottom;
        if (spaceBelow > spaceAbove || this.position && this.position.bottom) {
            this.openDirection = 'below';
            return;
        }
        this.openDirection = 'above';
    }

    removeFilter() {
        this.ui.filterText = '';
        this.findServices();
    }

    checkRadio(item: MultiselectResource) {
        item.checked = true;
        if (this.ui.inputRadioSelected ===  item.id) return;
        this.ui.inputRadioSelected = item.id;
        this.$emit('change', [item]);
    }

    /**
     * Seta o radio selecionado
     * @param value
     */
    selectRadio(item: MultiselectResource) {
        if (!item) return;
        this.checkRadio(item);
        this.forceHideDropdown();
    }

    /**
     * É chamado quando o usuário rola a lista até o final,
     * assim o endpoint será chamado novamente, porém com os resultados da
     * próxima página caso exista.
     */
    async findNextServices() {
        if (!this.ui.pageList.last) {
            this.ui.pageList.number++;
            await this.findServices(true);
        }
    }

    /**
     * Para evitar fazer diversas requisições para cada letra,
     * executa a função após o usuário parar de digitar por 300ms;
     */
    waitToRequest() {
        clearTimeout(this.ui.waiting);
        this.ui.waiting = setTimeout(() => {
            this.findServices();
        }, 300);
    }

    /**
     * Busca os serviços de acordo com a pesquisa do usuário
     * @param concat caso true, concatena o resultado da pesquisa na lista existente
     */
    async findServices(concat?: boolean) {
        try {
            if (!this.pageable || typeof this.query !== 'function') return;
            if (concat) {
                const result = await this.query(this.ui.pageList.number, this.ui.pageList.size, this.ui.filterText);
                this.ui.pageList.content = this.ui.pageList.content.concat(result.content);
                this.ui.pageList.last = result.last;
                this.ui.itens = this.ui.pageList.content;
                return;
            }

            this.ui.pageList = new Page();
            const result = await this.query(this.ui.pageList.number, this.ui.pageList.size, this.ui.filterText);
            this.ui.pageList = result;
            this.ui.itens = this.ui.pageList.content;

        } catch (error) {
            NotificationUtil.exception(error as string);
        }
    }

    /**
     * Emit an event when the scrollbar is at the bottom of list.
     */
    scrollBottomList() {
        if (!this.pageable)
            return;

        const list = this.$refs.list as HTMLElement;
        const scrollAtTheEnd = list.scrollHeight - list.scrollTop === list.clientHeight;
        if (scrollAtTheEnd) {
            this.$emit('scrollEnd', this.ui.filterText);
            this.findNextServices();
        }
    }

    setTooltip() {
        const button = (this.$refs.buttonName as HTMLAnchorElement);
        if (!button) return;
        if (this.showTooltip) {
            button.setAttribute('data-tooltip', StringUtil.striphtml(this.btnNameOrValue));
            return;
        }
        if (button && button.hasAttribute('data-tooltip')) button.removeAttribute('data-tooltip');
    }

    /**
     * Interacao com o teclado de acordo com
     * Guia de praticas do ARIA https://www.w3.org/TR/wai-aria-practices/#radiobutton
     * @param itemIndex index do item que deve ser focado
     * @param onOpen indica que o metodo foi chamado ao abrir o dropdown
     */
    changeItemFocus(itemIndex: number, onOpen = false) {
        const list = this.$refs.listItem;
        if (!list
            || typeof list !== 'object'
            || !Object.getOwnPropertyDescriptor(list, 'length')) {
                return;
            }

        const listArr = list as HTMLLIElement[];
        listArr.forEach(item => {
            item.tabIndex = -1;
            item.removeAttribute('aria-checked');
        });

        const noneSelected = itemIndex === null || !listArr[itemIndex];

        if (onOpen && noneSelected) {
            const item = listArr[0];
            item.tabIndex = 0;

            return;
        }

        if (noneSelected) {
            const item = listArr[0];
            item.tabIndex = 0;
            item.focus();

            if (!this.checkOnFocus) return;
            item.setAttribute('aria-checked', 'true');
            this.checkRadio(this.listItens[0]);

            return;
        }

        const item = listArr[itemIndex];
        item.tabIndex = 0;
        item.focus();

        if (!this.checkOnFocus) return;
        item.setAttribute('aria-checked', 'true');
        this.checkRadio(this.listItens[itemIndex]);
    }

    /**
     * Metodo para focar nos itens do checkbox com as setas
     * @param itemIndex index do item que deve ser focado
     */
    changeItemFocusCheckbox(itemIndex: number) {
        const list = this.$refs.listItem;
        if (!list
            || typeof list !== 'object'
            || !Object.getOwnPropertyDescriptor(list, 'length')
            || itemIndex === null
            || !(list as HTMLLIElement[])[itemIndex]) {
                return;
            }

        (list as HTMLLIElement[])[itemIndex].focus();
    }

    /**
     * Foca no item selecionado ou o primeiro da lista
     * @param onOpen indica que o metodo foi chamado ao abrir o dropdown
     */
    listItemFocus(onOpen = false) {
        if (this.isCheckbox) return;
        if (typeof onOpen !== 'boolean') onOpen = false;

        const selected =
            this.radioSelected
            && this.listItens.findIndex(item => {
                if (this.radioSelected) {
                    return item.id === this.radioSelected.id;
                }
                return false;
            });
        if (selected) {
            this.changeItemFocus(selected, onOpen);
        }
    }

    /**
     * Muda o foco para o item anterior ao item atual
     * @param currentItemIndex index do item atual para que possamos pegar o anterior
     */
    focusPreviousItem(currentItemIndex: number) {
        // Caso seja o primeiro item deve ir ao fim da lista
        const temp = this.$refs.listItem as any;
        const previousItemIndex
            = temp[currentItemIndex - 1] ? currentItemIndex - 1 : this.listItens.length - 1;

        this.$nextTick(() => {
            if (this.isRadio) {
                this.changeItemFocus(previousItemIndex);
                return;
            }
            this.changeItemFocusCheckbox(previousItemIndex);
        });
    }

    /**
     * Muda o foco para o item posterior ao item atual
     * @param currentItemIndex index do item atual para que possamos pegar o proximo
     */
    focusNextItem(currentItemIndex: number) {
        // Caso seja o ultimo item deve retornar ao topo da lista
        const temp = this.$refs.listItem as any;
        const nextItemIndex
            = temp[currentItemIndex + 1] ? currentItemIndex + 1 : 0;

        // Nao retorna ao topo da lista caso seja
        // paginacao e nao tenha carregado todos itens
        if (this.pageable && nextItemIndex === 0 && !this.ui.pageList.last) return;

        this.$nextTick(() => {
            if (this.isRadio) {
                this.changeItemFocus(nextItemIndex);
                return;
            }
            this.changeItemFocusCheckbox(nextItemIndex);
        });
    }

    /**
     * Selecionar o proximo item a lista (botao cancelar)
     * Metodo para permitir a navegacao na lista de checkboxes
     * apenas pelas setas, para seguir um padrao com o radio
     */
    goFowardFromList() {
        this.$nextTick(() => {
            (this.$refs.cancelButton as HTMLElement).focus();
        });
    }

    /**
     * Ir ao item focavel anterior ou fechar o dropdown
     */
    goBackFromList() {
        this.$nextTick(() => {
            if (this.showFilter || this.pageable) {
                (this.$refs.filterSearch as HTMLElement).focus();
                return;
            }

            if (!this.maxSelect) {
                (this.$refs.toggleCheckAll as HTMLElement).focus();
                return;
            }

            this.forceHideDropdown();
        });
    }

    focus() {
        if (!this.$refs || !this.$refs.buttonName) {
            return;
        }

        (this.$refs.buttonName as HTMLAnchorElement).focus();
    }

    // GETTERS
    get listItens() {
        if (this.pageable || !this.ui.filterText)
            return this.ui.itens;

        return this.ui.itens.filter(item =>
            StringUtil.normalize(item.name)
            .toLocaleLowerCase()
            .indexOf(StringUtil.normalize(this.ui.filterText.toLocaleLowerCase())) > -1);
    }

    get isAbove() {
        return this.openDirection === 'above';
    }

    /**
     * Retorna todos os itens que foram selecionados
     */
    get checked() {
        return this.ui.itens.filter(item => item.checked);
    }

    /**
     * Retorna todos os itens que foram selecionados e aplicados ao filtro
     */
    get selected() {
        return this.ui.itens.filter(item => item.applied);
    }

    /**
     * Retorna o input radio selecionado
     */
    get radioSelected() {
        return this.ui.itens.find(item => item.id === this.ui.inputRadioSelected);
    }

    /**
     * Exibe a quantidade de itens selecionados no dropdown
     */
    get textTotalSelected() {
        const total = this.checked.length;
        if (this.noneIsAll && total === 0) {
            return 'Todos selecionados';
        }
        if (total === 1) {
            return `1 selecionado`;
        }
        return `${total} selecionados`;
    }

    get isDropdownVisible() {
        return this.ui.isDropdownVisible;
    }

    /**
     * Retorna false caso algum item não esteja selecionado
     */
    get isAllChecked() {
        return !this.ui.itens.some(item => !item.checked);
    }

    /**
     * Retorna false caso algum item não esteja aplicado
     */
    get isAllSelected() {
        return !this.ui.itens.some(item => !item.applied);
    }

    get isMaxSelected() {
        return this.maxSelect && this.maxSelect === this.checked.length;
    }

    get isCheckbox() {
        return this.type === TypeEnum.CHECKBOX;
    }

    get isRadio() {
        return this.type === TypeEnum.RADIO;
    }

    get isSomeItemSelected() {
        return (this.isCheckbox && !!this.selected.length) || (this.isRadio && !!this.radioSelected);
    }

    /**
     * Retorna o item selecionado,
     * caso tenha mais de 1, retorna o nome + quantidade de itens extras, ex.: 'Nome +4'
     * Caso não tenha nada selecionado, retorna o nome passado para a Prop btnName
     */
    get btnNameOrValue() {
        if (!this.isSomeItemSelected || this.showChips)
            return this.btnName;

        if (this.isRadio && this.radioSelected) {
            return this.radioSelected.name;
        }

        if (this.selected.length === 1) {
            return this.selected[0].name;
        }

        const selected = this.selected[0].name.length > this.maxWordLength ? `${this.selected[0].name.substring(0, this.maxWordLength)}...` : this.selected[0].name;
        const numberExtraItens = this.selected.length - 1;
        return `${selected} +${numberExtraItens}`;
    }

    get showTooltip() {
        return this.isSomeItemSelected && !this.showChips
            && ((this.isRadio && this.radioSelected && this.radioSelected.name.length > this.maxWordLength)
                || (this.selected.length === 1 && this.selected[0].name.length > this.maxWordLength));
    }

    get btnClass() {
        return {
            'mf-disabled': this.disabled,
            'mf-selected': this.isSomeItemSelected,
            'gp-tooltip': this.showTooltip,
            'w-100': this.fullWidth,
        };
    }

    get btnNameClass() {
        return {
            'mw-100': this.fullWidth,
        };
    }

    get dropdownClass() {
        return {
            'show': this.isDropdownVisible,
            'mfd-above': this.isAbove,
        };
    }

    get dropdownStyle() {
        return {
            'min-width': this.minDropdownWidth,
        };
    }

    get showAllChip() {
        return (this.noneIsAll && !this.isSomeItemSelected) || (!this.pageable && this.isAllSelected);
    }
}
