
import { BaseFactory } from '../base.factory';

export class Factory extends BaseFactory {

    private title = 'Auth Email';

    constructor() {
        super(process.env.VUE_APP_API_URL);
    }

    public verifyPassword = async (email: string, password: string) => {
      const result = await this.post('/user/verify-password', {
        email,
        password
      });
      return result;
  }

    public sendEmailVerification = async (newEmail?: string) => {
        const result = await this.post('/user/send-email-verification', {
          newEmail
        });
        return result;
    }

    public verifyEmailToken = async (newEmail?: string, tokenCode?: string) => {
        const result = await this.post('/user/verify-email-token', {
          newEmail,
          tokenCode
        } );
        return result;
    }
}

export const AuthEmailFactory = new Factory();
