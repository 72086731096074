import { CompanyLocation } from '@/modules/module/model/client/company-location-model';
import { LocationModel } from '@/modules/module/model/client/location-model';
import { BaseFactory } from '../base.factory';

class Factory extends BaseFactory {
  constructor() {
    super(process.env.VUE_API_URL);
  }
  public findCities = async () => {
    const result = await this.get('/location');
    const cities: Array<LocationModel> = [];
    result.map((r: { [x: string]: number }) => {
      let cityName = '';
      Object.keys(r).map(key => {
        cityName = key;
      })
      const obj = {
        city: cityName,
        id: r[cityName]
      };
      cities.push(obj);
    })
    return cities as Array<LocationModel>;
  }
  public getCompanyByLocation = async (id: string) => {
    const result = await this.get(`/company/by-location?locationId=${id}`)
    return result as Array<CompanyLocation>;
  }
}

export const LocationFactory = new Factory();
