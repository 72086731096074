export class Util {
  public normalize = (str: string) => {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public removeSpaces = (str: string) => {
      return str.replace(/\s/g, '');
  }

  public normalizeAndRemoveSpaces = (str: string) => {
      const normalized = this.normalize(str);
      return this.removeSpaces(normalized);
  }

  public capitalizeAll(text: string): string {
      if (!text) return '';

      const splitedText = text.toString().toLowerCase().split(' ');

      const convertUpperRule = (text: string) => {
          const haveNumber = () => {
              return /\d/.test(text);
          };

          const isState = () => {
              text = text.toUpperCase();
              const states = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
              return states.indexOf(text) > -1;
          };

          return haveNumber() || isState();
      };

      const convertLowerRule = (text: string) => {
          const isArtigo = () => {
              text = text.toLowerCase();
              const states = ['da', 'de', 'do', 'das', 'dos', 'e', 'x'];
              return states.indexOf(text) > -1;
          };

          return isArtigo();
      };

      return splitedText
          .map(text => {
              if (convertUpperRule(text)) {
                  return text.toUpperCase();
              } else if (convertLowerRule(text)) {
                  return text.toLowerCase();
              }
              return text.charAt(0).toUpperCase() + text.slice(1);
          })
          .join(' ');
  }

  public striphtml = (text: string) => {
      const div = document.createElement('div');
      div.innerHTML = text;
      return div.textContent || div.innerText || '';
  }
}

export const StringUtil = new Util();
