import { Component, Prop, Vue } from 'vue-property-decorator';
import { StringUtil } from '../../../modules/module/util/string/string.util';
import { RouterPath } from '@/enums/router-path.enum';
import { Menu } from './types/menu';
import { MenuItem } from './types/menu-item';
import { AuthenticationService } from '@/modules/module-authentication/authentication.service';
import { ApplicationConfigService } from '@/modules/module/services/application-config/application-config.service';
import { AmplitudeService } from '../../../modules/module/services/amplitude/amplitude.service';
import { UserModel } from '@/modules/module/model/client/user-model';
import { UserFactory } from '@/modules/factory/users/users.factory';
import { UserAuth } from '@/modules/module/model/client/user-auth';
import { MultiCompanyService } from '@/modules/module/services/multi-company/multi-company.service';
interface UI {
  showRouteDropdown: boolean;
  userPermissions: any;
  sidebarPosition: any;
  status: {
    sidebarExpand: boolean;
  };
  menuOpened: string;
  menuFilter: string;
  menus: Array<Menu>;
  paths: object;
  user?: UserModel;
  counter: number;
  userAuth?: UserAuth;
}

@Component({
  template: require('./bs-sidebar.html'),
})
export class BSSidebarComponent extends Vue {
  ui: UI = {
    showRouteDropdown: false,
    userPermissions: {},
    sidebarPosition: null,
    status: {
      sidebarExpand: false,
    },
    menuOpened: '',
    menuFilter: '',
    menus: [],
    paths: { HOME: RouterPath.ROOT, FUNCTIONALITIES: RouterPath.FUNCTIONALITIES },
    user: undefined,
    counter: 0,
    userAuth: undefined,
  };

  @Prop(Boolean)
  sidebarCompressed!: boolean;

  async created() {
    this.ui.userAuth = AuthenticationService.user;

    if (!this.hasSefazGroup) {
      this.ui.user = await UserFactory.getUserById(AuthenticationService.user.id);
    }

    this.initializeMenu();
  }

  mounted() {
    setTimeout(() => {
      this.initializeMenu();
      this.ui.menus.forEach(menu => {
        if (this.hasAtLeastOnePermission(menu.menuItems)) {
          const menuGroupRef = this.$refs[menu.group] as any[];
          if (menuGroupRef) this.adjustSubmenuPosition((menuGroupRef[0] as HTMLElement ));
        }
      });
      this.refreshMenu();
    }, 1000);
  }

  isSefazPermission() {
    if (this.ui.userAuth) {
      const found = this.ui.userAuth.groups?.find(g => g === "SEFAZ");
      return !!found;
    }
    return false;
  }

  get hasSefazGroup() {
    return this.isSefazPermission();
  }

  async initializeMenu() {
    const company = MultiCompanyService.defaultCompany(await MultiCompanyService.userCompanies());

    this.ui.menus = [
      {
        group: 'sefaz',
        name: 'Menu Fiscal',
        icon: 'receipt_long',
        new: false,
        menuItems: [
          {
            to: RouterPath.TRIP_CLOSING,
            isExternalLink: false,
            name: 'Fechamento da Viagem',
            hasPermission: this.isSefazPermission(),
          } as MenuItem,
          {
            to: RouterPath.PAF_BPE_IDENTIFICATION,
            isExternalLink: false,
            name: 'Identificação do PAF-BP-e',
            hasPermission: this.isSefazPermission(),
          } as MenuItem,
          {
            to: RouterPath.PAF_BPE_REGISTERS,
            isExternalLink: false,
            name: 'Registros do PAF-BP-e',
            hasPermission: this.isSefazPermission(),
          } as MenuItem,
        ],
      },

      {
        group: 'guiche-pass',
        name: 'Administrativo',
        image: {
          active: '/assets/img/inventario.svg',
          inactive: '/assets/img/inventario.svg'
        },
        new: false,
        menuItems: [
          {
            to: `${ApplicationConfigService.urlGpassAdmin}/#/resultados-de-vendas?companyCnpj=${company?.cnpj ?? ''}`,
            isExternalLink: true,
            name: 'Resultado de vendas',
            hasPermission: !this.hasSefazGroup,
          } as MenuItem,
          {
            to: `${ApplicationConfigService.urlGpassAdmin}/#/caixa?companyCnpj=${company?.cnpj ?? ''}`,
            isExternalLink: true,
            name: 'Caixa',
            hasPermission: !this.hasSefazGroup,
          } as MenuItem,
          {
            to: `${ApplicationConfigService.urlGpassAdmin}/#/dashboard-financeiro?companyCnpj=${company?.cnpj ?? ''}`,
            isExternalLink: true,
            name: 'Dashboard financeiro',
            hasPermission: !this.hasSefazGroup,
          } as MenuItem,
        ],
      },

      {
        group: 'marketplace',
        name: 'Marketplace',
        icon: 'add_shopping_cart',
        new: true,
        menuItems: [
          {
            to: RouterPath.CHECK_TICKETS,
            isExternalLink: false,
            name: 'Consultar passagens - BP-e <span class="bs-badge badge-label badge-orange">Novo</span>',
            hasPermission: !this.hasSefazGroup,
          } as MenuItem,
          {
            to: RouterPath.INVOICE_MARKETPLACE_DETAIL,
            isExternalLink: false,
            name: 'Extrato de vendas',
            hasPermission: this.enableMarketplace() && !this.hasSefazGroup,
          } as MenuItem,
          {
            to: RouterPath.ACCOUNT + '/?marketplace=true',
            name: 'Faturas e notas',
            hasPermission: !this.hasSefazGroup,
          } as MenuItem,
          {
            // to: `${ApplicationConfigService.urlMarketplace}/relatorio`,
            to: RouterPath.DASHBOARD,
            isExternalLink: false,
            name: 'Relatórios e dashboard',
            hasPermission: this.enableMarketplace() && !this.hasSefazGroup,
          } as MenuItem,
          {
            to: RouterPath.PERMISSIONS,
            isExternalLink: false,
            name: 'Permissões',
            hasPermission: this.isAllowedPermission() && !this.hasSefazGroup,
          } as MenuItem,
        ],
      },

      {
        group: 'Conta Digital',
        name: 'Conta digital',
        icon: 'account_balance',
        new: false,
        menuItems: [
          {
            to: this.hasFitBankAccount() ? RouterPath.DIGITAL_ACCOUNT_LOGIN : RouterPath.ACCOUNT_OPENING,
            isExternalLink: false,
            name: this.hasFitBankAccount() ? 'Acessar conta' : 'Abrir Conta',
            hasPermission: !this.hasSefazGroup,
          } as MenuItem,
        ]
      },

      {
        group: 'Minha conta',
        name: 'Minha conta',
        icon: 'person',
        new: false,
        menuItems: [
            { to: RouterPath.ACCOUNT, name: 'Minha conta', hasPermission: !this.hasSefazGroup, } as MenuItem,
            { to: RouterPath.FUNCTIONALITIES, name: 'Funcionalidades ativas', hasPermission: !this.hasSefazGroup, } as MenuItem,
            { to: RouterPath.ROADMAP, name: 'Novas funcionalidades', hasPermission: !this.hasSefazGroup, new: true } as MenuItem,
        ]
      },

      {
        group: 'LGPD',
        name: 'LGPD',
        icon: 'gpp_good',
        new: false,
        menuItems: [
            { to: RouterPath.LGPD, name: 'Proteção de dados pessoais', hasPermission: !this.hasSefazGroup, } as MenuItem,
        ]
      },

      {
        group: 'Novidades e Histórico',
        name: 'Novidades e Histórico',
        icon: 'star',
        new: false,
        menuItems: [
            { to: RouterPath.NOTIFICATION_HISTORY, name: 'Novidades e Histórico', hasPermission: !this.hasSefazGroup, } as MenuItem,
        ]
      },
    ];
  }

  async logout() {
    AuthenticationService.logout();
  }

  filter() {
    if (this.isSidebarExpand)
      return;

    this.toggleExpand();
    AmplitudeService.logEvent('sidebar_search');
  }

  clearFilter() {
    this.toggleExpand();
  }

  get menus() {
    if (!this.ui.menuFilter)
      return this.ui.menus;

    const filter = StringUtil.normalize(this.ui.menuFilter.toLowerCase());

    return this.ui.menus.reduce((list: Menu[], menu) => {
      const filteredInner = menu.menuItems.filter(item =>
        StringUtil.normalize(item.name.toLowerCase()).includes(filter)
      );

      if (filteredInner.length < 1 && !menu.name.toLowerCase().includes(filter))
        return list;

      const tempMenu: Menu = {
        name: menu.name,
        group: menu.group,
        icon: menu.icon,
        menuItems: filteredInner.length ? filteredInner : menu.menuItems
      };

      list.push(tempMenu);
      return list;
    }, []);
  }

  enableMarketplace() {
    return !!ApplicationConfigService.urlMarketplace;
  }

  hasAtLeastOnePermission(sidebarItem: Array<any>) {
    return sidebarItem.some(item => item.hasPermission);
  }

  subIsActive(menuItems: Array<MenuItem>) {
    return menuItems.some(menu => !!menu.to && this.$route.path.indexOf(menu.to) === 0); // current path starts with this path string
  }

  get isSidebarExpand() {
    return this.sidebarCompressed;
  }

  toggleExpand() {
    this.ui.menuOpened = '';
    this.$emit('on-toggle-action-expand', !this.sidebarCompressed);

    this.logAmplitudeSidebarExpand();
  }

  get setSidebarClass() {
    return this.sidebarCompressed ? 'sidebar-expanded' : 'sidebar-compressed';
  }

  onClickOutside() {
    this.ui.menuFilter = '';
    this.$emit('on-toggle-action-expand', false);
  }

  isMenuOpen(menu: string) {
    // Se existe um filtro aplicado, ele exibe os menus abertos
    if (this.ui.menuFilter) {
      return true;
    }

    return this.ui.menuOpened === menu;
  }

  setSubmenuClass(menuItems?: Array<MenuItem>) {
    if (menuItems === undefined || menuItems === null) {
      return '';
    }
    return this.subIsActive(menuItems) ? 'smi-active' : '';
  }

  expandSubmenu(menu: string) {
    if (!this.isSidebarExpand) return;

    if (this.ui.menuOpened && this.ui.menuOpened === menu) {
      this.ui.menuOpened = '';
      return;
    }

    this.ui.menuOpened = menu;

    this.logAmplitudeSubmenuExpand();
  }

  getMenuImageImage(menu: any) {
    if (this.subIsActive(menu.menuItems)) {
      return menu.image.active;
    }
    return menu.image.inactive;
  }

  getMenuName(menuName: string) {
    return `${menuName} <i class="material-icons smi-icon">keyboard_arrow_down</i>`;
  }

  adjustSubmenuPosition(submenu: HTMLElement) {
    if (typeof window === 'undefined') return;

    const submenuPosition = submenu.getBoundingClientRect();
    const spaceBelow = window.innerHeight - submenuPosition.top;

    // Desloca o submenu para cima de acordo com o tamnho que ficou fora da tela (com um espaço extra de 16px)
    if (spaceBelow < submenu.offsetHeight) {
      submenu.style.top = `-${submenu.offsetHeight - spaceBelow + 16}px`;
      return;
    }

    submenu.style.top = submenu.getBoundingClientRect().top.toString();
  }

  hasFitBankAccount() {
    return ApplicationConfigService.hasFitBankAccount();
  }

  onMouseOver() {
      this.$emit('on-sidebar-hover', true);
  }

  onMouseLeave() {
      this.$emit('on-sidebar-hover', false);
  }

  /********************************************************************
    AMPLITUDE - EVENTOS DO GRUPO SIDEBAR
  *********************************************************************/
  // Mapear rotas clicadas pela sidebar
  logAmplitudeSidebarRoutes(route: string) {
    try {
      AmplitudeService.logEvent('sidebar_page_selected', {
          page: route,
      });
    } catch (error) {
        console.error('Amplitude event error: sidebar_page_selected');
    }
  }

  // Mapear expandir/comprimir sidebar
  logAmplitudeSidebarExpand() {
    try {
      AmplitudeService.logEvent('sidebar_expand_toggle', {
          status: !this.sidebarCompressed ? 'opened' : 'closed',
      });
    } catch (error) {
        console.error('Amplitude event error: sidebar_expand_toggle');
    }
  }

  // Mapear expansão de um grupo da sidebar
  logAmplitudeSubmenuExpand() {
    try {
        AmplitudeService.logEvent('sidebar_group_expanded', {
            group: this.ui.menuOpened,
        });
    } catch (error) {
        console.error('Amplitude event error: sidebar_group_expanded');
    }
  }

  refreshMenu() {
    if(this.ui.counter > 10) {
      if(this.ui.user) {
        this.initializeMenu();
      }
      return;
    }
    if (ApplicationConfigService.urlSuite === '') {
      this.ui.counter++;
      setTimeout(() => {
          if (ApplicationConfigService.urlSuite !== '')
              this.initializeMenu();
          else
              this.refreshMenu();
      }, 1000);
    } else if (ApplicationConfigService.urlMarketplace === '') {
      this.ui.counter++;
      setTimeout(() => {
          if (ApplicationConfigService.urlMarketplace !== '')
              this.initializeMenu();
          else
              this.refreshMenu();
      }, 1000);
    } else if(this.ui.user === undefined) {
      this.ui.counter++;
      setTimeout(() => {
          if (this.ui.user)
              this.initializeMenu();
          else
              this.refreshMenu();
      }, 1000);
    }
  }

  isAllowedPermission() {
    if (this.ui.user) {
      return this.ui.user.groups?.find(g => g === "MARKETPLACE_ADMIN" || "MARKETPLACE_OWNER");
    } else {
      this.refreshMenu();
      return false;
    }
  }
}
