import orderBy from 'lodash/orderBy';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface Data {
    value: any;
    itens: Array<any>;
    text: string;
    enabled: boolean;
    canDisable: boolean;
    pointer: number;
    loading: boolean;
    readonly: boolean;
}

@Component({
    template: require('./bs-selector.html'),
    // inject: ['$validator'],
})
export class BSSelectorComponent extends Vue {
    data: Data = {
        value: undefined,
        itens: new Array<any>(),
        text: '',
        enabled: false,
        canDisable: true,
        pointer: 0,
        loading: false,
        readonly: false,
    };

    openAbove = false;

    @Prop([String, Object, Array, Number, Boolean])
    value: any;

    @Prop({ type: Array })
    itens!: Array<any>;

    @Prop({ type: Function })
    query!: Function;

    @Prop([String, Function])
    label: any;

    @Prop({ type: Boolean, default: false })
    queryOnlyOnce!: boolean;

    @Prop({ type: Boolean, default: false })
    clear!: boolean;

    @Prop({ type: Boolean, default: false })
    clearAfter!: boolean;

    @Prop({ type: Boolean, default: false })
    nullable!: boolean;

    @Prop({ type: Boolean, default: false })
    onlyAutoComplete!: boolean;

    @Prop({
        default: () => {
            return { rules: {} };
        },
    })
    validate!: object;

    @Prop(String)
    scope!: string;

    @Prop(String)
    outputAs!: string;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    @Prop()
    required!: string;

    @Prop(String)
    name!: string;

    @Prop(String)
    id!: string;

    @Prop(String)
    inputLabel!: string;

    @Prop(String)
    inputClass!: string;

    @Prop(String)
    placeholder!: string;

    @Prop({ type: Boolean, default: false })
    dontFilter!: boolean;

    @Prop({ type: Boolean, default: true })
    showListOnClick!: boolean;

    @Prop(Number)
    limit!: number;

    @Prop({ type: Boolean, default: false })
    readonly!: boolean;

    @Prop({ type: Boolean, default: false })
    openFullScreen!: boolean;

    // Ordenado pelo 'label'
    @Prop({ type: Boolean, default: true })
    ordered!: boolean;

    @Prop({ type: Boolean, default: false })
    disableMountedChange!: boolean;

    mounted() {
        if (this.itens) {
            this.data.itens = this.itens;
        } else if (this.query) {
            this.doQuery();
        }
        this.selectItemExternal(true);
    }

    @Watch('query')
    async onQueryChange() {
        this.selectItem(undefined);
        this.doQuery();
    }

    @Watch('itens')
    onItensChange(val: any) {
        if (val) {
            this.data.itens = val;
        } else {
            this.data.itens = new Array<any>();
        }
    }

    @Watch('value')
    onValueChange(val: any) {
        if (this.isDiferentItem(val)) {
            this.selectItemExternal();
        }
    }

    @Watch('clear')
    onValueClear() {
        if (this.clear) {
            this.selectItem(undefined);
        }
    }

    async doQuery() {
        this.data.loading = true;
        this.data.itens = await this.query(this.data.text);
        this.data.loading = false;
    }

    onTextChanged(val: any) {
        // Caso tenha apagado o texto, irá resetar o campo
        if (!val) {
            this.selectItem(undefined);
        } else {
            if (this.query && !this.queryOnlyOnce) {
                this.doQuery();
            }
            if (this.isOnlyAutoComplete()) {
                this.selectItem(this.data.text);
            }
        }
    }

    onTextFieldBlur() {
        if (this.canDisable()) {
            this.disable();
        }
    }

    onClickOutside() {
        this.disable();
    }

    get hasItemSelected() {
        return !!this.data.value;
    }

    get selectedItem() {
        return this.data.text ? this.data.text : this.inputPlaceholder;
    }

    isEnabled() {
        return this.data.enabled;
    }

    canDisable() {
        return this.data.canDisable;
    }

    canDeselect() {
        return !this.isOnlyAutoComplete() && this.isNullable();
    }

    isNullable() {
        return this.nullable;
    }

    isOnlyAutoComplete() {
        return this.onlyAutoComplete;
    }

    isPointerAt(index: any) {
        return this.data.pointer === index;
    }

    isItemSelected(item: any) {
        return this.data.value === item;
    }

    isAnyItemSelected() {
        return !!this.data.value;
    }

    enable(clicked?: boolean) {
        this.adjustPosition();

        if (clicked) {
            this.data.enabled = this.showListOnClick && !this.disabled;
        } else {
            this.data.enabled = true && !this.disabled;
        }
    }

    disable() {
        this.data.enabled = false;
        this.enableDisable();
    }

    enableDisable() {
        this.data.canDisable = true;
    }

    disableDisable() {
        this.data.canDisable = false;
    }

    isDiferentItem(item: any) {
        if (item && this.data.value && this.outputAs && this.data.value[this.outputAs] === item) {
            return false;
        }
        if (item && this.data.value === item) {
            return false;
        }
        return true;
    }

    setInternalValue(item: any) {
        if (item && this.outputAs && this.isTypeOf(item, ['string'])) {
            this.data.value = this.itens.find(x => x[this.outputAs] === item);
            this.data.text = this.getItemLabel(item);
            this.$emit('input', this.data.value);
            return;
        }

        if (item && this.outputAs) {
            this.data.value = item;
            this.data.text = this.getItemLabel(item);
            this.$emit('input', this.data.value[this.outputAs]);
            return;
        }

        this.data.value = item;
        if (typeof item === 'string') this.data.text = item;
        this.$emit('input', this.data.value);
    }

    selectItem(item: any, disableChange = false) {
        if (this.isOnlyAutoComplete() && item && !this.isTypeOf(item, ['string', 'number', 'boolean'])) {
            const autoCompleteItem = this.getItemLabel(item, false);
            this.data.text = autoCompleteItem;
            this.setInternalValue(autoCompleteItem);
            if (!disableChange) {
                this.$emit('change', this.data.text, item);
            }
            return;
        }

        if (this.isOnlyAutoComplete()) {
            this.setInternalValue(this.data.text);
            if (!disableChange) {
                this.$emit('change', this.data.text);
            }
            return;
        }

        if (item && !this.isTypeOf(item, ['string', 'number', 'boolean', String, Number, Boolean])) {
            this.data.text = this.getItemLabel(item, false);
            this.setInternalValue(item);
            if (!disableChange) {
                this.$emit('change', item);
            }
            return;
        }

        this.data.text = '';
        this.setInternalValue(item);
        if (!disableChange) {
            this.$emit('change', item);
        }
    }

    selectItemExternal(verifyDisableMountedChanged = false) {
        const disableChange = verifyDisableMountedChanged && this.disableMountedChange;

        if (this.outputAs) {
            this.selectItem(this.data.itens.find(x => x[this.outputAs] === this.value), disableChange);
        } else {
            this.selectItem(this.value, disableChange);
        }
    }

    selectItemByIndex(index: any) {
        this.selectItem(this.getItensByText(this.data.text)[index]);
    }

    pointerForward() {
        if (this.getItensByText(this.data.text).length - 1 > this.data.pointer) {
            this.data.pointer++;
        }
        this.$forceUpdate();
    }

    pointerBackward() {
        if (-1 < this.data.pointer) {
            this.data.pointer--;
        }
        this.$forceUpdate();
    }

    getItensByText(text: string) {
        const filtered = this.filter(text, this.data.itens);
        const ordered = this.order(filtered);

        if (this.limit) return ordered.slice(0, this.limit);

        return ordered;
    }

    get inputPlaceholder() {
        if (this.data.value) {
            return this.getItemLabel(this.data.value);
        }

        return this.placeholder;
    }

    getItemLabel(item: any, highlight?: boolean) {
        let labelResult: any;
        if (!item) {
            return '';
        }

        if (this.label === undefined) {
            return item;
        }

        if (this.isTypeOf(this.label, [String, 'string'])) {
            labelResult = item[this.label as string];
            return highlight ? this.highlight(labelResult, this.data.text) : labelResult;
        }

        labelResult = this.label(item);
        if (this.isTypeOf(labelResult, [String, 'string'])) {
            return highlight ? this.highlight(labelResult, this.data.text) : labelResult;
        }

        return highlight ? this.highlight(labelResult.label, this.data.text) : labelResult.key;
    }

    // Retorna TRUE se o tipo do item for qualquer um dos tipos passado no array
    isTypeOf(item: any, types: Array<any>) {
        let isTypeOf = false;

        types.forEach(type => {
            // verifica se o tipo é primitivo ou objeto
            if (typeof type === 'function') {
                // Se for "objeto", valida com instanceof
                if (item instanceof type) {
                    isTypeOf = true;
                }

                // Se for "primitivo", valida com typeof
            } else if (typeof item === type) {
                isTypeOf = true;
            }
        });
        return isTypeOf;
    }

    adjustPosition() {
        if (this.openFullScreen || typeof window === 'undefined') return;

        const spaceAbove = this.$el.getBoundingClientRect().top;
        const spaceBelow = window.innerHeight - this.$el.getBoundingClientRect().bottom;
        this.openAbove = spaceAbove > spaceBelow;
    }

    get listPositionClass() {
        return {
            above: this.openAbove,
            'full-screen': this.openFullScreen,
        };
    }

    get getInputClass() {
        const classObject = ['form-control', 'w-100'];

        if (this.inputClass) {
            classObject.push(this.inputClass);
        }

        return classObject;
    }

    filter(text: string, itens: Array<any>) {
        if (!itens || !text || this.dontFilter) return itens;

        return itens.filter(
            x => this.prepareCompare(this.getItemLabel(x, true)).indexOf(this.prepareCompare(text)) > -1
        );
    }

    order(itens: Array<any>) {
        if (!this.ordered) return itens;

        return orderBy(itens, (item: any) => this.getItemLabel(item), ['asc']);
    }

    highlight(label: any, query: any, subStringStart?: any): any {
        if (!label || !query) {
            return label;
        }

        const labelPrepared = this.prepareCompare(label);
        const queryPrepared = this.prepareCompare(query);

        if (labelPrepared.substring(subStringStart).indexOf(queryPrepared) < 0) {
            return label;
        }

        const start = labelPrepared.substring(subStringStart).indexOf(queryPrepared) + (subStringStart || 0);
        const end = start + queryPrepared.length;

        if (this.isInsideTag(labelPrepared, end) !== undefined) {
            return this.highlight(label, query, this.isInsideTag(labelPrepared, end));
        }

        const highlighted = '<b>' + label.substring(start, end) + '</b>';
        return label.substring(0, start) + highlighted + label.substring(end);
    }

    isInsideTag(text: any, start: any) {
        let endTag = undefined;
        let startCount = start;
        while (startCount <= text.length) {
            const char = text[startCount];
            if (char === '<') {
                break;
            } else if (char === '>') {
                endTag = startCount;
                break;
            }
            startCount++;
        }
        return endTag;
    }

    prepareCompare(text: any) {
        if (!text) {
            return '';
        }
        return this.removeAccents(text)
            .toLowerCase()
            .trim();
    }

    removeAccents(text: any) {
        if (!text) {
            return '';
        }

        const helperRemoveAccents_map: any = {
            Ã: 'A',
            Â: 'A',
            Á: 'A',
            ã: 'a',
            â: 'a',
            á: 'a',
            à: 'a',
            É: 'E',
            Ê: 'E',
            È: 'E',
            é: 'e',
            ê: 'e',
            è: 'e',
            Í: 'I',
            Î: 'I',
            Ì: 'I',
            î: 'I',
            í: 'i',
            ì: 'i',
            Ô: 'O',
            Õ: 'O',
            Ó: 'O',
            Ò: 'O',
            ô: 'o',
            õ: 'o',
            ó: 'o',
            ò: 'o',
            Ú: 'U',
            Ù: 'U',
            ú: 'u',
            ù: 'u',
            ç: 'c',
        };
        return text.replace(/[^A-Za-z0-9[\] ]/g, function (a: any) {
            return helperRemoveAccents_map[a] || a;
        });
    }

    get fakeOrDisabledClass() {
        return {
            'selector-fake-input': !this.hasItemSelected,
            disabled: this.disabled,
        };
    }

    setFocus() {
        const element = this.$refs.input as HTMLInputElement;
        if (element) {
            element.focus();
        }
    }

    get nameAndIdForAutocompleteOff() {
        return `${this.name}-${new Date().getTime()}`;
    }
}
