import { BSFormBuilderInput, BSFormBuilderInputGPDateCatcher, BSFormBuilderInputGPSelector, BSFormBuilderInputText } from "./bs-form-builder.types";

class Util {

    isGPFormBuilderInputGPSelector(input: BSFormBuilderInput) {
        return input instanceof BSFormBuilderInputGPSelector;
    }

    isGPFormBuilderInputGPDateCatcher(input: BSFormBuilderInput) {
        return input instanceof BSFormBuilderInputGPDateCatcher;
    }

    isGPFormBuilderInputText(input: BSFormBuilderInput) {
        return input instanceof BSFormBuilderInputText;
    }

}

export const BSFormBuilderUtil = new Util();
