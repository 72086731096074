import { ArrayUtil } from '@/modules/module/util/array/array.util';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface UI {
    enabled: boolean;
    calendarDate: moment.Moment | undefined | null;
    viewType: ViewType;
    containerStyle: any;
    inputValue: string;
    lastDate: string;
}

enum ViewType {
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

interface Position {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
}

@Component({
    // inject: ['$validator'],
})
export default class BSDateCatcherComponent extends Vue {
    ui: UI = {
        enabled: false,
        calendarDate: undefined,
        viewType: ViewType.DAY,
        containerStyle: {},
        inputValue: '',
        lastDate: '',
    };

    openAbove!: boolean;
    openLeft!: boolean;

    @Prop([String, Date])
    value!: string | Date;

    @Prop({ type: String, default: 'YYYY-MM-DD' })
    label!: string;

    @Prop({ type: String, default: 'YYYY-MM-DD' })
    format!: string;

    @Prop({ type: Boolean, default: false })
    nullable!: boolean;

    @Prop({
    default: () => {
      return { rules: {} };
    },
    })
    validate!: any;

    @Prop(String)
    scope!: string;

    @Prop({type: String, default: 'id'})
    id!: string;

    @Prop(String)
    name!: string;

    @Prop(String)
    inputClass!: string;

    @Prop(String)
    placeholder!: string;

    @Prop({ type: Boolean, default: false })
    hideInput!: boolean;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    // Parâmetros para ocultar os dias do calendário
    // Parâmetros para ocultar os dias do calendário

    @Prop({ type: Boolean, default: false })
    hideDays!: boolean;

    @Prop({ type: Boolean, default: false })
    hideTodayText!: boolean;

    @Prop({ type: Object })
    position!: Position;

    /**
     * Boolean força a atualização das datas, mesmo quando ela já está selecionada
     * @type {Boolean}
     * @default false
     */
    /**
   * Boolean força a atualização das datas, mesmo quando ela já está selecionada
   * @type {Boolean}
   * @default false
   */

    @Prop({ type: Boolean, default: false })
    forceUpdate!: boolean;

    /**
     * Moment data mínima que pode ser selecionada
     * @type {Boolean}
     */
    /**
   * Moment data mínima que pode ser selecionada
   * @type {Boolean}
   */

    @Prop()
    minDate!: moment.Moment;

    /**
     * Moment data máxima que pode ser selecionada
     * @type {Boolean}
     */
    /**
   * Moment data máxima que pode ser selecionada
   * @type {Boolean}
   */

    @Prop()
    maxDate!: moment.Moment;

    /**
     * Boolean controla quando o calendário deve ser exibido
     * @type {Boolean}
     */
    /**
   * Boolean controla quando o calendário deve ser exibido
   * @type {Boolean}
   */

    @Prop()
    showCalendar!: boolean;

    created() {
        this.initialize();
        if (this.hideDays) {
            this.ui.viewType = ViewType.MONTH;
        }
    }

    // mounted() {
    //     (this as any).$validator.reset();
    // }

    @Watch('value')
    onValueChange(val: string | Date) {
        const newDate = val ? moment(val, this.format) : undefined;
        this.setCalendarDate(newDate!);

        if (!newDate) {
            this.clearDate(true);
            return;
        }

        this.setInputValue(newDate);
    }

    /**
     * Verifica se o valor externo é diferente do interno quando o calendário é exibido
     * Caso seja diferente, atualiza o interno
     */
    @Watch('showCalendar')
    onShowCalendarChange() {
        this.adjustPosition();

        if (!!this.value && !!this.ui.inputValue && this.value !== this.ui.inputValue)
            this.initialize();
    }

    initialize() {
        const newDate = this.value ? moment(this.value, this.format) : undefined;
        this.setCalendarDate(newDate!);

        if (!newDate) {
            this.clearDate(true);

        } else {
            this.setInputValue(newDate);
        }
    }

    async onTextChanged() {
        this.setCalendarDate(this.ui.inputValue ? this.dateFromText(this.ui.inputValue) : undefined);
    }

    dateFromText(text: string) {
        return moment(text, this.label);
    }

    enable() {
        this.adjustPosition();
        this.ui.enabled = true;
    }

    disable() {
        if (!this.isEnabled) return;

        if (this.hideInput) {
            this.$emit('disabled', this.name);
            return;
        }

        this.ui.enabled = false;
    }

    doIncreaseDate() {
        switch (this.ui.viewType) {
            case ViewType.DAY: {
                this.doIncreaseMonth();
                break;
            }
            case ViewType.MONTH: {
                this.doIncreaseYear();
                break;
            }
            case ViewType.YEAR: {
                this.doIncreaseDecade();
                break;
            }
        }
    }

    doDecreaseDate() {
        switch (this.ui.viewType) {
            case ViewType.DAY: {
                this.doDecreaseMonth();
                break;
            }
            case ViewType.MONTH: {
                this.doDecreaseYear();
                break;
            }
            case ViewType.YEAR: {
                this.doDecreaseDecade();
                break;
            }
        }
    }

    doIncreaseMonth() {
        this.ui.calendarDate?.add(1, 'month');
        this.$forceUpdate();
    }

    doDecreaseMonth() {
        this.ui.calendarDate?.subtract(1, 'month');
        this.$forceUpdate();
    }

    doIncreaseYear() {
        this.ui.calendarDate?.add(1, 'year');
        this.$forceUpdate();
    }

    doDecreaseYear() {
        this.ui.calendarDate?.subtract(1, 'year');
        this.$forceUpdate();
    }

    doIncreaseDecade() {
        this.ui.calendarDate?.add(10, 'year');
        this.$forceUpdate();
    }

    doDecreaseDecade() {
        this.ui.calendarDate?.subtract(10, 'year');
        this.$forceUpdate();
    }

    isDateSelected(date: moment.Moment) {
        if (!this.ui.inputValue) return false;

        return date.format(this.format) === moment(this.ui.inputValue, this.label).format(this.format);
    }

    isDateToday(date: moment.Moment) {
        return !this.isDateOutOfCurrentMonth(date) && date.format(this.format) === moment().format(this.format);
    }

    isDateOutOfCurrentMonth(date: moment.Moment) {
        return date.format('YYYY/MM') !== moment(this.ui.calendarDate).format('YYYY/MM');
    }

    onCalendarSelect(newDate: moment.Moment) {
        if (!this.isSelectableDate(newDate)) {
            return;
        }
        this.setCalendarDate(newDate);
        this.setInputValue(newDate);
        this.disable();
    }

    changeMonth(month: string) {
        const newDate = cloneDeep(this.ui.calendarDate)?.month(month);
        if (!this.isSelectableDate(newDate)) {
            return;
        }
        this.ui.calendarDate?.month(month);
        this.setCalendarDate(newDate);
        this.setInputValue(newDate);
        this.disable();
    }

    changeYear(year: number) {
        const newDate = cloneDeep(this.ui.calendarDate)?.year(year);
        if (!this.isSelectableDate(newDate)) {
            return;
        }
        this.ui.calendarDate?.year(year);
        this.setCalendarDate(newDate);
        this.setInputValue(newDate);
        this.disable();
    }

    clearDate(forceInputNull?: boolean) {
        this.setCalendarDate(null!);
        if (forceInputNull) {
            this.ui.inputValue = '';
            this.ui.lastDate = '';
            this.emitChange();
            return;
        }
        this.setDefaultValue();
    }

    setCalendarDate(newDate: moment.Moment | undefined | null) {
        if (newDate && newDate.isValid()) {
            this.ui.calendarDate = newDate;
            return;
        }

        this.ui.calendarDate = moment();
    }

    /**
     * Seta a data de acordo com a data digitada pelo usuário
     */
    setInputValue(newDate?: moment.Moment) {
        if (!newDate && !this.ui.inputValue) {
            this.setDefaultValue();
            return;
        }

        if (!newDate) newDate = moment(this.ui.inputValue, this.label);

        // Se não for uma data válida
        if (!newDate.isValid()) {
            this.setDefaultValue();
            return;
        }

        const newDateFormated = newDate.format(this.label);
        if (!this.forceUpdate && this.ui.lastDate === newDateFormated) return;

        this.ui.inputValue = newDateFormated;
        this.ui.lastDate = this.ui.inputValue;
        this.emitChange(newDate);
    }

    /**
     * Valor default do input
     * Seta como nulo se o atributo nullable for true
     * Caso contrário, seta a última data informada
     */
    setDefaultValue() {
        if (!this.isNullable) {
            this.ui.inputValue = this.ui.lastDate;
            return;
        }

        this.ui.inputValue = '';
        this.ui.lastDate = '';
        this.emitChange();
        return;
    }

    emitChange(newDate?: moment.Moment) {
        if (!newDate) {
            this.$emit('change', null);
            this.$emit('input', null);
            return;
        }
        this.$emit('change', newDate.format(this.format));
        this.$emit('input', newDate.format(this.format));
    }

    setViewType(viewType: ViewType) {
        this.ui.viewType = viewType;
    }

    isViewType(viewType: ViewType) {
        return this.ui.viewType === viewType;
    }

    getMonthName(date: moment.Moment) {
        return date.format('MMMM');
    }

    getYear(date: moment.Moment) {
        return date.format('YYYY');
    }

    // Retorna o domingo anterior à data selecionada
    getFirstDateOfMonthToShow(startDate: moment.Moment) {
        const date = moment(`${startDate.format('YYYY')}-${startDate.format('MM')}-01`);
        return date.weekday(0);
    }

    getFirstMonthOfYearToShow(startDate: moment.Moment) {
        const date = moment(`${startDate.format('YYYY')}-${startDate.format('01')}-01`);
        return date;
    }

    getFirstYearOfDecadeToShow(startDate: moment.Moment) {
        let year = parseInt(startDate.format('YYYY'));
        while (year % 10 !== 0) {
            year--;
        }
        const date = moment(`${year}-${startDate.format('01')}-01`);
        return date;
    }

    getDates() {
        const startDate = this.getFirstDateOfMonthToShow(moment(this.ui.calendarDate));
        const dateToRun = moment(startDate);

        // Adiciono 7 dias para caso a data inicial seja do mês anterior ir para o mês presente
        // e calculo quantas semanas são necessárias para abranger todos os dias do mês atual.
        const currentMonth = dateToRun.clone().add(7, 'day');
        const lastDay = currentMonth.endOf('month');
        let diff = lastDay.diff(dateToRun, 'days') + 1;
        if (diff % 7 > 0) diff += 7 - (diff % 7);

        const dates = new Array<Moment>();
        for (let x = 0; x < diff; x++) {
            dates.push(moment(dateToRun));
            dateToRun.add(1, 'day');
        }
        return dates;
    }

    getMonths() {
        const startDate = this.getFirstMonthOfYearToShow(moment(this.ui.calendarDate));
        const dates = new Array<string>();
        for (let x = 0; x < 12; x++) {
            dates.push(moment(startDate).format('MMMM'));
            startDate.add(1, 'month');
        }
        return dates;
    }

    getYears() {
        const startDate = this.getFirstYearOfDecadeToShow(moment(this.ui.calendarDate));
        const dates = new Array<number>();
        for (let x = 0; x < 10; x++) {
            dates.push(Number(moment(startDate).format('YYYY')));
            startDate.add(1, 'year');
        }
        return dates;
    }

    adjustPosition() {
        if (typeof window === 'undefined') return;

        const spaceAbove = this.$el.getBoundingClientRect().top;
        const spaceBelow = window.innerHeight - this.$el.getBoundingClientRect().bottom;
        const spaceLeft = this.$el.getBoundingClientRect().left;
        const spaceRight = window.innerWidth - this.$el.getBoundingClientRect().right;

        if (!this.position) {
            this.openAbove = spaceAbove > spaceBelow;
            this.openLeft = spaceLeft > spaceRight;
            return;
        }

        this.openAbove = !!this.position.top || (!this.position.bottom && spaceAbove > spaceBelow);
        this.openLeft = !!this.position.left || (!this.position.right && spaceLeft > spaceRight);
    }

    getDateClass(date: moment.Moment) {
        const classObject = {
            'dcc-weekend': this.isDateWeekend(date),
            'dcc-out-of-month': this.isDateOutOfCurrentMonth(date),
            'dcc-selected': this.isDateSelected(date),
            'dcc-today': this.isDateToday(date),
            'dcc-disabled': !this.isSelectableDate(date)
        };
        return classObject;
    }

    getMonthClass(month: string) {
        return { 'dcc-disabled': !this.isSelectableMonth(month) };
    }

    getYearClass(year: number) {
        return { 'dcc-disabled': !this.isSelectableYear(year) };
    }

    isSelectableDate(date: moment.Moment | undefined | null) {
        if (this.isViewType(ViewType.DAY) && date && this.isDateOutOfCurrentMonth(date)) {
            return false;
        }

        if (this.minDate && date!.isBefore(this.minDate)) {
            return false;
        }

        if (this.maxDate && date!.isAfter(this.maxDate)) {
            return false;
        }

        return true;
    }

    isSelectableMonth(month: string) {
        const newDate = cloneDeep(this.ui.calendarDate!).month(month);
        if (this.minDate && newDate.isBefore(this.minDate)) {
            return false;
        }

        if (this.maxDate && newDate.isAfter(this.maxDate)) {
            return false;
        }
        return true;
    }

    isSelectableYear(year: number) {
        const newDate = cloneDeep(this.ui.calendarDate!).year(year);
        if (this.minDate && newDate.isBefore(this.minDate)) {
            return false;
        }

        if (this.maxDate && newDate.isAfter(this.maxDate)) {
            return false;
        }
        return true;
    }

    isDateWeekend(date: moment.Moment | string) {
        if (typeof date === 'string') {
            return date === moment.weekdaysShort(0) || date === moment.weekdaysShort(6);
        }
        return date.day() === 0 || date.day() === 6;
    }

    getDaysOfWeek() {
        return moment.weekdaysShort();
    }

    toggleCalendar() {
        if (this.isEnabled) {
            this.disable();
            return;
        }

        this.enable();
    }

    get inputPlaceholder() {
        return this.placeholder || '__/__/____';
    }

    get isEnabled() {
        return (!this.hideInput && this.ui.enabled) || (this.hideInput && this.showCalendar);
    }

    get isNullable() {
        return this.nullable;
    }

    get calendaPositionClass() {
        return {
            above: this.openAbove,
            left: this.openLeft,
        };
    }

    get inputMask() {
        return this.label.replace(/D|M|Y/g, '#');
    }

    get isSelect() {
        return this.inputClass === 'select-input'
    }

    chain = ArrayUtil.chain;
}
