import IFilter from '../ifilter';
import { StringUtil } from '@/modules/module/util/string/string.util';

export default class StriphtmlFilter implements IFilter {
    public filterName: string;

    constructor() {
        this.filterName = 'striphtml';
    }

    public filterAction(value: string) {
        return StringUtil.striphtml(value);
    }
}
