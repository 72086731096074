import { Component, Vue, Prop } from 'vue-property-decorator'
import { ButtonVariant } from './button-variant.enum';

@Component({
  template: require('./bs-button.html')
})
export class BSButtonComponent extends Vue {

  @Prop({ type: String, default: '' })
  variant!: string;

  @Prop({ required: true, type: String, default: '' })
  title!: string;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  fullWidth!: boolean;

  @Prop({ type: Boolean, default: false })
  shadow!: boolean;

  @Prop({ type: String, default: '' })
  leadingIcon!: string;

  @Prop({ type: String, default: '' })
  trailingIcon!: string;

  @Prop({ type: String, default: '' })
  color!: string;

  @Prop({ type: String })
  height!: string;

  click(): void {
    this.$emit('click');
  }

  get hasShadow(): boolean {
    return this.shadow && !this.isText;
  }

  get isText(): boolean {
      return this.variant === ButtonVariant.TEXT && !this.isGoogle && !this.isFacebook;
  }

  get isUnelevated(): boolean {
      return this.variant === ButtonVariant.UNELEVATED && !this.isGoogle && !this.isFacebook;
  }

  get isOutlined(): boolean {
      return this.variant === ButtonVariant.OUTLINED && !this.isGoogle && !this.isFacebook;
  }

  get isRounded(): boolean {
      return this.variant === ButtonVariant.ROUNDED && !this.isGoogle && !this.isFacebook;
  }

  get isRoundedOutlined(): boolean {
      return this.variant === ButtonVariant.ROUNDED_OUTLINED && !this.isGoogle && !this.isFacebook;
  }

  get classObject(): any {
      return {
          'gs-btn--text': this.isText,
          'gs-btn--outlined': this.isOutlined,
          'gs-btn--unelevated': this.isUnelevated,
          'gs-btn--rounded': this.isRounded,
          'gs-btn--rounded--outlined': this.isRoundedOutlined,
          'gs-btn--shadow': this.hasShadow,
          'gs-btn--facebook': this.isFacebook,
          'gs-btn--google': this.isGoogle,
          'gs-btn--full-width': this.fullWidth,
          'gs-btn--orange': this.color === 'orange' || this.color === 'secondary',
          'gs-btn--white': this.color === 'white',
      };
  }

  get styleObject(): any {
      return {
          height: this.height,
      };
  }

  get isFacebook(): boolean {
      return this.color === 'facebook';
  }

  get isGoogle(): boolean {
      return this.color === 'google';
  }
}
