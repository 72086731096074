import {Component, Vue} from 'vue-property-decorator';
import { UserAuth } from '@/modules/module/model/client/user-auth';
import { AuthenticationService } from '@/modules/module-authentication/authentication.service';
import { ApplicationConfigService } from '@/modules/module/services/application-config/application-config.service';
import { AppBroadcastEventBus, AppBroadcastEvent } from '@/app.broadcast-event-bus';
import { AmplitudeService } from '../../../modules/module/services/amplitude/amplitude.service';
import { CompanyHeaderModel } from '@/modules/module/model/client/company-model';
import { MultiCompanyService } from '@/modules/module/services/multi-company/multi-company.service';
import { RouterPath } from '@/enums/router-path.enum';

interface UI {
  user?: UserAuth;
  visible: {
    workspace: boolean;
    userMenu: boolean;
    dropdown: boolean;
  };
  companies: Array<CompanyHeaderModel>;
  company?: CompanyHeaderModel;
  menuOpened: string;
  menuFilter: string;
  status: {
    sidebarExpand: boolean;
  };
  displayName: string;
}

@Component({
  template: require('./bs-taskbar.html'),
})
export class BSTaskbarComponent extends Vue {

  ui: UI = {
    visible: {
      workspace: false,
      userMenu: false,
      dropdown: false,
    },
    companies: [],
    company: undefined,
    menuOpened: '',
    menuFilter: '',
    status: {
      sidebarExpand: false,
    },
    displayName: '',
  };

  created() {
    this.ui.user = AuthenticationService.user;
  }

  async mounted() {
    if (AuthenticationService.isAuthenticated) {
      if (!this.hasSefazGroup) {
        this.ui.companies = await MultiCompanyService.userCompanies();
        // checa se já existe um workspace ativo e adiciona um caso não tenha nenhum ativo
        this.ui.company = MultiCompanyService.defaultCompany(this.ui.companies);
      }
    }
  }

  loadDisplayName() {
    if (this.ui.company && this.ui.company.name) {
      this.ui.displayName = this.ui.company.name;
    } else if (this.ui.companies && this.ui.companies.length) {
      this.ui.displayName = this.ui.companies[0].name;
    }
  }

  get companyWorkspace() {
    if (this.ui.company && this.ui.company.name) {
      return this.ui.company.name;
    }
  }

  get workspaces() {
    return this.ui.companies;
  }

  isSefazPermission() {
    if (this.ui.user) {
      const found = this.ui.user.groups?.find(g => g === "SEFAZ");
      return !!found
    }
    return false;
  }

  get hasSefazGroup() {
    return this.isSefazPermission();
  }

  toggleDropdown() {
    this.ui.visible.dropdown = !this.ui.visible.dropdown;
  }

  redirectToWorkspace(workspace: string) {
    this.toggleDropdown();
    this.ui.company = this.ui.companies.find(c => c.keyCompanyGv === workspace || c.cnpj === workspace);
    this.$router.push({
      path: RouterPath.SWITCH_WORKSPACE,
      query: {
        key: workspace,
      }
    });
  }

  get showDropdown() {
    return this.ui.visible.dropdown;
  }

  toggleWorkspaces() {
    this.ui.visible.workspace = !this.ui.visible.workspace;
    this.logAmplitudeToggleWorkspace();
  }

  toggleUser() {
    this.ui.visible.userMenu = !this.ui.visible.userMenu;
    this.logAmplitudeToggleUserMenu();
  }

  toggleSidebar() {
    AppBroadcastEventBus.$emit(AppBroadcastEvent.TOGGLE_SIDEBAR);
  }

  hideWorkspaceMenu() {
    this.ui.visible.workspace = false;
  }

  hideUserMenu() {
    this.ui.visible.userMenu = false;
  }

  logout() {
    AuthenticationService.logout();
  }

  toGpass() {
    location.href = `${this.urlGpassAdmin}/#/inicio?companyCnpj=${this.ui.company?.cnpj ?? ''}`;
  }

  toMarketplace() {
    location.href = `${this.urlMarketplace}/home`;
  }

  getUserName() {
    if (this.ui.user) {
      return this.ui.user.name;
    }

    return 'Anônimo';
  }

  get isSidebarExpand() {
    return this.ui.status.sidebarExpand;
  }

  get setSidebarClass() {
    return this.ui.status.sidebarExpand ? 'sidebar-expanded' : 'sidebar-compressed';
  }

  toggleExpand() {
    this.ui.menuOpened = '';
    this.ui.status.sidebarExpand = !this.ui.status.sidebarExpand;
    this.$emit('on-toggle-expand', this.ui.status.sidebarExpand);
  }

  get urlMarketplace() {
    return ApplicationConfigService.urlMarketplace;
  }

  get urlGpassAdmin() {
    return ApplicationConfigService.urlGpassAdmin;
  }

  get isWorkspaceActive() {
    return this.ui.visible.workspace;
  }

  get isUserMenuActive() {
    return this.ui.visible.userMenu;
}

  get gpassLogo() {
    return `../../assets/img/gp-icon.png`;
  }

  get marketplaceLogo() {
    return `../../assets/img/gv-icon.png`;
  }

  get urlSuite() {
    return ApplicationConfigService.urlSuite;
  }

  /********************************************************************
    AMPLITUDE - EVENTOS DO GRUPO TASKBAR
  *********************************************************************/

  logAmplitudeToggleWorkspace() {
      try {
          AmplitudeService.logEvent('taskbar_toggle_workspace_teste', {
            workspace_toggle: !!this.isWorkspaceActive,
          });
      } catch (error) {
          console.error('Amplitude event error: taskbar_toggle_workspace');
      }
  }

  logAmplitudeToggleUserMenu() {
      try {
          AmplitudeService.logEvent('taskbar_toggle_user_menu', {
            user_menu_toggle: !!this.isUserMenuActive,
          });
      } catch (error) {
          console.error('Amplitude event error: taskbar_toggle_user_menu');
      }
  }

  logAmplitudeUserMenuSelected(route: string) {
      try {
          AmplitudeService.logEvent('taskbar_user_menu_selected', {
            user_menu: route,
          });
      } catch (error) {
          console.error('Amplitude event error: taskbar_user_menu_selected');
      }
  }

}
