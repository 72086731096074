import { RouteConfig } from 'vue-router'
import { RouterPath } from '@/enums/router-path.enum'

export default (): Array < RouteConfig > => {
  return [
    {
      path: RouterPath.PERMISSIONS,
      component: () => import('./components/page/page-marketplace-permission').then(m => m.default)
    },
    {
      path: RouterPath.PERMISSIONS_CREATE,
      component: () => import('./components/page/page-marketplace-invite').then(m => m.default)
    },
    {
      path: RouterPath.DASHBOARD,
      component: () => import('./components/page/page-dashboard').then(m => m.default),
    },
    {
      path: RouterPath.DASHBOARD_DETAILS,
      component: () => import('./components/page/page-dashboard-detail').then(m => m.default),
    }
  ]
}
