import { MultiselectResource } from './bs-multiselect-filter.types';

class BSMultiselectFilterUtil {

    /**
     * Get an array of objects and convert to an array of MultiselectResource
     * @param items Object list to convert in Multiselect list
     * @param attrId Attribute to set multiselect id. Default 'id'
     * @param attrName Attribute to set multiselect name. Default 'name'
     */
    convertToMultiselectArray(items: any[], attrId = 'id', attrName = 'name') {
        return items.map(item => {
            return {
                id: item[attrId],
                name: item[attrName],
                checked: false,
                applied: false
            } as MultiselectResource;
        }) as MultiselectResource[];
    }

    getSelectedItems(selectedItems: MultiselectResource[], items: any[], attrId = 'id') {
        return items.filter(item => {
            return !!selectedItems.find(selected => selected.id === item[attrId]);
        });
    }
}

export const MultiselectFilterUtil = new BSMultiselectFilterUtil();
