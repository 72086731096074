import IFilter from '../ifilter';

export default class FormatPercentage implements IFilter {
    public filterName: any;

    constructor() {
        this.filterName = 'formatPercentage';
    }

    public filterAction(value: number) {
        if (value === null) {
            return '';
        }
        const val = value.toFixed(1).replace('.', ',');
        return `${val}%`;
    }

}
